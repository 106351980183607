import React                                from "react";
import ReactDOM                             from "react-dom";
import registerServiceWorker                from "./registerServiceWorker";

// Redux
import { createStore, applyMiddleware }     from "redux";
import thunk                                from "redux-thunk";
import { composeWithDevTools }              from "redux-devtools-extension";
import { Provider }                         from "react-redux";
import RootReducer                          from "./Reducers/RootReducer";
import { setSettings, setPlatform }         from "./Actions/Core/CoreActions";

// Mysc
import App                                  from "./App";
import API                                  from "./Utils/App/API";
import NLS                                  from "./Utils/App/NLS";
import Auth                                 from "./Utils/App/Auth";
import PushService                          from "./Utils/App/PushService";



// Creates a Redux Store
const store = createStore(
    RootReducer,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
);


// Init the Settings
const isApp     = window.location.search === "?app";
const isCordova = !!window.cordova;
store.dispatch(setSettings(isApp, isCordova));


// Init the Components
API.init(store, isApp);
NLS.init();
Auth.init(store);


// Init the Cordova App
function onDeviceReady() {
    store.dispatch(setPlatform(window.device.platform.toLowerCase()));
    PushService.init(store);
    renderApp();
}


// Render the App
function renderApp() {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById("root")
    );
}


// Start the App depending on the Environment
if (isCordova) {
    document.addEventListener("deviceready", onDeviceReady, false);
} else {
    renderApp();
    registerServiceWorker();
}
