import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchQuery }       from "Actions/Client/QueryActions";
import NLS                  from "Utils/App/NLS";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Status               from "Components/Utils/Common/Status";
import Alert                from "Components/Utils/Form/Alert";
import QueryReply           from "./QueryReply";

// Styles
import "Styles/Components/Client/Query.css";



/**
 * The Query Page
 */
class QueryPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchQuery(this.props.match.params.query);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { queryID, subject, queryTypeName, credentialName, isOpen, unread, replies } = this.props.data;
        const { queryID : newQueryID, success } = this.props.created;
        const showSuccess = newQueryID !== 0 && newQueryID === queryID;

        return <>
            <Card>
                <SubTitle message={subject} icon="query" />
                <header className="query-header">
                    <div>
                        <b>{NLS.get("QUERIES_TYPE")}</b>
                        {queryTypeName}
                    </div>
                    <div>
                        <b>{NLS.get("QUERIES_ASSIGNED")}</b>
                        {credentialName}
                    </div>
                    <div>
                        <b>{NLS.get("QUERIES_STATUS")}</b>
                        <Status condition={!!isOpen} success="QUERIES_STATUS_OPEN" error="QUERIES_STATUS_CLOSE" />
                    </div>
                    <div>
                        <b>{NLS.get("QUERIES_READING")}</b>
                        <Status condition={!unread} success="QUERIES_READ" error="QUERIES_UNREAD" />
                    </div>
                </header>
                <section className="query-content spacing">
                    {showSuccess && <Alert variant="success" message={success} />}

                    {replies.map((elem) => <section key={elem.queryReplyID} className="query-reply">
                        <header className="query-reply-header">
                            {elem.socialReason && <label className="query-reply-admin">
                                {elem.socialReason}
                            </label>}
                            {elem.credentialName && <label className="query-reply-client">
                                {elem.credentialName}
                            </label>}
                            <div>{NLS.format("QUERIES_PUBLISH", elem.createdTimeFull)}</div>
                        </header>
                        <section className="query-reply-content">
                            {elem.messageLines.map((text, index) => <p key={index}>{text}</p>)}
                            {elem.image && <img src={elem.imageUrl} alt={elem.image} />}
                        </section>
                    </section>)}
                </section>
            </Card>
            <QueryReply queryID={queryID} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchQuery : PropTypes.func.isRequired,
        data       : PropTypes.object.isRequired,
        created    : PropTypes.object.isRequired,
        match      : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data    : state.query.query,
            created : state.query.created,
        };
    }
}

export default connect(QueryPage.mapStateToProps, {
    fetchQuery,
})(QueryPage);
