import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchAddresses }   from "Actions/Client/AddressActions";
import Utils                from "Utils/Common/Utils";

// Components
import AddressEdit          from "./AddressEdit";
import AddressDelete        from "./AddressDelete";
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";
import Alert                from "Components/Utils/Form/Alert";
import Button               from "Components/Utils/Form/Button";



/**
 * The Address List
 */
class AddressList extends React.Component {
    state = {
        showDialog : "",
        elemID     : 0,
        success    : "",
        error      : "",
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchAddresses();
    }

    /**
     * Opens a Dialog
     * @param {String} type
     * @param {Number} elemID
     * @returns {Function}
     */
    openDialog = (type, elemID) => (e) => {
        this.setState({ showDialog : Utils.mergeVCE(type), elemID });
        e.preventDefault();
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.setState({ showDialog : "", elemID : 0 });
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }

    /**
     * Called after submitting the Dialog
     * @param {String=} success
     * @param {String=} error
     * @returns {Void}
     */
    onSubmit = (success = "", error = "") => {
        this.setState({ success, error });
        this.closeDialog();
    }





    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, isPotential                  } = this.props;
        const { showDialog, elemID, success, error } = this.state;

        if (isPotential) {
            return <React.Fragment />;
        }
        return <Card>
            <SubTitle message="ADDRESSES_NAME" icon="address" withTop />
            <section className="spacing">
                <Alert variant="success" message={success} onClose={this.closeAlert} />
                <Alert variant="error"   message={error}   onClose={this.closeAlert} />

                <Table hasContent={!!data.length} none="ADDRESSES_NONE_AVAILABLE" hasActions>
                    <TableHead>
                        <TableCell message="ADDRESSES_DESCRIPTION" />
                        <TableCell message="ADDRESSES_ADDRESS" />
                        <TableCell message="ADDRESSES_PROVINCE" />
                        <TableCell message="ADDRESSES_LOCALITY" />
                        <TableCell message="ADDRESSES_POSTAL_CODE" />
                        <TableCell message="ADDRESSES_SCHEDULE" />
                        <TableCell message="ADDRESSES_IS_DEFAULT" align="center" />
                    </TableHead>
                    <TableBody>
                        {data.map((elem) => <TableRow
                            key={elem.addressID}
                            onClick={this.openDialog("edit", elem.addressID)}
                        >
                            <TableCell
                                isLink
                                message={elem.description}
                                onClick={this.openDialog("edit", elem.addressID)}
                            />
                            <TableCell message={elem.address + " "} />
                            <TableCell message={elem.province} />
                            <TableCell message={elem.locality} />
                            <TableCell message={elem.postalCode} nowrap />
                            <TableCell message={elem.schedule} />
                            <TableCell
                                isStatus
                                condition={elem.isDefault}
                                success="GENERAL_YES"
                                error="GENERAL_NO"
                                align="center"
                                hide
                            />
                            <TableCell
                                isLink
                                variant="black"
                                message="GENERAL_DELETE"
                                onClick={this.openDialog("delete", elem.addressID)}
                            />
                        </TableRow>)}
                    </TableBody>
                </Table>

                <div className="client-address">
                    <Button
                        variant="primary"
                        message="ADDRESSES_CREATE"
                        onClick={this.openDialog("create")}
                    />
                </div>
            </section>

            <AddressEdit
                open={showDialog === "edit"}
                elemID={elemID}
                onSubmit={this.onSubmit}
                onClose={this.closeDialog}
            />
            <AddressDelete
                open={showDialog === "delete"}
                data={data}
                elemID={elemID}
                onSubmit={this.onSubmit}
                onClose={this.closeDialog}
            />
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAddresses : PropTypes.func.isRequired,
        isPotential    : PropTypes.bool.isRequired,
        data           : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isPotential : state.auth.isPotential,
            data        : state.address.addresses,
        };
    }
}

export default connect(AddressList.mapStateToProps, {
    fetchAddresses,
})(AddressList);
