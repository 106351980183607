import API                  from "Utils/App/API";

// Types
import {
    OFFER_LIST, OFFER_DATA,
} from "Utils/Types";



/**
 * Fetches the Offer List
 * @returns {Function}
 */
export function fetchOffers() {
    return async (dispatch) => {
        const data = await API.Offer.getOffers();
        dispatch({ type : OFFER_LIST, data });
    };
}

/**
 * Fetches the Offer Data
 * @param {Object} params
 * @returns {Function}
 */
export function fetchOffer(params) {
    return async (dispatch) => {
        const data = await API.Offer.getOffer(params);
        dispatch({ type : OFFER_DATA, data });
    };
}
