import React                from "react";

// Components
import AccountForm          from "./AccountForm";
import AddressList          from "./AddressList";



/**
 * The Account Page
 */
class AccountPage extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <>
            <AccountForm />
            <AddressList />
        </>;
    }
}

export default AccountPage;
