import { getRedirect }      from "Actions/Content/NotificationActions";
import API                  from "Utils/App/API";
import Auth                 from "Utils/App/Auth";

// Variables
let oneSignal;
let store;



/**
 * Initialize the One Signal Service
 * @param {Store} theStore
 * @returns {Void}
 */
function init(theStore) {
    oneSignal = window.plugins ? window.plugins.OneSignal : null;
    store     = theStore;

    if (!oneSignal) {
        return;
    }
    
    oneSignal
        .startInit("021620ce-1289-4b89-864e-70206ac07764")
        .handleNotificationOpened((data) => {
            const url = data.notification.payload.additionalData.url;
            if (url) {
                store.dispatch(getRedirect(url));
            }
        })
        .endInit();
    
    Auth.onSet(setDevice);
    Auth.onUnset(unsetDevice);
}

/**
 * Sets the Device
 * @param {Object} credential
 * @returns {Void}
 */
async function setDevice(credential) {
    const playerID = await getPlayerID();

    if (credential.clientCode && playerID) {
        if (!localStorage.playerID) {
            API.Device.add({ clientCode : credential.clientCode, playerID });
        } else if (localStorage.playerID !== playerID) {
            API.Device.edit({
                clientCode  : credential.clientCode,
                oldPlayerID : localStorage.playerID,
                newPlayerID : playerID,
            });
        }
        localStorage.setItem("playerID", playerID);
    }
}

/**
 * Unsets the Device
 * @param {Object} credential
 * @returns {Void}
 */
async function unsetDevice(credential) {
    if (credential.clientCode && localStorage.playerID) {
        API.Device.remove({
            clientCode : credential.clientCode,
            playerID   : localStorage.playerID,
        });
        localStorage.removeItem("playerID");
    }
}



/**
 * Returns the Player ID
 * @returns {Promise}
 */
function getPlayerID() {
    return new Promise((resolve) => {
        oneSignal.getIds((data) => {
            resolve(data.userId);
        });
    });
}




// The Public API
export default {
    init,
    getPlayerID,
};
