import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setPage }          from "Actions/Client/ClientActions";
import { fetchReceipts }    from "Actions/Client/VoucherActions";
import Utils                from "Utils/Common/Utils";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";



/**
 * The Receipt Page
 */
class ReceiptPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchReceipts(params);
        this.props.setPage(params.page);
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchReceipts(newParams);
            this.props.setPage(newParams.page);
        }
    }

    /**
     * Returns the Params
     * @param {Object} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        return { page : Number(params.page) || 1 };
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isTablet, list, total } = this.props;
        const { page                  } = this.getParams();

        const baseUrl = "recibos";

        return <Card className="client-card">
            <SubTitle show={!isTablet} message="VOUCHERS_RECEIPTS" icon="voucher" />
            <section className="client-table spacing">
                <Table hasContent={!!list.length} none="VOUCHERS_NONE_RECEIPTS" hasActions>
                    <TableHead>
                        <TableCell message="VOUCHERS_DESCRIPTION" />
                        <TableCell message="VOUCHERS_DATE"        />
                        <TableCell message="VOUCHERS_AMOUNT"      align="right" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.receiptID}>
                            <TableCell message={elem.name} />
                            <TableCell message={elem.voucherTimeDate} nowrap />
                            <TableCell message={`$ ${elem.amount}`}   align="right" nowrap />
                            <TableCell
                                isLink
                                href={`${process.env.REACT_APP_VOUCHER}${baseUrl}/${elem.hash}`}
                                target="_blank"
                                message="VOUCHERS_DOWNLOAD"
                                align="right"
                            />
                        </TableRow>)}
                    </TableBody>
                </Table>
                <Pagination
                    current={page}
                    total={total}
                    url={`/cuenta/comprobantes/${baseUrl}`}
                />
            </section>
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setPage       : PropTypes.func.isRequired,
        fetchReceipts : PropTypes.func.isRequired,
        match         : PropTypes.object.isRequired,
        isTablet      : PropTypes.bool.isRequired,
        list          : PropTypes.array.isRequired,
        total         : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isTablet : state.core.isTablet,
            list     : state.voucher.receipts.list,
            total    : state.voucher.receipts.total,
        };
    }
}

export default connect(ReceiptPage.mapStateToProps, {
    setPage, fetchReceipts,
})(ReceiptPage);
