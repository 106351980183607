import Utils                from "Utils/Common/Utils";

// Types
import {
    BRAND_LIST, BRAND_DATA,
    STORE_FAVORITE,
} from "Utils/Types";



// The initial State
const initialState = {
    error  : false,
    brands : {
        types : [],
        lists : [],
    },
    brand  : {
        name          : "GENERAL_LOADING",
        url           : "",
        categories    : [],
        subcategories : [],
        products      : [],
        results       : 0,
        total         : 0,
        pages         : 1,
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case BRAND_LIST:
        return {
            ...state,
            error  : false,
            brands : action.data,
        };
    case BRAND_DATA:
        return {
            ...state,
            error  : false,
            brand  : action.data,
        };

    // Update the Favorite
    case STORE_FAVORITE:
        return {
            ...state,
            error  : false,
            brand  : Utils.setFavorite(state.brand, action.data.productCode),
        };

    default:
        return state;
    }
};
