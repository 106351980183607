import React                from "react";
import PropTypes            from "prop-types";

// Components
import ProductCard          from "Components/Product/Item/ProductCard";
import ProductDialog        from "Components/Product/Item/ProductDialog";
import Pagination           from "Components/Utils/Common/Pagination";

// Styles
import "Styles/Components/Product/List/ProductsList.css";



/**
 * The Products List
 */
class ProductsList extends React.Component {
    // The Current State
    state = {
        showDialog : false,
        product    : {},
    }

    /**
     * Opens the Product Dialog
     * @param {Object} product
     * @returns {Function}
     */
    openProduct = (product) => () => {
        this.setState({ showDialog : true, product });
    }

    /**
     * Closes the Product Dialog
     * @returns {Void}
     */
    closeProduct = () => {
        const { product } = this.state;
        this.setState({ showDialog : false, product : {} });
        if (this.props.onClose) {
            this.props.onClose(product);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { products, page, pages, url, withHeader, onFavorite } = this.props;
        const { showDialog, product                                } = this.state;

        return <section className={`products-list ${withHeader ? "products-list-header" : ""}`}>
            <div className="products-list-grid">
                {products.map((elem, index) => <ProductCard
                    key={index}
                    data={elem}
                    onClick={this.openProduct(elem)}
                    onFavorite={onFavorite}
                />)}
            </div>
            <Pagination
                current={page}
                total={pages}
                url={url}
            />

            <ProductDialog
                open={showDialog}
                data={product}
                onClose={this.closeProduct}
            />
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        products   : PropTypes.array.isRequired,
        page       : PropTypes.number.isRequired,
        pages      : PropTypes.number.isRequired,
        url        : PropTypes.string.isRequired,
        withHeader : PropTypes.bool,
        onFavorite : PropTypes.func,
        onClose    : PropTypes.func,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        withHeader : false,
    }
}

export default ProductsList;
