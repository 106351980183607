import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";

// Images
import LoaderImage          from "Styles/Images/Loader.gif";

// Styles
import "Styles/Components/Utils/Common/Loader.css";

// Constants
const WINDOW_WIDTH  = 950;
const MOBILE_WIDTH  = 650;

const HEADER_HEIGHT = 110;
const HEAD_HEIGHT   = 60;
const MOBILE_HEIGHT = 50;
const BAR_HEIGHT    = 50;



/**
 * The Loader
 */
class Loader extends React.Component {

    // The Reference
    ref = React.createRef();

    /**
     * Adds the Scroll Event
     * @returns {Void}
     */
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    /**
     * Removes the Scroll Event
     * @returns {Void}
     */
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    /**
     * Handles the Scroll
     * @returns {Void}
     */
    handleScroll = () => {
        const top    = this.getTop();
        const bottom = this.getBottom();

        this.ref.current.style.top    = `${top}px`;
        this.ref.current.style.bottom = `${bottom}px`;
    }



    /**
     * Calculates the Top
     * @returns {Number}
     */
    getTop() {
        if (!this.props.isAuthenticated) {
            return 0;
        }
        if (this.props.isApp) {
            return window.innerWidth <= MOBILE_WIDTH ? MOBILE_HEIGHT : HEAD_HEIGHT;
        }

        let header = HEADER_HEIGHT;
        if (window.innerWidth <= MOBILE_WIDTH) {
            header = MOBILE_HEIGHT;
        } else if (window.innerWidth <= WINDOW_WIDTH) {
            header = HEAD_HEIGHT;
        }

        const scrollTop = document.documentElement.scrollTop;
        const topCalc   = header - scrollTop;
        const top       = Math.max(topCalc, 0);
        return top;
    }

    /**
     * Calculates the Top
     * @returns {Number}
     */
    getBottom() {
        if (!this.props.isAuthenticated || !this.props.isApp) {
            return 0;
        }
        return BAR_HEIGHT;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { variant, show } = this.props;

        const top    = this.getTop();
        const bottom = this.getBottom();
        const styles = { top : `${top}px`, bottom : `${bottom}px` };

        return <div
            className={`loader loader-${variant} ${show ? "loader-show" : ""}`}
            style={styles}
            ref={this.ref}
        >
            <div className="loader-image">
                <img src={LoaderImage} alt={NLS.get("GENERAL_LOADING")} />
            </div>
            <div className="loader-text">
                {NLS.get("GENERAL_LOADING")}
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isAuthenticated : PropTypes.bool.isRequired,
        isApp           : PropTypes.bool.isRequired,
        variant         : PropTypes.string.isRequired,
        show            : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth && state.auth.isAuthenticated,
            isApp           : state.core.isApp,
        };
    }
}

export default connect(Loader.mapStateToProps)(Loader);
