import React                from "react";
import PropTypes            from "prop-types";
import Production           from "Utils/Common/Production";
import NLS                  from "Utils/App/NLS";

// Components
import Breadcrumb           from "Components/Product/List/Breadcrumb";
import SortFilter           from "Components/Product/List/SortFilter";
import BikeFilter           from "Components/Product/List/BikeFilter";
import CategoryFilter       from "Components/Product/List/CategoryFilter";
import TypeFilter           from "Components/Product/List/TypeFilter";
import PriceFilter          from "Components/Product/List/PriceFilter";
import AmountFilter         from "Components/Product/List/AmountFilter";

import SubTitle             from "Components/Utils/Common/SubTitle";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Product/List/ProductsAside.css";



/**
 * The Products Aside
 */
class ProductsAside extends React.Component {
    // The Current State
    state = {
        open : false,
    }

    /**
     * Handles the Filter
     * @param {Event} e
     * @returns {Void}
     */
    toggleFilter = (e) => {
        this.setState({ open : !this.state.open });
        e.preventDefault();
    }

    /**
     * Closes the Filter
     * @returns {Void}
     */
    handleChange = () => {
        this.setState({ open : false });
    }



    /**
     * Returns the Suffex
     * @param {{amount: Number, type: Number, price: Number, sort: Number }=} params
     * @returns {String}
     */
    getSuffix(params) {
        return Production.getSuffixDef(params, this.props);
    }

    /**
     * Returns the Url
     * @param {{amount: Number, type: Number, price: Number, sort: Number }=} params
     * @returns {String}
     */
    getUrl(params) {
        const suffix = this.getSuffix(params);
        return `${this.props.baseUrl}${suffix}`;
    }

    /**
     * Returns the Category Url
     * @param {String=} hash
     * @returns {String}
     */
    getCatUrl(hash) {
        const suffix = this.getSuffix();
        if (hash) {
            return `${this.props.catUrl}/${hash}${suffix}`;
        }
        return `${this.props.catUrl}${suffix}`;
    }

    /**
     * Returns the Subcategory Url
     * @param {String=} hash
     * @returns {String}
     */
    getSubUrl(hash) {
        const suffix = this.getSuffix();
        if (hash) {
            return `${this.props.subUrl}/${hash}${suffix}`;
        }
        return `${this.props.subUrl}${suffix}`;
    }

    /**
     * Returns the Bike Url
     * @param {String=} hash
     * @returns {String}
     */
    getBikeUrl(hash) {
        if (hash) {
            return `${this.props.bikeUrl}/${hash}`;
        }
        return this.props.cylinderUrl;
    }

    /**
     * Returns the Amount Url
     * @param {String=} hash
     * @returns {String}
     */
    getAmountUrl(hash) {
        if (hash) {
            return `${this.props.amountUrl}/${hash}`;
        }
        return this.props.cylinderUrl;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            name, results,
            crumbs, sort,
            categories, category,
            subcategories, subcategory,
            withType, types, type,
            withBike, bikes, bike,
            withAmount, amounts, amount,
            prices, price,
            withHeader,
        } = this.props;
        const { open } = this.state;
        
        const showCategories    = Boolean(categories    && categories.length);
        const showSubcategories = Boolean(subcategories && subcategories.length && category);
        const showAmounts       = Boolean(withAmount    && amounts);
        const showBikes         = Boolean(withBike      && bikes);
        const showType          = Boolean(withType      && types && types[1]);
        const showPrice         = Boolean(prices        && prices[1]);

        return <aside className={"aside" + (withHeader ? " aside-with-header" : "")}>
            <header className="aside-header">
                <Breadcrumb data={crumbs} />
                <h3>{NLS.get(name)}</h3>
                <div className="aside-info">
                    <p className="aside-results">{NLS.pluralize("PRODUCTS_RESULTS", results)}</p>
                    <HyperLink
                        className="aside-filter"
                        variant="filter"
                        message="PRODUCTS_FILTER"
                        onClick={this.toggleFilter}
                    />
                </div>
            </header>
            <main className={"aside-content" + (open ? " aside-open" : "")}>
                <SubTitle className="aside-subtitle" message="PRODUCTS_FILTER_TITLE" icon="filter">
                    <HyperLink variant="icon" icon="close" onClick={this.toggleFilter} />
                </SubTitle>
                <section className="aside-filters">
                    <SortFilter
                        selected={sort}
                        getUrl={(sort) => this.getUrl({ sort })}
                        onChange={this.handleChange}
                    />
                    {showAmounts && <AmountFilter
                        data={amounts}
                        selected={amount}
                        getUrl={(amount) => this.getUrl({ amount })}
                        onChange={this.handleChange}
                    />}
                    {showBikes && <BikeFilter
                        data={bikes}
                        selected={bike}
                        getUrl={(hash) => this.getBikeUrl(hash)}
                        onChange={this.handleChange}
                    />}
                    {showCategories && <CategoryFilter
                        data={categories}
                        selected={category}
                        getUrl={(hash) => this.getCatUrl(hash)}
                        onChange={this.handleChange}
                    />}
                    {showSubcategories && <CategoryFilter
                        data={subcategories}
                        selected={subcategory}
                        getUrl={(hash) => this.getSubUrl(hash)}
                        onChange={this.handleChange}
                        isSub
                    />}
                    {showType && <TypeFilter
                        data={types}
                        selected={type}
                        getUrl={(type) => this.getUrl({ type })}
                        onChange={this.handleChange}
                    />}
                    {showPrice && <PriceFilter
                        data={prices}
                        selected={price}
                        getUrl={(price) => this.getUrl({ price })}
                        onChange={this.handleChange}
                    />}
                </section>
            </main>
        </aside>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        name          : PropTypes.string.isRequired,
        results       : PropTypes.number.isRequired,
        crumbs        : PropTypes.array.isRequired,
        sort          : PropTypes.number.isRequired,
        withAmount    : PropTypes.bool,
        amounts       : PropTypes.array,
        amount        : PropTypes.number,
        withBike      : PropTypes.bool,
        bikes         : PropTypes.array,
        bike          : PropTypes.string,
        categories    : PropTypes.array.isRequired,
        category      : PropTypes.string.isRequired,
        subcategories : PropTypes.array,
        subcategory   : PropTypes.string,
        withType      : PropTypes.bool,
        types         : PropTypes.array,
        type          : PropTypes.number,
        prices        : PropTypes.object,
        price         : PropTypes.number,
        baseUrl       : PropTypes.string.isRequired,
        subUrl        : PropTypes.string,
        catUrl        : PropTypes.string.isRequired,
        bikeUrl       : PropTypes.string,
        cylinderUrl   : PropTypes.string,
        amountUrl     : PropTypes.string,
        withHeader    : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        withHeader : false,
        withType   : false,
        withAmount : false,
        withBike   : false,
    }
}

export default ProductsAside;
