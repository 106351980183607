import Utils                    from "Utils/Common/Utils";

// Types
import {
    AUTH_CURRENT_USER,
} from "Utils/Types";



// The initial State
const initialState = {
    isAuthenticated : false,
    isPotential     : false,
    credential      : {
        name        : "",
        phone       : "011-4712-5151",
        whatsapp    : "",
        isPotential : false,
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    switch (action.type) {
    case AUTH_CURRENT_USER:
        if (Utils.isEmpty(action.credential)) {
            return initialState;
        }

        action.credential.whatsapp = String(action.credential.whatsapp || "");
        return {
            isAuthenticated : !Utils.isEmpty(action.credential),
            isPotential     : Boolean(action.credential.isPotential),
            credential      : action.credential,
            email           : "",
        };
    default:
        return state;
    }
};
