import React                from "react";
import ReactDOM             from "react-dom";
import NLS                  from "Utils/App/NLS";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import YesterdayImage       from "Styles/Images/About/YesterdayTitle.png";
import TodayImage           from "Styles/Images/About/TodayTitle.png";
import TomorrowImage        from "Styles/Images/About/TomorrowTitle.png";

// Images
import "Styles/Images/About/Header.jpg";
import "Styles/Images/About/YesterdayNav.jpg";
import "Styles/Images/About/YesterdayBG.jpg";
import "Styles/Images/About/TodayNav.jpg";
import "Styles/Images/About/TodayBG.jpg";
import "Styles/Images/About/TomorrowNav.jpg";
import "Styles/Images/About/TomorrowBG.jpg";

// Styles
import "Styles/Components/Content/AboutUs.css";



/**
 * The About Us Page
 */
class AboutUsPage extends React.Component {
    // References
    yesterdayRef = React.createRef();
    todayRef     = React.createRef();
    tomorrowRef  = React.createRef();

    /**
     * Scrolls to the given Element
     * @param {ReactRef} ref
     * @returns {Function}
     */
    scrollTo = (ref) => () => {
        const node = ReactDOM.findDOMNode(ref.current);
        if (node) {
            node.scrollIntoView({ behavior : "smooth" });
        }
    };



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        return <Wrapper className="about-container">
            <header className="about-bg about-header">
                <h2>{NLS.get("ABOUT_TITLE")}</h2>
            </header>
            <ul className="about-nav no-list">
                <li
                    className="about-bg about-nav-yesterday"
                    onClick={this.scrollTo(this.yesterdayRef)}
                >{NLS.get("ABOUT_YESTERDAY")}</li>
                <li
                    className="about-bg about-nav-today"
                    onClick={this.scrollTo(this.todayRef)}
                >{NLS.get("ABOUT_TODAY")}</li>
                <li
                    className="about-bg about-nav-tomorrow"
                    onClick={this.scrollTo(this.tomorrowRef)}
                >{NLS.get("ABOUT_TOMORROW")}</li>
            </ul>

            <section className="about-bg about-section about-yesterday" ref={this.yesterdayRef}>
                <div className="about-content">
                    <h2>{NLS.get("ABOUT_YESTERDAY_TITLE")}</h2>
                    <p>{NLS.get("ABOUT_YESTERDAY_LINE1")}</p>
                    <p>{NLS.get("ABOUT_YESTERDAY_LINE2")}</p>
                    <p>{NLS.get("ABOUT_YESTERDAY_LINE3")}</p>
                    <p>{NLS.get("ABOUT_YESTERDAY_LINE4")}</p>
                </div>
                <div className="about-title">
                    <img src={YesterdayImage} alt={NLS.get("ABOUT_YESTERDAY")} />
                </div>
            </section>

            <section className="about-bg about-section about-today" ref={this.todayRef}>
                <div className="about-content">
                    <p>{NLS.get("ABOUT_TODAY_LINE1")}</p>
                    <p>{NLS.get("ABOUT_TODAY_LINE2")}</p>
                </div>
                <div className="about-title">
                    <img src={TodayImage} alt={NLS.get("ABOUT_TODAY")} />
                </div>
            </section>

            <section className="about-bg about-section about-tomorrow" ref={this.tomorrowRef}>
                <div className="about-content">
                    <p>{NLS.get("ABOUT_TOMORROW_LINE1")}</p>
                </div>
                <div className="about-title">
                    <img src={TomorrowImage} alt={NLS.get("ABOUT_TOMORROW")} />
                </div>
            </section>
        </Wrapper>;
    }
}

export default AboutUsPage;
