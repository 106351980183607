import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Utils/Common/Title.css";



/**
 * The Title
 */
class Title extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, message, submessage, href } = this.props;

        return <header className={`title ${className}`}>
            {!!href && <HyperLink variant="icon" icon="back" href={href} />}
            <div className="title-content">
                <h2>{NLS.get(message)}</h2>
                {submessage && <h3>{submessage}</h3>}
            </div>
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className  : PropTypes.string,
        message    : PropTypes.string.isRequired,
        submessage : PropTypes.string,
        href       : PropTypes.string,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }
}

export default Title;
