import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Button               from "Components/Utils/Form/Button";
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Cart Summary
 */
class CartSummary extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const {
            totals, showShipping, shipping, showMap, map, openMap,
            submit, isDisabled, onSubmit,
            cancel, onCancel, cancelUrl,
        } = this.props;

        return <section className="cart-summary">
            <Card>
                <SubTitle message="CART_SUMMARY" icon="cart" />
                {totals.map((elem) => (
                    <section key={elem.id} className="cart-summary-item">
                        <h4>{NLS.format("CART_TOTAL", elem.name)}</h4>
                        <p>
                            <span className="cart-symbol">{elem.symbol}</span>
                            {elem.total}
                        </p>
                    </section>
                ))}
                {showShipping && <>
                    <SubTitle message="CART_SHIPPING_SELECTED" icon="shipping" />
                    <section className="cart-summary-item cart-summary-shipping">
                        <div className={`cart-summary-image ${shipping.image}`} />
                        <p>{NLS.get(shipping.message)}</p>
                    </section>
                </>}
                {showMap && <>
                    <SubTitle message="CART_SHIPPING_MAP" icon="map" />
                    <section className="cart-summary-map">
                        <HyperLink variant="none" onClick={openMap}>
                            <img src={map.mapUrl} alt={map.name} />
                        </HyperLink>
                    </section>
                </>}
                <footer className="cart-summary-footer">
                    <Button
                        variant="primary"
                        message={submit}
                        isDisabled={isDisabled}
                        onClick={onSubmit}
                        fullWidth
                    />
                    <Button
                        variant="cancel"
                        message={cancel}
                        onClick={onCancel}
                        href={cancelUrl}
                        fullWidth
                    />
                </footer>
            </Card>
            <Card className="cart-summary-legals">
                <p>{NLS.get("CART_LEGALS_1")}</p>
                <p>{NLS.get("CART_LEGALS_2")}</p>
                <p>{NLS.get("CART_LEGALS_3")}</p>
                <p>{NLS.get("CART_LEGALS_4")}</p>
            </Card>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        totals       : PropTypes.array.isRequired,
        showShipping : PropTypes.bool,
        shipping     : PropTypes.object,
        showMap      : PropTypes.bool,
        map          : PropTypes.object,
        openMap      : PropTypes.func,
        submit       : PropTypes.string.isRequired,
        onSubmit     : PropTypes.func.isRequired,
        cancel       : PropTypes.string.isRequired,
        onCancel     : PropTypes.func,
        cancelUrl    : PropTypes.string,
        isDisabled   : PropTypes.bool.isRequired,
    }
}

export default CartSummary;
