import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchOffers }      from "Actions/Store/OfferActions";
import NLS                  from "Utils/App/NLS";

// Components
import ProductBanner        from "Components/Product/Item/ProductBanner";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Images/Store/Offers.jpg";
import "Styles/Components/Store/Offers.css";



/**
 * The Offer List
 */
class OfferList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchOffers();
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { banners, list } = this.props.data;
        const showBanners = Boolean(banners.length > 0);
        const showList    = Boolean(list.length    > 0);
        const showNone    = !showBanners && !showList;
        const showContent = showList || showNone;

        return <div className="offers-container">
            <header className="offers-header">
                <h2>{NLS.get("OFFERS_TITLE")}</h2>
            </header>
            <Wrapper withSpacing>
                {showBanners && <section className="offers-banners">
                    {banners.map((elem) => <ProductBanner key={elem.id} data={elem} />)}
                </section>}
                {showContent && <Card className="offers-content" withSpacing>
                    {showList && <ul className="offers-grid no-list">
                        {list.map((elem) => <li key={elem.id}>
                            <HyperLink variant="none" href={`/ofertas/${elem.id}`}>
                                <img src={elem.image} alt={elem.name} />
                                <h2>{elem.name}</h2>
                            </HyperLink>
                        </li>)}
                    </ul>}
                    {showNone && <div>
                        <h3 className="offers-none">{NLS.get("OFFERS_NONE_AVAILABLE")}</h3>
                        <HyperLink href="/categorias" message="OFFERS_BUY_MORE" />
                    </div>}
                </Card>}
            </Wrapper>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOffers : PropTypes.func.isRequired,
        data        : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.offer.offers,
        };
    }
}

export default connect(OfferList.mapStateToProps, {
    fetchOffers,
})(OfferList);
