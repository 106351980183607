import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import NLS                  from "Utils/App/NLS";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Images
import MainLogo             from "Styles/Images/LogoWhite.png";

// Styles
import "Styles/Components/Core/Header.css";

// Constants
const WINDOW_WIDTH = 650;



/**
 * The Header
 */
class Header extends React.Component {
    // The Current State
    state = {
        search     : "",
        showSearch : false,
        setted     : false,
    }
    searchElem = null;



    /**
     * Set the Search Value
     * @returns {Void}
     */
    componentDidUpdate() {
        const search = this.props.search;
        if (search && search !== "GENERAL_LOADING" && this.state.search === "" && !this.state.setted) {
            this.setState({ search, setted : true });
        }
    }

    /**
     * Handles the Search Input Change
     * @param {Event} e
     * @returns {Void}
     */
    onChange = (e) => {
        this.setState({ search : e.target.value, setted : true });
    }

    /**
     * Handles the Key Press
     * @param {Event} e
     * @returns {Void}
     */
    onKeyPress = (e) => {
        if (e.key === "Enter") {
            this.submitSearch();
        }
    }

    /**
     * Opens the Search Input
     * @param {Event} e
     * @returns {Void}
     */
    openSearch = () => {
        this.setState({ showSearch : true });
    }

    /**
     * Closes the Search Input
     * @param {Event} e
     * @returns {Void}
     */
    closeSearch = () => {
        window.setTimeout(() => this.setState({ showSearch : false }), 100);
    }

    /**
     * Handles the Search
     * @returns {Void}
     */
    submitSearch = () => {
        const { search, showSearch } = this.state;
        const isMobile = window.innerWidth <= WINDOW_WIDTH;

        if (search && (showSearch || !isMobile)) {
            const url = search.replace("/", "--").replace(/\s+/g, "_").toLocaleLowerCase();
            this.props.history.push(`/buscar/${url}`);
            if (this.searchElem) {
                this.searchElem.blur();
            }

        } else if (isMobile && this.searchElem) {
            this.openSearch();
            window.setTimeout(() => {
                this.searchElem.click();
                this.searchElem.focus();
            }, 500);
        }
        this.closeNav();
    }



    /**
     * Toggles the Navigation
     * @returns {Void}
     */
    toggleNav = () => {
        this.props.toggleNav(!this.props.showNav);
    }

    /**
     * Closes the Navigation
     * @returns {Void}
     */
    closeNav = () => {
        this.props.toggleNav(false);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { phone, whatsapp, cart, path, showNav } = this.props;
        const { search, showSearch                   } = this.state;

        return <section className={"header-container" + (showSearch ? " header-search" : "")}>
            <Wrapper className="header-content">
                <h1>
                    <HyperLink variant="none" href="/">
                        <img src={MainLogo} alt={NLS.get("GENERAL_NAME")} />
                    </HyperLink>
                </h1>
                <div className="header-secondary">
                    <div className={"searchbar-container" + (showSearch ? " searchbar-focused" : "")}>
                        <HyperLink
                            className="header-icon searchbar-back"
                            variant="icon"
                            icon="back"
                        />
                        <div className="searchbar-bar searchbar-back" />
                        <input
                            type="text"
                            className="searchbar-input"
                            value={search}
                            placeholder={NLS.get("HEADER_SEARCH")}
                            onChange={this.onChange}
                            onFocus={this.openSearch}
                            onBlur={this.closeSearch}
                            onKeyPress={this.onKeyPress}
                            ref={(elem) => { this.searchElem = elem; }}
                        />
                        <div className="searchbar-bar" />
                        <HyperLink
                            className="header-icon"
                            variant="icon"
                            icon="search"
                            onClick={this.submitSearch}
                        />
                    </div>
                    <div className="header-phone">
                        {!!whatsapp && <HyperLink
                            variant="menu"
                            icon="whatsapp"
                            href={whatsapp}
                            message={whatsapp}
                            whatsapp
                        />}
                        {!!phone && <HyperLink
                            variant="menu"
                            icon="phone"
                            href={phone}
                            message={phone}
                            tel
                        />}
                    </div>
                    <div className="header-menu">
                        <HyperLink
                            className="header-icon"
                            variant="icon"
                            icon={showNav ? "close" : "menu"}
                            onClick={this.toggleNav}
                        />
                    </div>
                    <div className="header-cart">
                        <HyperLink
                            className={"header-icon" + (path === "/compras" ? " header-selected" : "")}
                            variant="icon"
                            icon="cart"
                            href="/compras"
                            onClick={this.closeNav}
                            badge={cart}
                        />
                    </div>
                </div>
            </Wrapper>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history   : PropTypes.object.isRequired,
        phone     : PropTypes.string,
        whatsapp  : PropTypes.string,
        cart      : PropTypes.number.isRequired,
        search    : PropTypes.string.isRequired,
        path      : PropTypes.string.isRequired,
        showNav   : PropTypes.bool.isRequired,
        toggleNav : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            phone    : state.auth.credential.phone,
            whatsapp : state.auth.credential.whatsapp,
            cart     : state.store.header.cart,
            search   : state.product.search.name,
        };
    }
}

export default withRouter(connect(Header.mapStateToProps)(Header));
