import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";

// Components
import ProductList          from "./ProductList";
import CartSummary          from "./CartSummary";
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";

// Actions
import {
    editProduct, removeProduct, confirmCart,
} from "Actions/Store/CartActions";



/**
 * The Product Page
 */
class ProductPage extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Handles the Product Edit
     * @param {String} productCode
     * @returns {Function}
     */
    handleEdit = (productCode) => async (name, amount) => {
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true });
        this.props.closeAlert();
        try {
            await this.props.editProduct({ productCode, amount });
            this.setState({ loading : false });
        } catch (errors) {
            this.props.openAlert("", errors.form);
            this.setState({ loading : false });
        }
    }

    /**
     * Handles a Product Delete
     * @param {String} productCode
     * @returns {Function}
     */
    handleDelete = (productCode) => async () => {
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true });
        this.props.closeAlert();
        try {
            const success = await this.props.removeProduct({ productCode });
            this.props.openAlert(success);
            this.setState({ loading : false });
        } catch (errors) {
            this.props.openAlert("", errors.form);
            this.setState({ loading : false });
        }
    }

    /**
     * Handles a Product Delete
     * @param {String} productCode
     * @returns {Function}
     */
    handleSubmit = async () => {
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true });
        this.props.closeAlert();
        try {
            await this.props.confirmCart();
            this.props.onSubmit();
        } catch (errors) {
            this.props.openAlert("", errors.form);
            this.setState({ loading : false });
        }
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { items, offers, totals } = this.props.data;
        const { loading               } = this.state;

        return <>
            <section className="cart-content">
                {items.length > 0 && items.map((elem, index) => (
                    <Card key={index} className="cart-card">
                        <SubTitle
                            message={NLS.format("CART_PURCHASES", totals[index].name)}
                            icon="product"
                        />
                        <ProductList
                            data={elem}
                            onEdit={this.handleEdit}
                            onDelete={this.handleDelete}
                            loading={loading}
                        />
                    </Card>
                ))}
                {offers.map((elem) => (
                    <Card key={elem.id} className="cart-card">
                        <SubTitle message={elem.name} icon="offer" />
                        <div className="cart-units">
                            {elem.index > 0 ? <p className="cart-discount">
                                {NLS.format(`CART_DISCOUNT_${elem.type.toUpperCase()}`, elem.current)}
                            </p> : <p className="cart-next">
                                {NLS.format(`CART_DISCOUNT_NEXT_${elem.type.toUpperCase()}`, elem.next)}
                            </p>}
                        </div>
                        <ProductList
                            data={elem.items}
                            onEdit={this.handleEdit}
                            onDelete={this.handleDelete}
                            loading={loading}
                        />
                    </Card>
                ))}
            </section>

            <CartSummary
                totals={totals}
                submit="CART_CONTINUE"
                cancel="CART_ADD_MORE"
                isDisabled={loading}
                onSubmit={this.handleSubmit}
                cancelUrl="/"
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        editProduct   : PropTypes.func.isRequired,
        removeProduct : PropTypes.func.isRequired,
        confirmCart   : PropTypes.func.isRequired,
        onSubmit      : PropTypes.func.isRequired,
        data          : PropTypes.object.isRequired,
        openAlert     : PropTypes.func.isRequired,
        closeAlert    : PropTypes.func.isRequired,
    }

    /**
     * The Context Types
     * @typedef {Object} contextTypes
     */
    static contextTypes = {
        router : PropTypes.shape({
            history : PropTypes.object.isRequired,
        }),
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.cart.data,
        };
    }
}

export default connect(ProductPage.mapStateToProps, {
    editProduct, removeProduct, confirmCart,
})(ProductPage);
