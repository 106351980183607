// Types
import {
    CORE_SETTINGS, CORE_PLATFORM, CORE_MOBILE,
    CORE_LOADING, CORE_REDIRECT, CORE_RESULT, CORE_SECTION,
} from "Utils/Types";



/**
 * Sets the App
 * @param {Boolean} isApp
 * @param {Boolean} isCordova
 * @returns {{type: String, data: Object}}
 */
export function setSettings(isApp, isCordova) {
    return { type : CORE_SETTINGS, data : { isApp, isCordova } };
}

/**
 * Sets the Platform
 * @param {String} data
 * @returns {{type: String, data: Object}}
 */
export function setPlatform(data) {
    return { type : CORE_PLATFORM, data };
}

/**
 * Sets if is Mobile
 * @param {Object} isMobile
 * @param {Object} isTablet
 * @returns {Function}
 */
export function setMobile(isMobile, isTablet) {
    return async (dispatch) => {
        dispatch({
            type : CORE_MOBILE,
            data : { isMobile, isTablet },
        });
    };
}



/**
 * Sets the Loading
 * @param {Boolean} data
 * @returns {{type: String, data: Boolean}}
 */
export function setLoading(data) {
    return { type : CORE_LOADING, data };
}

/**
 * Sets the Redirect
 * @param {String} data
 * @returns {{type: String, data: String}}
 */
export function setRedirect(data) {
    return { type : CORE_REDIRECT, data };
}

/**
 * Sets the Section
 * @param {String} data
 * @returns {{type: String, data: String}}
 */
export function setSection(data) {
    return { type : CORE_SECTION, data };
}



/**
 * Shows the Result
 * @param {String} type
 * @param {String} message
 * @returns {{type: String, data: Object}}
 */
export function showResult(type, message) {
    const data = { open : true, type, message };
    return { type : CORE_RESULT, data };
}

/**
 * Hides the Result
 * @returns {{type: String, data: Object}}
 */
export function hideResult() {
    const data = { open : false, type : "", message : "" };
    return { type : CORE_RESULT, data };
}
