import React                from "react";

// Components
import Card                 from "Components/Utils/Common/Card";

// Images
import "Styles/Images/Client.jpg";



/**
 * The Blank Page
 */
class BlankPage extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        return <Card className="client-blank" />;
    }
}

export default BlankPage;
