import React                from "react";
import PropTypes            from "prop-types";

// Styles
import "Styles/Components/Product/Item/ProductPrice.css";



/**
 * The Product Cart Price
 */
class ProductCartPrice extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { currencySymbol, priceFormat, discountPrice } = this.props.data;

        const hasDiscount = discountPrice && Boolean(Number(String(discountPrice).replace(",", ".")));
        const symbol      = <span className="product-price-symbol">{currencySymbol}</span>;

        return <div className="product-price cart-price">
            {hasDiscount ? <div className="product-price-cnt">
                <div className="product-price-old">
                    {symbol} {priceFormat}
                </div>
                <div className="product-price-new">
                    <div className="product-new-value">
                        {symbol} {discountPrice}
                    </div>
                </div>
            </div> : <div className="product-price-cnt">
                {symbol} {priceFormat}
            </div>}
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data : PropTypes.object.isRequired,
    }
}

export default ProductCartPrice;
