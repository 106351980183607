import React                from "react";
import NLS                  from "Utils/App/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";

// Images
import AppStoreImage        from "Styles/Images/Download/AppStore.png";
import GooglePlayImage      from "Styles/Images/Download/GooglePlay.png";

// Styles
import "Styles/Images/Download/Phone.png";
import "Styles/Components/Content/Download.css";



/**
 * The Download App Page
 */
class DownloadAppPage extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        return <div className="download-container">
            <div className="download-title">
                <div>
                    <h2><span>{NLS.get("DOWNLOAD_TITLE1")}</span> {NLS.get("DOWNLOAD_TITLE2")}</h2>
                    <p>{NLS.get("DOWNLOAD_SUBTITLE")}</p>
                </div>
            </div>
            <div className="download-content">
                <HyperLink variant="opacity">
                    <img src={AppStoreImage} alt={NLS.get("DOWNLOAD_APP_STORE")} />
                </HyperLink>
                <HyperLink variant="opacity">
                    <img src={GooglePlayImage} alt={NLS.get("DOWNLOAD_GOGOLE_PLAY")} />
                </HyperLink>
            </div>
            <h3>{NLS.get("DOWNLOAD_TEXT")}</h3>
        </div>;
    }
}

export default DownloadAppPage;
