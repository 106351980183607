import React                from "react";
import PropTypes            from "prop-types";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Category Menu
 */
class CategoryMenu extends React.Component {
    // The Current State
    state = {
        showSub     : false,
        categoryID  : 0,
        categoryIdx : 0,
    };

    /**
     * Shows the Category SubMenu
     * @param {Number} id
     * @param {Number} index
     * @returns {Function}
     */
    showCategory = (id, index) => () => {
        this.setState({
            showSub     : true,
            categoryID  : id,
            categoryIdx : index,
        });
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { open, data, onEnter, onLeave, onClose } = this.props;
        const { showSub, categoryID, categoryIdx      } = this.state;

        const category   = showSub ? data[categoryIdx] : {};
        const showMenues = showSub && !!category && !!category.childs.length;
        const isSmall    = showMenues && category.childs.length < 20;

        return <nav
            className={
                "submenu-container submenu-category" +
                (!isSmall ? " submenu-category-big" : "") +
                (open     ? " submenu-open"         : "")
            }
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className={`submenu-category-list ${showSub && "submenu-with-sub"} no-list`}>
                {data.map((elem, index) => <li
                    key={elem.id}
                    className={categoryID === elem.id ? "submenu-selected" : ""}
                >
                    <HyperLink
                        variant="none"
                        href={`/categorias/${elem.url}`}
                        message={elem.name}
                        onClick={onClose}
                        onMouseEnter={this.showCategory(elem.id, index)}
                    />
                </li>)}
            </ul>
            {showMenues && <div
                className={`submenu-category-subs + ${isSmall ? "submenu-category-small" : ""}`}
                style={{ backgroundImage : `url(${category.image})` }}
            >
                <h2>{category.name}</h2>
                <ul className="no-list">
                    {category.childs.map((elem) => <li key={elem.id}>
                        <HyperLink
                            variant="gray"
                            href={`/categorias/${category.url}/${elem.url}`}
                            message={elem.name}
                            onClick={onClose}
                        />
                    </li>)}
                </ul>
            </div>}
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open    : PropTypes.bool.isRequired,
        data    : PropTypes.array.isRequired,
        onEnter : PropTypes.func.isRequired,
        onLeave : PropTypes.func.isRequired,
        onClose : PropTypes.func.isRequired,
    }

    /**
     * The Context Types
     * @typedef {Object} contextTypes
     */
    static contextTypes = {
        router : PropTypes.shape({
            history : PropTypes.object.isRequired,
        }),
    }
}

export default CategoryMenu;
