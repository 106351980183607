import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Notifications        from "Utils/Common/Notifications";
import NLS                  from "Utils/App/NLS";

// Components
import NotificationItem     from "./NotificationItem";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import Content              from "Components/Utils/Common/Content";
import Button               from "Components/Utils/Form/Button";



/**
 * The Notification Page
 */
class NotificationPage extends React.Component {
    // The Current State
    state = {
        show : true,
    }

    /**
     * Handles the Notification Enabled
     * @returns {Void}
     */
    handleNotifications = async () => {
        const message = NLS.get("NOTIFICATIONS_ENABLED");
        const result  = await Notifications.requestPermission(message);
        if (result) {
            this.setState({ show : false });
        }
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { list } = this.props;
        const { show } = this.state;

        const button = Notifications.canGrant() && show;

        return <Wrapper className="notification-container" withSpacing>
            <Title message="NOTIFICATIONS_NAME" />
            <Content
                className="notification-content"
                none="NOTIFICATIONS_NONE_AVAILABLE"
                hasContent={!!list.length}
                withBorder
            >
                <ul className="no-list">
                    {list.map((elem, index) => <li key={elem.key}>
                        <NotificationItem data={elem} index={index} />
                    </li>)}
                    {button && <li className="submenu-button">
                        <Button
                            variant="primary"
                            message="NOTIFICATIONS_ENABLE"
                            onClick={this.handleNotifications}
                            fullWidth
                        />
                    </li>}
                </ul>
            </Content>
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        list : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            list : state.notification.list,
        };
    }
}

export default connect(NotificationPage.mapStateToProps, null)(NotificationPage);
