import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Styles
import "Styles/Components/Utils/Common/Table.css";



/**
 * The Table
 */
class Table extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { className, hasContent, hasActions, none, children } = this.props;

        // There are no results
        if (!hasContent) {
            return <h3 className="table-none">{NLS.get(none)}</h3>;
        }

        // There are
        return <div className={"table-container " + (hasActions ? "table-actions " : "") + className}>
            <table>
                {children.map((elem, key) => React.cloneElement(elem, { key, hasActions }))}
            </table>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className  : PropTypes.string,
        hasContent : PropTypes.bool,
        none       : PropTypes.string.isRequired,
        hasActions : PropTypes.bool,
        children   : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className  : "",
        hasContent : false,
        hasActions : false,
    }

    /**
     * The Context Types
     * @typedef {Object} contextTypes
     */
    static contextTypes = {
        router : PropTypes.shape({
            history : PropTypes.object.isRequired,
        }),
    }
}

export default Table;
