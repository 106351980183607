import Utils    from "./Utils";



export default class Production {
    /**
     * Creates a new Content Instance
     * @param {Object} data
     * @param {String} url
     * @param {Number} type
     * @param {Number} price
     * @param {Number} sort
     * @param {Number} amount
     */
    constructor(data, url, type, price, sort, amount) {
        this.data          = data;
        this.crumbs        = [];
        this.category      = "";
        this.categories    = [];
        this.subcategory   = "";
        this.subcategories = [];

        this.url           = url;
        this.baseUrl       = url;
        this.catUrl        = url;
        this.subUrl        = url;
        this.suffix        = Production.getSuffix({ amount, type, price, sort });
    }

    /**
     * Returns the Full Url
     * @returns {String}
     */
    get fullUrl() {
        return this.baseUrl + this.suffix;
    }



    /**
     * Adds a Crumb
     * @param {String} to
     * @param {String} name
     * @returns {Void}
     */
    addCrumb(to, name) {
        this.crumbs.push({ to, name });
    }

    /**
     * Adds a Crumb if there are Categories
     * @param {String} category
     * @returns {Void}
     */
    addCategory(category) {
        this.catUrl     = this.baseUrl;
        this.category   = category;
        this.categories = this.data.categories;

        if (category && this.data.category) {
            this.baseUrl += `/${this.data.category.hash}`;
            this.crumbs.push({
                to   : this.baseUrl + this.suffix,
                name : this.data.category.name,
            });
        }
    }

    /**
     * Adds a Crumb if there are Subcategories
     * @param {String} subcategory
     * @returns {Void}
     */
    addSubcategory(subcategory) {
        if (this.category && this.data.subcategories && this.data.subcategories.length) {
            this.subUrl       += `/${this.data.category.hash}`;
            this.subcategory   = subcategory;
            this.subcategories = this.data.subcategories;
        }
        if (subcategory && this.data.subcategory) {
            this.baseUrl += `/${this.data.subcategory.hash}`;
            this.crumbs.push({
                to   : this.baseUrl + this.suffix,
                name : this.data.subcategory.name,
            });
        }
    }



    /**
     * Returns the Params for the Fetch
     * @param {Object} initials
     * @param {Object} params
     * @returns {Object}
     */
    static getParams(initials, params) {
        if (params.amount && typeof params.amount === "string" && params.amount.startsWith("a")) {
            params.amount = Number(params.amount.substr(1));
        }
        if (params.type && typeof params.type === "string" && params.type.startsWith("t")) {
            params.type = Number(params.type.substr(1));
        }
        if (params.sort && typeof params.sort === "string" && params.sort.startsWith("s")) {
            params.sort = Number(params.sort.substr(1));
        }
        if (params.price && typeof params.price === "string" && params.price.startsWith("p")) {
            params.price = Number(params.price.substr(1));
        }
        if (params.page && typeof params.page === "string") {
            params.page = Number(params.page);
        }
        return Utils.extend(initials, params);
    }

    /**
     * Returns the Initial Params for the Fetch
     * @param {String=}  param
     * @param {Number=}  initialSort
     * @param {Boolean=} withType
     * @param {Boolean=} withAmount
     * @returns {Object}
     */
    static getInitialParams(param = null, initialSort = 1, withType = true, withAmount = false) {
        const result = {
            category    : "",
            subcategory : "",
            price       : 1,
            sort        : initialSort,
            page        : 1,
        };
        if (param) {
            result[param] = "";
        }
        if (withType) {
            result.type   = 0;
        }
        if (withAmount) {
            result.amount = 1;
        }
        return result;
    }

    /**
     * Returns the url Suffix
     * @param {{amount: Number, type: Number, price: Number, sort: Number }} params
     * @returns {String}
     */
    static getSuffix({ amount, type, price, sort }) {
        let prefix = "";
        if (amount !== undefined) {
            prefix = `/a${amount}`;
        }
        if (type !== undefined) {
            prefix = `${prefix}/t${type}`;
        }
        return `${prefix}/p${price}/s${sort}`;
    }

    /**
     * Returns the Options extending the Defaults
     * @param {Object=} options
     * @param {Object=} defaults
     * @returns {Object}
     */
    static getSuffixDef(options = {}, defaults = {}) {
        const keys   = [ "amount", "type", "price", "sort" ];
        const params = {};
        for (const key of keys) {
            params[key] = options[key] !== undefined ? options[key] : defaults[key];
        }
        return Production.getSuffix(params);
    }
}
