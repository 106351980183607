import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import EditDialog           from "Components/Utils/Dialog/EditDialog";
import Icon                 from "Components/Utils/Common/Icon";



/**
 * The Address Dialog
 */
class AddressDialog extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, data, selectedID, onSelect, onClose } = this.props;
        const hasAddresses = data.length > 0;

        return <EditDialog
            open={open}
            className="cart-address-dialog"
            message="CART_SELECT_ADDRESS"
            icon="address"
            onClose={onClose}
        >
            {!hasAddresses ? <h3>{NLS.get("CART_SELECT_NONE_ADDRESS")}</h3> :
                <div className="cart-address-select">
                    {data.map((elem) => <div
                        key={elem.addressID}
                        className={
                            "cart-address-content spacing" +
                            (selectedID === elem.addressID ? " cart-address-selected" : "")
                        }
                        onClick={() => onSelect(elem.addressID)}
                    >
                        <Icon variant="location" />
                        <div className="cart-address-current">
                            <h3>{elem.description}</h3>
                            <ul className="no-list">
                                <li>{elem.address}</li>
                                <li>{elem.province}</li>
                                <li>{elem.locality}</li>
                                <li>{elem.postalCode}</li>
                                <li>{elem.schedule}</li>
                            </ul>
                        </div>
                    </div>)}
                </div>}
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open       : PropTypes.bool.isRequired,
        data       : PropTypes.array.isRequired,
        selectedID : PropTypes.number.isRequired,
        onSelect   : PropTypes.func.isRequired,
        onClose    : PropTypes.func.isRequired,
    }
}

export default AddressDialog;
