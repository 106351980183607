import API                  from "Utils/App/API";
import Auth                 from "Utils/App/Auth";

// Types
import {
    AUTH_CURRENT_USER,
} from "Utils/Types";



/**
 * Sets the Current Credential
 * @param {Object} credential
 * @returns {{type: String, user: Object}}
 */
export function setCurrentUser(credential) {
    return { type : AUTH_CURRENT_USER, credential };
}



/**
 * Logs In the User
 * @param {Object} data
 * @returns {Function}
 */
export function login(data) {
    return async () => API.Auth.login(data);
}

/**
 * Rcovers the Credentials
 * @param {Object} data
 * @returns {Function}
 */
export function recover(data) {
    return async () => {
        const result = await API.Auth.recover(data);
        return result.success;
    };
}

/**
 * Logouts the User
 * @returns {Function}
 */
export function logout() {
    return () => {
        API.abort();
        Auth.unsetToken();
        API.Auth.logout();
    };
}
