import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import Notifications        from "Utils/Common/Notifications";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import NotificationItem     from "Components/Content/Notifications/NotificationItem";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    fetchNotifications,
} from "Actions/Content/NotificationActions";



/**
 * The Notification Menu
 */
class NotificationMenu extends React.Component {
    state = {
        time    : null,
        timeout : null,
        show    : true,
    }

    /**
     * Starts the Timeout
     * @returns {Void}
     */
    componentDidMount() {
        this.startFetch();
    }

    /**
     * End the Timeouts
     * @returns {Void}
     */
    componentWillUnmount() {
        if (this.state.timeout) {
            window.clearTimeout(this.state.timeout);
        }
    }

    /**
     * Starts the Auto Fetch
     * @returns {Void}
     */
    startFetch() {
        this.setState({
            time    : new Date(),
            timeout : window.setTimeout(this.doFetch, 10000),
        });
    }

    /**
     * Does the Auto Fetch
     * @returns {Void}
     */
    doFetch = async () => {
        const time    = Math.floor(this.state.time.getTime() / 1000);
        const request = await this.props.fetchNotifications(time);
        
        if (Notifications.isGranted() && request && Array.isArray(request)) {
            for (const elem of request) {
                const data = Notifications.getData(elem);
                Notifications.show(data.title, data.body, this.handleClick(elem.url));
            }
        }
        this.startFetch();
    }

    /**
     * Goes to the Url
     * @param {String} href
     * @returns {Function}
     */
    handleClick = (href) => () => {
        if (!href) {
            return;
        }
        if (Utils.hrefInApp(href)) {
            this.props.history.push(Utils.getUrl(href));
        } else if (!href.startsWith("http")) {
            this.props.history.push(href);
        } else {
            window.location.href = href;
        }
    }

    /**
     * Handles the Notification Enabled
     * @returns {Void}
     */
    handleNotifications = async () => {
        const result = await Notifications.requestPermission(NLS.get("NOTIFICATIONS_ENABLED"));
        if (result) {
            this.setState({ show : false });
        }
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { open, data, onEnter, onLeave, onClose } = this.props;
        const { show                                  } = this.state;

        const button = Notifications.canGrant() && show;

        return <nav
            className={"submenu-notification submenu-secondary" + (open ? " submenu-open" : "")}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <h3>{NLS.get("NOTIFICATIONS_NAME")}</h3>
            <ul className="no-list">
                {data.map((elem, index) => <li key={elem.key}>
                    <NotificationItem data={elem} index={index} onClose={onClose} />
                </li>)}
                {button && <li className="submenu-button">
                    <Button
                        variant="primary"
                        message="NOTIFICATIONS_ENABLE"
                        onClick={this.handleNotifications}
                        fullWidth
                    />
                </li>}
            </ul>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history            : PropTypes.object.isRequired,
        fetchNotifications : PropTypes.func.isRequired,
        open               : PropTypes.bool.isRequired,
        data               : PropTypes.array.isRequired,
        onEnter            : PropTypes.func.isRequired,
        onLeave            : PropTypes.func.isRequired,
        onClose            : PropTypes.func.isRequired,
    }
}

export default withRouter(connect(null, {
    fetchNotifications,
})(NotificationMenu));
