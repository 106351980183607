import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import TermsContent         from "./TermsContent";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";

// Styles
import "Styles/Components/Terms/Terms.css";



/**
 * The Terms User
 */
class TermsUser extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const backUrl = this.props.isApp ? "/cuenta" : "";

        return <Wrapper withSpacing>
            <Title message="TERMS_NAME" href={backUrl} />
            <TermsContent />
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isApp : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp : state.core.isApp,
        };
    }
}

export default connect(TermsUser.mapStateToProps)(TermsUser);
