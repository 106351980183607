import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductCartPrice     from "Components/Product/Item/ProductCartPrice";
import HyperLink            from "Components/Utils/Common/HyperLink";
import NumberField          from "Components/Utils/Form/NumberField";



/**
 * The Product List
 */
class ProductList extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, onEdit, onDelete, loading } = this.props;

        return <table className="cart-table">
            <tbody>
                {data.map((elem, index) => <tr key={index}>
                    <td><ProductImage className="cart-image" data={elem} /></td>
                    <td>
                        <div className="cart-product">
                            <h4>{elem.productCode}</h4>
                            <h3>{elem.description}</h3>
                            {elem.stock !== undefined && <p className="cart-stock">
                                {NLS.format("CART_STOCK", Math.max(elem.stock, 0))}
                            </p>}
                        </div>
                    </td>
                    <td>
                        <div className="cart-column">
                            <h4>{NLS.get("CART_AMOUNT")}</h4>
                            <div className="cart-amount">
                                <NumberField
                                    name="amount"
                                    value={elem.amount}
                                    onChange={onEdit(elem.productCode)}
                                    isDisabled={loading}
                                />
                                <HyperLink
                                    variant="light"
                                    message="CART_DELETE"
                                    onClick={onDelete(elem.productCode)}
                                />
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="cart-column">
                            <h4>{NLS.get("CART_PRICE")}</h4>
                            <ProductCartPrice data={elem.price} />
                        </div>
                    </td>
                    <td>
                        <div className="cart-column">
                            <h4>{NLS.get("CART_SUBTOTAL")}</h4>
                            <ProductCartPrice data={elem.subtotal} />
                        </div>
                    </td>
                </tr>)}
            </tbody>
        </table>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data     : PropTypes.array.isRequired,
        onEdit   : PropTypes.func.isRequired,
        onDelete : PropTypes.func.isRequired,
        loading  : PropTypes.bool.isRequired,
    }
}

export default ProductList;
