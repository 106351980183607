// Types
import {
    ADDRESS_LIST, ADDRESS_DATA, ADDRESS_UNSET,
} from "Utils/Types";



// The initial State
const initialState = {
    error     : false,
    addresses : [],
    address   : {},
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case ADDRESS_LIST:
        return {
            ...state,
            addresses : action.data,
        };
    case ADDRESS_DATA:
        return {
            ...state,
            address : action.data,
        };
    case ADDRESS_UNSET:
        return {
            ...state,
            address : {},
        };
    default:
        return state;
    }
};
