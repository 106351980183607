import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchOffer }       from "Actions/Store/OfferActions";
import Production           from "Utils/Common/Production";
import Utils                from "Utils/Common/Utils";

// Components
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsHeader       from "Components/Product/List/ProductsHeader";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsAside        from "Components/Product/List/ProductsAside";
import ProductsList         from "Components/Product/List/ProductsList";



/**
 * The Offer Page
 */
class OfferPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.fetchData(params);
    }

    /**
     * Load the Data if the Offer changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.fetchData(newParams);
        }
    }

    /**
     * Returns the Params for the Fetch
     * @param {Object=} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        const initials = Production.getInitialParams("offer", 1, true, true);
        return Production.getParams(initials, params);
    }

    /**
     * Fetches the content
     * @param {Object} params
     * @returns {Void}
     */
    fetchData(params) {
        this.props.fetchOffer(params);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isMobile, data, error } = this.props;
        const { offer, amount, category, subcategory, page, type, price, sort } = this.getParams();

        const discAmount = amount || 1;
        const production = new Production(data, `/ofertas/${offer}`, type, price, sort, discAmount);
        const image      = isMobile ? data.mobile : data.banner;

        production.addCrumb("/ofertas", "OFFERS_TITLE");
        production.addCrumb(production.fullUrl, "OFFERS_NAME");
        production.addCategory(category);
        production.addSubcategory(subcategory);

        return <ProductsContainer error={error}>
            <ProductsHeader className="products-offer" banner={image} />
            <ProductsContent noHeader>
                <ProductsAside
                    name={data.name}
                    results={data.results}
                    crumbs={production.crumbs}
                    sort={sort}
                    amounts={data.amounts}
                    amount={discAmount}
                    categories={production.categories}
                    category={production.category}
                    subcategories={production.subcategories}
                    subcategory={production.subcategory}
                    types={data.types}
                    type={type}
                    prices={data.prices}
                    price={price}
                    baseUrl={production.baseUrl}
                    catUrl={production.catUrl}
                    subUrl={production.subUrl}
                    withType
                    withAmount
                />
                <ProductsList
                    products={data.products}
                    page={page}
                    pages={data.pages}
                    url={production.fullUrl}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOffer : PropTypes.func.isRequired,
        match      : PropTypes.object.isRequired,
        isMobile   : PropTypes.bool.isRequired,
        data       : PropTypes.object.isRequired,
        error      : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMobile : state.core.isMobile,
            data     : state.offer.offer,
            error    : state.offer.error,
        };
    }
}

export default connect(OfferPage.mapStateToProps, {
    fetchOffer,
})(OfferPage);
