import React                from "react";
import PropTypes            from "prop-types";

// Components
import ErrorPage            from "Components/Content/ErrorPage";



/**
 * The Products Container
 */
class ProductsContainer extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { error, children } = this.props;
        if (error) {
            return <ErrorPage />;
        }
        return children;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        error    : PropTypes.bool.isRequired,
        children : PropTypes.any,
    }
}

export default ProductsContainer;
