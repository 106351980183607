import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleFavorite }   from "Actions/Store/StoreActions";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Product/Item/ProductCard.css";



/**
 * The Product Card
 */
class ProductCard extends React.Component {
    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleFavorite = (e) => {
        const { data, toggleFavorite, onFavorite } = this.props;
        toggleFavorite(data.productCode);
        if (onFavorite) {
            onFavorite(data.productCode);
        }
        e.preventDefault();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, hover, onClick } = this.props;
        data.description = data.description.replace(/\//g, " / ");

        return <div className={`product-container ${hover ? "product-hover" : ""}`} onClick={onClick}>
            <div className="product-card">
                <div className="product-code">
                    {data.productCode}
                </div>
                <ProductImage className="product-card-image" data={data} />
                <ProductPrice className="product-card-price" data={data} />
                <div className="product-description">
                    {data.description}
                </div>
                <div className="product-button">
                    <Button variant="primary" message="PRODUCTS_ADD_TO_CART" fullWidth />
                </div>
                <HyperLink
                    className="product-card-favorite"
                    variant="icon"
                    icon={data.isFavorite ? "favorite" : "unfavorite"}
                    onClick={this.handleFavorite}
                />
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleFavorite : PropTypes.func.isRequired,
        data           : PropTypes.object.isRequired,
        onClick        : PropTypes.func.isRequired,
        onFavorite     : PropTypes.func,
        hover          : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        hover : false,
    }
}

export default connect(null, {
    toggleFavorite,
})(ProductCard);
