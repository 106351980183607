import API                  from "Utils/App/API";

// Types
import {
    STORE_HEADER, STORE_HOME,
    STORE_FAVORITE, STORE_HISTORY,
} from "Utils/Types";



/**
 * Fetches the Header
 * @returns {Function}
 */
export function fetchHeader() {
    return async (dispatch) => {
        const data = await API.Store.getHeader();
        dispatch({ type : STORE_HEADER, data });
    };
}

/**
 * Fetches the Home
 * @returns {Function}
 */
export function fetchHome() {
    return async (dispatch) => {
        const data = await API.Store.getHome();
        dispatch({ type : STORE_HOME, data });
    };
}



/**
 * Toggles the Product Favorite
 * @param {String} productCode
 * @returns {Function}
 */
export function toggleFavorite(productCode) {
    return async (dispatch) => {
        const data = await API.Store.toggleFavorite({ productCode });
        dispatch({ type : STORE_FAVORITE, data });
        return data.success;
    };
}

/**
 * Adds a Product to the History
 * @param {String} productCode
 * @returns {Function}
 */
export function addToHistory(productCode) {
    return async (dispatch) => {
        const data = await API.Store.addToHistory({ productCode });
        dispatch({ type : STORE_HISTORY, data });
        return data.success;
    };
}
