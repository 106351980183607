import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { MainMenu }         from "Utils/App/Data";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Core/Bar.css";



/**
 * The Bar
 */
class Bar extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { path, notification } = this.props;

        return <footer className="bar-container">
            <Wrapper className="bar-wrapper">
                <ul className="bar-list no-list">
                    {MainMenu.map(({ key, href, message }) => <li key={key}>
                        <HyperLink
                            className={path.startsWith(href) ? "bar-selected" : ""}
                            variant="menu"
                            icon={key}
                            href={href}
                            message={message}
                        />
                    </li>)}
                    <li>
                        <HyperLink
                            className={path.startsWith("/notificaciones") ? "bar-selected" : ""}
                            variant="menu"
                            icon="notifications"
                            href="/notificaciones"
                            message="NOTIFICATIONS_NAME"
                            badge={notification}
                        />
                    </li>
                    <li>
                        <HyperLink
                            className={path.startsWith("/cuenta") ? "bar-selected" : ""}
                            variant="menu"
                            icon="account"
                            href="/cuenta"
                            message="CLIENT_NAME"
                        />
                    </li>
                </ul>
            </Wrapper>
        </footer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        path         : PropTypes.string.isRequired,
        notification : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            notification : state.notification.list.length,
        };
    }
}

export default connect(Bar.mapStateToProps, null)(Bar);
