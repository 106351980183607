import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchBanks }       from "Actions/Client/ClientActions";
import NLS                  from "Utils/App/NLS";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";

// Styles
import "Styles/Images/Banks/Header.png";
import "Styles/Images/Banks/Background.jpg";
import "Styles/Components/Client/Banks.css";



/**
 * The Bank Page
 */
class BankPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchBanks();
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isTablet, data } = this.props;

        return <Card className="client-card">
            <SubTitle show={!isTablet} message="BANKS_NAME" icon="bank" />
            <section className="banks-content">
                <div className="banks-image" />
                <div className="banks-grid">
                    <div className="banks-bank" />
                    {data.map((elem) => <div key={elem.bankID} className="banks-bank">
                        <img src={elem.logoUrl} alt={elem.name} />
                        <div>
                            <h3>{elem.name}</h3>
                            <p>{elem.type}</p>
                            <p>{NLS.get("BANKS_ACCOUNT")}: {elem.account}</p>
                            <p>{NLS.get("BANKS_CBU")}: <b>{elem.cbu}</b></p>
                        </div>
                    </div>)}
                </div>
            </section>
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchBanks : PropTypes.func.isRequired,
        isTablet   : PropTypes.bool.isRequired,
        data       : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isTablet : state.core.isTablet,
            data     : state.client.banks,
        };
    }
}

export default connect(BankPage.mapStateToProps, {
    fetchBanks,
})(BankPage);
