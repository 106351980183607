import React                from "react";
import PropTypes            from "prop-types";

// Styles
import "Styles/Components/Utils/Common/Card.css";



/**
 * The Card
 */
class Card extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, withSpacing, children } = this.props;

        return <section className={`card ${className} ${withSpacing ? "spacing" : ""}`}>
            {children}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className   : PropTypes.string,
        withSpacing : PropTypes.bool,
        children    : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        withSpacing : false,
    }
}

export default Card;
