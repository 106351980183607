import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCategory }    from "Actions/Store/CategoryActions";
import Production           from "Utils/Common/Production";
import Utils                from "Utils/Common/Utils";

// Components
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsAside        from "Components/Product/List/ProductsAside";
import ProductsList         from "Components/Product/List/ProductsList";



/**
 * The Category Page
 */
class CategoryPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchCategory(params);
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchCategory(newParams);
        }
    }

    /**
     * Returns the Params for the Fetch
     * @param {Object=} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        const initials = Production.getInitialParams();
        return Production.getParams(initials, params);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, error } = this.props;
        const { category, subcategory, page, type, price, sort } = this.getParams();
        const production = new Production(data, `/categorias/${category}`, type, price, sort);
        const name       = data.subcategory.name || data.name;

        production.addCrumb("/categorias", "CATEGORIES_NAME");
        production.addCrumb(production.fullUrl, data.name);
        production.addSubcategory(subcategory);

        return <ProductsContainer error={error}>
            <ProductsContent error={error} noHeader>
                <ProductsAside
                    name={name}
                    results={data.results}
                    crumbs={production.crumbs}
                    sort={sort}
                    categories={data.subcategories}
                    category={subcategory}
                    types={data.types}
                    type={type}
                    prices={data.prices}
                    price={price}
                    baseUrl={production.baseUrl}
                    catUrl={production.catUrl}
                    withType
                />
                <ProductsList
                    products={data.products}
                    page={page}
                    pages={data.pages}
                    url={production.fullUrl}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCategory : PropTypes.func.isRequired,
        data          : PropTypes.object.isRequired,
        error         : PropTypes.bool.isRequired,
        match         : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data  : state.category.category,
            error : state.category.error,
        };
    }
}

export default connect(CategoryPage.mapStateToProps, {
    fetchCategory,
})(CategoryPage);
