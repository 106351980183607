import React                from "react";
import PropTypes            from "prop-types";
import { withRouter }       from "react-router";
import NLS                  from "Utils/App/NLS";

// Components
import TextField          from "Components/Utils/Form/TextField";



/**
 * The Sort Filter
 */
class SortFilter extends React.Component {
    /**
     * Handles the Sort Change
     * @param {Stirng} name
     * @param {Number} value
     * @returns {Void}
     */
    onChange = (name, value) => {
        this.props.history.push(this.props.getUrl(value));
        this.props.onChange();
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { selected } = this.props;
        const options = [
            { key : 1, value : NLS.get("PRODUCTS_SORT_NAME_AZ")       },
            { key : 2, value : NLS.get("PRODUCTS_SORT_NAME_ZA")       },
            { key : 3, value : NLS.get("PRODUCTS_SORT_CODE_AZ")       },
            { key : 4, value : NLS.get("PRODUCTS_SORT_CODE_ZA")       },
            { key : 5, value : NLS.get("PRODUCTS_SORT_LOWER_PRICE")   },
            { key : 6, value : NLS.get("PRODUCTS_SORT_GREATER_PRICE") },
            { key : 7, value : NLS.get("PRODUCTS_SORT_NEWER_PRODUCT") },
            { key : 8, value : NLS.get("PRODUCTS_SORT_OLDER_PRODUCT") },
        ];

        return <div className="aside-options">
            <h4>{NLS.get("PRODUCTS_SORT")}</h4>
            <p className="aside-option">
                <TextField
                    type="select"
                    name="sort"
                    options={options}
                    value={selected}
                    onChange={this.onChange}
                />
            </p>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history  : PropTypes.object.isRequired,
        selected : PropTypes.number.isRequired,
        getUrl   : PropTypes.func.isRequired,
        onChange : PropTypes.func.isRequired,
    }
}

export default withRouter(SortFilter);
