import API                  from "Utils/App/API";

// Types
import {
    ORDER_LIST, ORDER_DATA,
    ORDER_GROUP_LIST, ORDER_GROUP_DATA,
} from "Utils/Types";



/**
 * Fetches the Order List
 * @param {Object} params
 * @returns {Function}
 */
export function fetchOrders(params) {
    return async (dispatch) => {
        const data = await API.Order.getOrders(params);
        dispatch({ type : ORDER_LIST, data });
    };
}

/**
 * Fetches the Order Data
 * @param {Number} orderID
 * @returns {Function}
 */
export function fetchOrder(orderID) {
    return async (dispatch) => {
        const data = await API.Order.getOrder({ orderID });
        dispatch({ type : ORDER_DATA, data });
    };
}



/**
 * Fetches the Order Group List
 * @param {Object} params
 * @returns {Function}
 */
export function fetchOrderGroups(params) {
    return async (dispatch) => {
        const data = await API.Order.getGroups(params);
        dispatch({ type : ORDER_GROUP_LIST, data });
    };
}

/**
 * Fetches the Order Group Data
 * @param {Number} groupID
 * @returns {Function}
 */
export function fetchOrderGroup(groupID) {
    return async (dispatch) => {
        const data = await API.Order.getGroup({ groupID });
        dispatch({ type : ORDER_GROUP_DATA, data });
    };
}
