import Utils                from "Utils/Common/Utils";

// Types
import {
    CATEGORY_LIST, CATEGORY_DATA,
    STORE_FAVORITE,
} from "Utils/Types";



// The initial State
const initialState = {
    error      : false,
    categories : [],
    category   : {
        name          : "GENERAL_LOADING",
        url           : "",
        subcategory   : {},
        subcategories : [],
        products      : [],
        results       : 0,
        total         : 0,
        pages         : 1,
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case CATEGORY_LIST:
        return {
            ...state,
            error      : false,
            categories : action.data,
        };
    case CATEGORY_DATA:
        return {
            ...state,
            error      : false,
            category   : action.data,
        };

    // Update the Favorite
    case STORE_FAVORITE:
        return {
            ...state,
            error      : false,
            category   : Utils.setFavorite(state.category, action.data.productCode),
        };

    default:
        return state;
    }
};
