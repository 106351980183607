import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";



/**
 * The Bike Menu
 */
class BikeMenu extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { open, data, onEnter, onLeave, onClose } = this.props;

        return <nav
            className={"submenu-container submenu-full submenu-bike" + (open ? " submenu-open" : "")}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className="submenu-bike-grid submenu-grid no-list">
                {data.map((elem) => <li key={elem.id}>
                    <HyperLink variant="image" href={`/motos/${elem.url}`} onClick={onClose}>
                        <header>
                            <h3>{NLS.get("BIKES_SPARES")}</h3>
                            <h2>{elem.name}</h2>
                        </header>
                        <img src={elem.image} alt={elem.name} />
                    </HyperLink>
                </li>)}
            </ul>
            <div className="submenu-more">
                <Button
                    variant="primary"
                    href="/cilindradas"
                    message="HEADER_MORE"
                    onClick={onClose}
                />
            </div>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open    : PropTypes.bool.isRequired,
        data    : PropTypes.array.isRequired,
        onEnter : PropTypes.func.isRequired,
        onLeave : PropTypes.func.isRequired,
        onClose : PropTypes.func.isRequired,
    }
}

export default BikeMenu;
