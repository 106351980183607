// Types
import {
    VOUCHER_INVOICES, VOUCHER_RECEIPTS,
    VOUCHER_CREDIT_NOTES, VOUCHER_DEBIT_NOTES,
} from "Utils/Types";



// The initial State
const initialState = {
    error       : false,
    invoices    : {
        list  : [],
        total : 0,
    },
    receipts    : {
        list  : [],
        total : 0,
    },
    creditNotes : {
        list  : [],
        total : 0,
    },
    debitNotes  : {
        list  : [],
        total : 0,
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case VOUCHER_INVOICES:
        return {
            ...state,
            invoices : action.data,
        };
    case VOUCHER_RECEIPTS:
        return {
            ...state,
            receipts : action.data,
        };
    case VOUCHER_CREDIT_NOTES:
        return {
            ...state,
            creditNotes : action.data,
        };
    case VOUCHER_DEBIT_NOTES:
        return {
            ...state,
            debitNotes : action.data,
        };
    default:
        return state;
    }
};
