/**
 * Returns true if the Object is empty
 * @param {Object} object
 * @returns {Boolean}
 */
function isEmpty(object) {
    return !object || Object.keys(object).length === 0;
}

/**
 * Clones an Object
 * @param {Object} object
 * @returns {Object}
 */
function clone(object) {
    return JSON.parse(JSON.stringify(object));
}

/**
 * Returns true if both given objects are the same at 1 level deep
 * @param {Object} a
 * @param {Object} b
 * @returns {Boolean}
 */
function areObjectsEqual(a, b) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }
    for (let i = 0; i < aProps.length; i += 1) {
        if (a[aProps[i]] !== b[aProps[i]]) {
            return false;
        }
    }
    return true;
}

/**
 * Creates an Array of N numbers
 * @param {Number} amount
 * @returns {Array.<Number>}
 */
function createArrayOf(amount) {
    const result = [];
    for (let i = 1; i <= amount; i++) {
        result.push(i);
    }
    return result;
}

/**
 * Creates a Map from an Array
 * @param {Array.<Object>} request
 * @param {String}         id
 * @returns {Object.<Number, Object>}
 */
function createMap(request, id) {
    const result = {};
    for (const elem of request) {
        result[elem[id]] = elem;
    }
    return result;
}

/**
 * Returns the Data at the given id with the given value
 * @param {Array.<Object>} data
 * @param {String}         idKey
 * @param {Number}         idValue
 * @returns {Object}
 */
function getData(data, idKey, idValue) {
    for (const elem of data) {
        if (elem[idKey] === idValue) {
            return elem;
        }
    }
    return {};
}

/**
 * Returns the Index at the given id with the given value
 * @param {Array.<Object>} data
 * @param {String}         idKey
 * @param {Number}         idValue
 * @returns {Number}
 */
function getIndex(data, idKey, idValue) {
    for (const [ index, elem ] of data.entries()) {
        if (elem[idKey] === idValue) {
            return index;
        }
    }
    return 0;
}

/**
 * Returns the Value at the given id with the given key
 * @param {Array.<Object>} data
 * @param {String}         idKey
 * @param {Number}         idValue
 * @param {String}         key
 * @returns {String}
 */
function getValue(data, idKey, idValue, key) {
    const elem = getData(data, idKey, idValue);
    return elem[key];
}

/**
 * Uses the keys from primary and sets the secondary values if are set
 * @param {Object} primary
 * @param {Object} secondary
 * @returns {Object}
 */
function extend(primary, secondary) {
    const result = {};
    for (const [ key, value ] of Object.entries(primary)) {
        result[key] = secondary[key] !== undefined ? secondary[key] : value;
    }
    return result;
}

/**
 * Merges the View, Create and Edit into an Edit type
 * @param {String} type
 * @returns {String}
 */
function mergeVCE(type) {
    if (type === "view" || type === "create") {
        return "edit";
    }
    return type;
}



/**
 * Returns true if the given href is from the App
 * @param {String} href
 * @returns {Boolean}
 */
function hrefInApp(href) {
    return href && href.startsWith(process.env.REACT_APP_URL) && !href.startsWith(process.env.REACT_APP_VOUCHER);
}

/**
 * Returns the url without the complete path
 * @param {String} href
 * @returns {Boolean}
 */
function getUrl(href) {
    return href.replace(process.env.REACT_APP_URL, "");
}

/**
 * Clears the Selection
 * @returns {Void}
 */
function clearSelection() {
    if (window.getSelection) {
        if (window.getSelection().empty) {  // Chrome
            window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {  // Firefox
            window.getSelection().removeAllRanges();
        }
    } else if (document.selection) {  // IE?
        document.selection.empty();
    }
}

/**
 * Updates the Favorite State of the Product
 * @param {Object} data
 * @param {String} productCode
 * @returns {Object}
 */
function setFavorite(data, productCode) {
    if (data.products.length) {
        const products = setFavoriteList(data.products, productCode);
        return { ...data, products };
    }
    return { ...data };
}

/**
 * Updates the Favorite State of the Product List
 * @param {Array.<Object>} list
 * @param {String}         productCode
 * @returns {Object}
 */
function setFavoriteList(list, productCode) {
    if (list.length) {
        const products = [ ...list ];
        for (const product of products) {
            if (product.productCode === productCode) {
                product.isFavorite = !product.isFavorite;
            }
        }
        return products;
    }
    return list;
}




// The Public API
export default {
    isEmpty,
    clone,
    areObjectsEqual,
    createArrayOf,
    createMap,
    getData,
    getIndex,
    getValue,
    extend,
    mergeVCE,

    hrefInApp,
    getUrl,
    clearSelection,
    setFavorite,
    setFavoriteList,
};
