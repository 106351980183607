import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { replyQuery }       from "Actions/Client/QueryActions";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";
import Alert                from "Components/Utils/Form/Alert";



/**
 * The Query Reply
 */
class QueryReply extends React.Component {
    // The Initial Data
    initialData = {
        message : "",
        image   : "",
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        success : "",
        errors  : {},
        error   : "",
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, success : "", errors : {}, error : "" });
        try {
            data.queryID = this.props.queryID;
            const success = await this.props.replyQuery(data);
            this.setState({
                data    : { ...this.initialData },
                loading : false,
                success,
            });
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, success, errors, error } = this.state;

        return <Card className="query-reply-form">
            <SubTitle message="QUERIES_REPLY" icon="query" />
            <form onSubmit={this.handleSubmit}>
                <Alert variant="success" message={success} onClose={this.closeAlert} />
                <Alert variant="error"   message={error}   onClose={this.closeAlert} />

                <TextField
                    type="textarea"
                    name="message"
                    label="QUERIES_MESSAGE"
                    value={data.message}
                    onChange={this.handleChange}
                    error={errors.message}
                    isRequired
                    withMargin
                />
                <TextField
                    type="file"
                    name="image"
                    label="QUERIES_IMAGE"
                    value={data.image}
                    onChange={this.handleChange}
                    error={errors.image}
                    withMargin
                />

                <div className="query-reply-button">
                    <Button
                        variant="primary"
                        className="query-reply-btn"
                        message="QUERIES_REPLY"
                        isDisabled={loading}
                        fullWidth
                    />
                </div>
            </form>
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        replyQuery : PropTypes.func.isRequired,
        queryID    : PropTypes.number.isRequired,
    }
}

export default connect(QueryReply.mapStateToProps, {
    replyQuery,
})(QueryReply);
