import React                from "react";
import PropTypes            from "prop-types";
import { FooterMenu }       from "Utils/App/Data";
import NLS                  from "Utils/App/NLS";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Images
import FacebookImage        from "Styles/Images/Footer/Facebook.png";
import TercomImage          from "Styles/Images/Footer/Tercom.png";

// Styles
import "Styles/Images/Footer/Background.jpg";
import "Styles/Components/Core/Footer.css";



/**
 * The Footer
 */
class Footer extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const fromYear = 2018;
        const toYear   = new Date().getFullYear();
        const links    = [
            {
                title : "FOOTER_ABOUT_US",
                items : FooterMenu,
            },
            {
                title : "FOOTER_STAY_CONNECTED",
                items : [
                    { message : "NEWS_NAME",           href : "/novedades",                       target : "_self"  },
                    { message : "FOOTER_DOWNLOAD_APP", href : "/app",                             target : "_self"  },
                    { message : "FOOTER_MAGAZINE",     href : "https://issuu.com/exclusivomotos", target : "_blank" },
                ],
                social : [
                    [ "Facebook", FacebookImage, "https://www.facebook.com/tercom.srl" ],
                    [ "Tercom",   TercomImage,   "http://www.tercom.com.ar"            ],
                ],
            },
            {
                title : "FOOTER_SITE_MAP",
                items : [
                    { message : "GENERAL_HOME",    href : "/"           },
                    { message : "CATEGORIES_NAME", href : "/categorias" },
                    { message : "OFFERS_NAME",     href : "/ofertas"    },
                    { message : "FOOTER_ACCOUNT",  href : "/cuenta"     },
                ],
            },
        ];

        return <footer className="footer-container">
            <Wrapper className="footer-wrapper">
                <div className="footer-content">
                    <section className="footer-links">
                        <div className="footer-grid">
                            {links.map(({ title, items, social }) => <div key={title}>
                                <h3>{NLS.get(title)}</h3>
                                <ul className="no-list">
                                    {items.map(({ message, href, target }) => <li key={message}>
                                        <HyperLink
                                            variant="white"
                                            href={href}
                                            message={message}
                                            target={target}
                                        />
                                    </li>)}
                                </ul>
                                {social && social.length > 0 && <ul className="footer-social no-list">
                                    {social.map(([ key, image, href ]) => <li key={key}>
                                        <HyperLink
                                            variant="opacity"
                                            href={href}
                                            target="_blank"
                                        >
                                            <img src={image} alt={key} />
                                        </HyperLink>
                                    </li>)}
                                </ul>}
                            </div>)}
                        </div>
                    </section>
                    <section className="footer-copyright">
                        <div>{NLS.format("FOOTER_COPYRIGHT", fromYear, toYear)}</div>
                    </section>
                </div>
                <div className="footer-fiscal">
                    <a href="http://qr.afip.gob.ar/?qr=UO-EaSXxnB4rug4qvg6I-w,," target="_F960AFIPInfo">
                        <img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt={NLS.get("FOOTER_FISCAL")} />
                    </a>
                </div>
            </Wrapper>
        </footer>;
    }



    /**
     * The Context Types
     * @typedef {Object} contextTypes
     */
    static contextTypes = {
        router : PropTypes.shape({
            history : PropTypes.object.isRequired,
        }),
    }
}

export default Footer;
