import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCart }        from "Actions/Store/CartActions";
import NLS                  from "Utils/App/NLS";

// Components
import ProductPage          from "./ProductPage";
import ShippingPage         from "./ShippingPage";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Card                 from "Components/Utils/Common/Card";
import Title                from "Components/Utils/Common/Title";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";
import Alert                from "Components/Utils/Form/Alert";

// Styles
import "Styles/Images/Shipping/1.png";
import "Styles/Images/Shipping/2.png";
import "Styles/Images/Shipping/3.png";
import "Styles/Components/Store/Cart.css";



/**
 * The Cart Container
 */
class CartContainer extends React.Component {
    // The Current State
    state = {
        success : "",
        error   : "",
        orderID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchCart();
    }

    /**
     * Scrolls to the Top
     * @returns {Void}
     */
    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    /**
     * Opens the Alert
     * @param {String=} success
     * @param {String=} error
     * @returns {Void}
     */
    openAlert = (success = "", error = "") => {
        this.setState({ success, error });
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }

    /**
     * Called when the Cart was completed
     * @param {Number} orderID
     * @returns {Void}
     */
    completeCart = (orderID) => {
        this.setState({ success : "", error : "", orderID });
    }



    /**
     * Renders the Content
     * @returns {Object}
     */
    renderContent() {
        const { amount, isCart, isShipping } = this.props.data;
        const { success, error, orderID    } = this.state;

        if (orderID) {
            return <Card className="cart-completed">
                <header className="spacing">
                    <h3>{NLS.get("CART_SUCCESS_COMPLETE")}</h3>
                    <h4>{NLS.get("CART_COMPLETED_CONTACT")}</h4>
                </header>
                <footer className="spacing">
                    <h4>{NLS.get("CART_COMPLETED_NEXT")}</h4>
                    <Button
                        variant="primary"
                        message="CART_COMPLETED_ORDER"
                        href={`/cuenta/pedido/${orderID}`}
                    />
                    <HyperLink variant="primary" href="/" message="CART_COMPLETED_MORE" />
                </footer>
            </Card>;
        }

        if (amount === 0) {
            return <Card className="cart-none" withSpacing>
                <h3 className="cart-none">{NLS.get("CART_NONE_AVAILABLE")}</h3>
                <Button variant="primary" message="CART_BUY_MORE" href="/" />
            </Card>;
        }

        return <>
            <Alert variant="success" message={success} onClose={this.closeAlert} />
            <Alert variant="error"   message={error}   onClose={this.closeAlert} />
            <div className="cart-container">
                {isCart && <ProductPage
                    openAlert={this.openAlert}
                    closeAlert={this.closeAlert}
                    onSubmit={this.scrollToTop}
                />}
                {isShipping && <ShippingPage
                    openAlert={this.openAlert}
                    closeAlert={this.closeAlert}
                    onComplete={this.completeCart}
                    onSubmit={this.scrollToTop}
                />}
            </div>
        </>;
    }

    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { amount } = this.props.data;
        const message = amount > 0 ? NLS.pluralize("CART_PRODUCTS", amount) : "";

        return <Wrapper withSpacing>
            <Title message="CART_TITLE" submessage={message} />
            {this.renderContent()}
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCart : PropTypes.func.isRequired,
        data      : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.cart.data,
        };
    }
}

export default connect(CartContainer.mapStateToProps, {
    fetchCart,
})(CartContainer);
