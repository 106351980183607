// Types
import {
    CART_DATA, ADDRESS_LIST,
} from "Utils/Types";



// The initial State
const initialState = {
    error : false,
    data  : {
        orderID    : 0,
        isCart     : true,
        isShipping : false,
        amount     : 0,
        items      : [],
        offers     : [],
        totals     : [],
        addresses  : [],
        canUseFree : false,
        map        : {},
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case CART_DATA:
        if (action.data.amount === 0) {
            return {
                ...state,
                error : false,
                data  : initialState.data,
            };
        }
        return {
            ...state,
            error : false,
            data  : action.data,
        };

    case ADDRESS_LIST:
        return {
            ...state,
            error : false,
            data  : {
                ...state.data,
                addresses : action.data,
            },
        };
    default:
        return state;
    }
};
