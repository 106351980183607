import React                from "react";
import PropTypes            from "prop-types";

// Styles
import "Styles/Components/Utils/Common/Wrapper.css";



/**
 * The Wrapper
 */
class Wrapper extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, withSpacing, centered, children } = this.props;

        return <section className={
            `wrapper ${className}` +
            (withSpacing ? " wrapper-spacing"  : "") +
            (centered    ? " wrapper-centered" : "")
        }>
            {children}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className   : PropTypes.string,
        withSpacing : PropTypes.bool,
        centered    : PropTypes.bool,
        children    : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        withSpacing : false,
        centered    : false,
    }
}

export default Wrapper;
