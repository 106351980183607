import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchOrderGroup }  from "Actions/Client/OrderActions";
import NLS                  from "Utils/App/NLS";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import ProductImage         from "Components/Product/Item/ProductImage";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableFoot            from "Components/Utils/Table/TableFoot";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";

// Styles
import "Styles/Components/Client/Order.css";



/**
 * The Order Group Page
 */
class OrderGroupPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchOrderGroup(this.props.match.params.group);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data } = this.props;
        const totals = data.totals.filter((elem) => elem.amount);
        const client = data.clients ? data.clients[0] : {};

        return <div>
            <Card>
                <SubTitle message={NLS.format("ORDERS_GROUP_TITLE", data.groupID)} icon="cart" />
                <header className="order-header">
                    <div>
                        <b>{NLS.get("ORDERS_DATE")}</b>
                        {data.groupTimeFull}
                    </div>
                    <div>
                        <b>{NLS.get("ORDERS_STATUS")}</b>
                        {data.statusName}
                    </div>
                </header>
                <main className="order-content spacing">
                    <section className="order-summary">
                        <div>
                            <SubTitle message="ORDERS_SUMMARY" icon="money" />
                            <ul className="no-list">
                                {totals.map((elem) => <li key={elem.symbol}>
                                    <h4>{NLS.format("CART_TOTAL", elem.name)}</h4>
                                    <p>
                                        <span className="order-symbol">{elem.symbol}</span>
                                        {elem.price}
                                    </p>
                                </li>)}
                            </ul>
                        </div>
                        <div>
                            <SubTitle message="ORDERS_SHIPPING" icon="shipping" />
                            <ul className="no-list">
                                <li>
                                    <h4>{NLS.get("ORDERS_SHIPPING_TYPE")}</h4>
                                    <p className="order-address">{NLS.get(data.shippingName)}</p>
                                </li>
                                {data.hasAddress && <li>
                                    <h4>{NLS.get("ORDERS_ADDRESS")}</h4>
                                    <p className="order-address">{`
                                        ${client.addressDesc}:
                                        ${client.address} -
                                        ${client.province} -
                                        ${client.locality} -
                                        ${client.postalCode} -
                                        ${client.schedule}
                                    `}</p>
                                </li>}
                                {data.hasAddress && <li>
                                    <h4>{NLS.get("ORDERS_TRANSPORT")}</h4>
                                    <p className="order-address">{client.transportDescription}</p>
                                </li>}
                            </ul>
                        </div>
                    </section>
                </main>
            </Card>

            {data.products.map(({ list, total }, index) => ((
                <Card key={index} className="order-products">
                    <SubTitle
                        message={NLS.format("ORDERS_PRODUCTS", total.symbol)}
                        icon="cart"
                        withTop
                    />
                    <Table hasContent={!!list.length} className="order-table" none="ORDERS_NONE_AVAILABLE">
                        <TableHead>
                            <TableCell message="ORDERS_PRODUCT" />
                            <TableCell message="" />
                            <TableCell message="ORDERS_AMOUNT"   aling="center" />
                            <TableCell message="ORDERS_PRICE"    align="right" />
                            <TableCell message="ORDERS_SUBTOTAL" align="right" />
                        </TableHead>
                        <TableBody>
                            {list.map((elem) => <TableRow key={`${elem.orderID}-${elem.productCode}`}>
                                <TableCell className="order-table-image" inlineBlock>
                                    <ProductImage className="order-image" data={elem} />
                                </TableCell>
                                <TableCell className="order-description order-product" inlineBlock>
                                    <p>{NLS.format("ORDERS_TITLE", elem.orderID)}</p>
                                    <h4>{elem.productCode}</h4>
                                    <h3>{elem.description}</h3>
                                    {!!elem.campaignID && <p className="order-campaign">
                                        {elem.campaignName}
                                    </p>}
                                </TableCell>
                                <TableCell message={elem.amount} className="order-big" align="center" />
                                <TableCell className="order-big order-subtotal" align="right" nowrap>
                                    <span className="order-symbol">{elem.currencySymbol}</span>
                                    {elem.realPrice}
                                </TableCell>
                                <TableCell className="order-big order-total" align="right" nowrap>
                                    <span className="order-symbol">{elem.currencySymbol}</span>
                                    {elem.subtotal}
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                        <TableFoot>
                            <TableCell />
                            <TableCell message="ORDERS_TOTAL" align="right" />
                            <TableCell message={total.amount} className="order-big" align="center" />
                            <TableCell />
                            <TableCell className="order-big" nowrap>
                                <span className="order-symbol">{total.symbol}</span> {total.price}
                            </TableCell>
                        </TableFoot>
                    </Table>
                </Card>
            )))}

            {!!data.message && <Card className="order-message">
                <SubTitle message="ORDERS_MESSAGE" icon="query" withTop />
                {data.messageLines.map((text, index) => <p key={index}>{text}</p>)}
            </Card>}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOrderGroup : PropTypes.func.isRequired,
        data            : PropTypes.object.isRequired,
        match           : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.order.group,
        };
    }
}

export default connect(OrderGroupPage.mapStateToProps, {
    fetchOrderGroup,
})(OrderGroupPage);
