import NLS                  from "Utils/App/NLS";



/**
 * Returns if the Notifications are Supported
 * @returns {Boolean}
 */
function isSupported() {
    return ("Notification" in window) && !window.cordova;
}

/**
 * Returns if the Notifications can be Granted
 * @returns {Boolean}
 */
function canGrant() {
    return isSupported() && Notification.permission !== "denied" && Notification.permission !== "granted";
}

/**
 * Returns if the Notifications are Granted
 * @returns {Boolean}
 */
function isGranted() {
    return isSupported() && Notification.permission === "granted";
}

/**
 * Returns if the Notifications are Denied
 * @returns {Boolean}
 */
function isDenied() {
    return isSupported() && Notification.permission === "denied";
}

/**
 * Request the Notifications Permissions
 * @param {String} message
 * @returns {Promise}
 */
function requestPermission(message) {
    return new Promise((resolve) => {
        Notification.requestPermission((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                const notification = new Notification(message, {
                    icon : process.env.REACT_APP_ICON,
                });
                notification.onclick = () => {
                    window.focus();
                    notification.close();
                };
            }
            resolve(permission === "granted");
        });
    });
}

/**
 * Shows the Notification
 * @param {Stirng}   title
 * @param {Stirng}   body
 * @param {Function} onClick
 * @returns {Void}
 */
function show(title, body, onClick) {
    const notification = new Notification(title, {
        icon : process.env.REACT_APP_ICON,
        body : body,
    });
    notification.onclick = () => {
        window.focus();
        onClick();
        notification.close();
    };
}



/**
 * Returns the Notification Data
 * @param {Object} data
 * @returns {Object}
 */
function getData(data) {
    switch (data.type) {
    case "lightning":
    case "promotion":
        return {
            title : NLS.get("NOTIFICATIONS_TITLE_OFFER"),
            body  : data.body,
        };
    case "news":
        return {
            title : data.title,
            body  : data.body,
        };
    default:
        return {
            title : NLS.get(`NOTIFICATIONS_TITLE_${data.type.toUpperCase()}`),
            body  : NLS.format(`NOTIFICATIONS_TEXT_${data.type.toUpperCase()}`, ...data.body),
        };
    }
}



// The Public API
export default {
    isSupported,
    canGrant,
    isGranted,
    isDenied,
    requestPermission,
    show,
    getData,
};
