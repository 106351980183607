import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { fetchHome }        from "Actions/Store/StoreActions";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import HomeProducts         from "./HomeProducts";
import ProductBanner        from "Components/Product/Item/ProductBanner";
import ProductDialog        from "Components/Product/Item/ProductDialog";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Slider               from "Components/Utils/Common/Slider";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Store/Home.css";



/**
 * The Home Page
 */
class HomePage extends React.Component {
    // The Current State
    state = {
        showDialog : false,
        product    : {},
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchHome();
    }

    /**
     * Goes to the Url
     * @param {Object} elem
     * @returns {Function}
     */
    handleClick = (elem) => () => {
        if (Utils.hrefInApp(elem.url)) {
            this.props.history.push(Utils.getUrl(elem.url));
        } else if (elem.url) {
            window.location.href = elem.url;
        }
    }



    /**
     * Opens the Product Dialog
     * @param {Object} product
     * @returns {Void}
     */
    openProduct = (product) => {
        this.setState({ showDialog : true, product });
    }

    /**
     * Closes the Product Dialog
     * @returns {Void}
     */
    closeProduct = () => {
        this.setState({ showDialog : false, product : {} });
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isMobile, data                                        } = this.props;
        const { slides, banner, newests, favorites, history, category } = data;
        const { showDialog, product                                   } = this.state;

        const showSlider     = Boolean(slides.length);
        const showBanner     = Boolean(banner);
        const showNewests    = Boolean(newests.length   > 0);
        const showFavorites  = Boolean(favorites.length > 0);
        const showHistory    = Boolean(history.length   > 2);
        const showCategories = Boolean(category.main.length);
        const showOthers     = Boolean(category.others.length);

        for (const slide of slides) {
            slide.image = isMobile ? slide.mobileImage : slide.desktopImage;
        }

        return <main>
            {showSlider && <Slider
                className="home-slider"
                data={slides}
                onClick={this.handleClick}
                autoSlide
                withDots
            />}
            <Wrapper>
                {showBanner && <ProductBanner className="home-banner" data={banner} />}

                {showNewests && <HomeProducts
                    variant="newests"
                    message="NEWESTS_TITLE"
                    href="/nuevos"
                    list={newests}
                    onClick={this.openProduct}
                />}
                {showFavorites && <HomeProducts
                    variant="favorites"
                    message="FAVORITES_TITLE"
                    href="/favoritos"
                    list={favorites}
                    onClick={this.openProduct}
                />}
                {showCategories && <div className="home-categories">
                    {category.main.map((elem) => <HomeProducts
                        key={elem.id}
                        variant="category"
                        message={elem.name}
                        href={`/categorias/${elem.url}`}
                        list={elem.products}
                        onClick={this.openProduct}
                    />)}
                </div>}
                {showHistory && <HomeProducts
                    variant="history"
                    message="HISTORY_TITLE"
                    href="/historial"
                    list={history}
                    onClick={this.openProduct}
                />}

                {showOthers && <div className="home-others">
                    <header className="home-header">
                        <h2>{NLS.get("CATEGORIES_OTHERS")}</h2>
                        <Button
                            variant="primary"
                            href="/categorias"
                            message="HEADER_MORE"
                        />
                    </header>
                    <Card>
                        {category.others.map((elem) => <HyperLink
                            key={elem.id}
                            variant="none"
                            href={`/categorias/${elem.url}`}
                            message={elem.name}
                            style={{ backgroundImage : `url(${elem.image})` }}
                        />)}
                    </Card>
                </div>}
            </Wrapper>

            <ProductDialog
                open={showDialog}
                data={product}
                onClose={this.closeProduct}
            />
        </main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history   : PropTypes.object.isRequired,
        fetchHome : PropTypes.func.isRequired,
        isMobile  : PropTypes.bool.isRequired,
        data      : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMobile : state.core.isMobile,
            data     : state.store.home,
        };
    }
}

export default withRouter(connect(HomePage.mapStateToProps, {
    fetchHome,
})(HomePage));
