import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";

// Components
import Benefits             from "./BenefitsItem";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import Card                 from "Components/Utils/Common/Card";

// Styles
import "Styles/Components/Content/Benefits.css";



/**
 * The Benefits Page
 */
class BenefitsPage extends React.Component {
    // The Current State
    state = {
        message : "BENEFITS_SELECT",
        isSet   : false,
    }
    
    /**
     * Sets the Message
     * @param {String} message
     * @returns {Function}
     */
    handleClick = (message) => () => {
        this.setState({ message, isSet : !this.state.isSet });
    }

    /**
     * Sets the Message
     * @param {String} message
     * @returns {Function}
     */
    handleEnter = (message) => () => {
        if (!this.state.isSet) {
            this.setState({ message });
        }
    }

    /**
     * Unsets the Message
     * @param {String} message
     * @returns {Function}
     */
    handleLeave = () => {
        if (!this.state.isSet) {
            this.setState({ message : "BENEFITS_SELECT" });
        }
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isApp   } = this.props;
        const { message } = this.state;

        return <Wrapper centered={!isApp} withSpacing={isApp}>
            {isApp ? <Title message="BENEFITS_NAME" href="/cuenta" /> : ""}
            <Card className="benefits-container">
                <div>
                    {!isApp ? <h2>{NLS.get("BENEFITS_NAME")}</h2> : ""}
                    <p>{NLS.get(message)}</p>
                </div>
                <Benefits
                    onClick={this.handleClick}
                    onEnter={this.handleEnter}
                    onLeave={this.handleLeave}
                />
            </Card>
        </Wrapper>;
    }




    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isApp : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp : state.core.isApp,
        };
    }
}

export default connect(BenefitsPage.mapStateToProps)(BenefitsPage);
