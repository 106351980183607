import React                from "react";
import NLS                  from "Utils/App/NLS";

// Components
import Card                 from "Components/Utils/Common/Card";



/**
 * The Terms Content
 */
class TermsContent extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <Card className="terms-content" withSpacing>
            <p>{NLS.get("TERMS_LINE1")}</p>
            <p>{NLS.get("TERMS_LINE2")}</p>
            <ol>
                <li>{NLS.get("TERMS_LIST1")}</li>
                <li>{NLS.get("TERMS_LIST2")}</li>
                <li>{NLS.get("TERMS_LIST3")}</li>
                <li>{NLS.get("TERMS_LIST4")}</li>
                <li>{NLS.get("TERMS_LIST5")}</li>
                <li>{NLS.get("TERMS_LIST6")}</li>
                <li>{NLS.get("TERMS_LIST7")}</li>
                <li>{NLS.get("TERMS_LIST8")}</li>
                <li>{NLS.get("TERMS_LIST9")}</li>
                <li>
                    {NLS.get("TERMS_LIST10")}
                    <ol>
                        <li>{NLS.get("TERMS_LIST101")}</li>
                        <li>{NLS.get("TERMS_LIST102")}</li>
                        <li>{NLS.get("TERMS_LIST103")}</li>
                        <li>{NLS.get("TERMS_LIST104")}</li>
                        <li>{NLS.get("TERMS_LIST105")}</li>
                        <li>{NLS.get("TERMS_LIST106")}</li>
                        <li>{NLS.get("TERMS_LIST107")}</li>
                        <li>{NLS.get("TERMS_LIST108")}</li>
                    </ol>
                </li>
                <li>{NLS.get("TERMS_LIST11")}</li>
                <li>{NLS.get("TERMS_LIST12")}</li>
                <li>{NLS.get("TERMS_LIST13")}</li>
                <li>{NLS.get("TERMS_LIST14")}</li>
                <li>
                    {NLS.get("TERMS_LIST15")}
                    <ol>
                        <li>
                            {NLS.get("TERMS_LIST151")}
                            <ul>
                                <li>{NLS.get("TERMS_LIST1511")}</li>
                                <li>{NLS.get("TERMS_LIST1512")}</li>
                                <li>{NLS.get("TERMS_LIST1513")}</li>
                                <li>{NLS.get("TERMS_LIST1514")}</li>
                                <li>{NLS.get("TERMS_LIST1515")}</li>
                                <li>{NLS.get("TERMS_LIST1516")}</li>
                                <li>{NLS.get("TERMS_LIST1517")}</li>
                                <li>{NLS.get("TERMS_LIST1518")}</li>
                                <li>{NLS.get("TERMS_LIST1519")}</li>
                            </ul>
                        </li>
                        <li>
                            {NLS.get("TERMS_LIST152")}
                            <ul>
                                <li>{NLS.get("TERMS_LIST1521")}</li>
                                <li>{NLS.get("TERMS_LIST1522")}</li>
                                <li>{NLS.get("TERMS_LIST1523")}</li>
                            </ul>
                            {NLS.get("TERMS_LIST1524")}<br />
                            {NLS.get("TERMS_LIST1525")}<br />
                            {NLS.get("TERMS_LIST1516")}<br />
                            {NLS.get("TERMS_LIST1517")}<br />
                            {NLS.get("TERMS_LIST1518")}
                        </li>
                        <li>{NLS.get("TERMS_LIST153")}</li>
                        <li>{NLS.get("TERMS_LIST154")}</li>
                        <li>{NLS.get("TERMS_LIST155")}</li>
                        <li>{NLS.get("TERMS_LIST156")}</li>
                    </ol>
                </li>
                <li>{NLS.get("TERMS_LIST16")}</li>
                <li>{NLS.get("TERMS_LIST17")}</li>
            </ol>
        </Card>;
    }
}

export default TermsContent;
