import React                from "react";
import NLS                  from "Utils/App/NLS";

// Components
import TermsContent         from "./TermsContent";
import Title                from "Components/Utils/Common/Title";

// Images
import LogoImage            from "Styles/Images/LogoBlack.png";

// Styles
import "Styles/Components/Terms/Terms.css";



/**
 * The Terms Guest
 */
class TermsGuest extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <div className="terms-container">
            <h1><img src={LogoImage} alt={NLS.get("GENERAL_NAME")} /></h1>
            <div className="terms-wrapper">
                <Title message="TERMS_NAME" href="/ingresar" />
                <TermsContent />
            </div>
        </div>;
    }
}

export default TermsGuest;
