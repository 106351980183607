import React                from "react";
import PropTypes            from "prop-types";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Product/List/Breadcrumb.css";



/**
 * The Breadcrumb
 */
class Breadcrumb extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <ul className="no-list breadcrumb">
            {this.props.data.map((elem) => <React.Fragment key={elem.to}>
                <li>
                    <HyperLink variant="gray" href={elem.to} message={elem.name} />
                </li>
                <li className="breadcrumb-chevron">
                    <Icon variant="right" />
                </li>
            </React.Fragment>)}
        </ul>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data : PropTypes.array.isRequired,
    }
}

export default Breadcrumb;
