import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "Utils/Common/Utils";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";
import Alert                from "Components/Utils/Form/Alert";

// Actions
import {
    fetchAccount, editAccount,
} from "Actions/Client/ClientActions";



/**
 * The Account Page
 */
class AccountPage extends React.Component {
    // The Initial Data
    static initialData = {
        socialReason : "",
        fantasyName  : "",
        email        : "",
        phone        : "",
        cellphone    : "",
        CUIT         : "",
        address      : "",
        province     : "",
        locality     : "",
    }

    // The Current State
    state = {
        data    : { ...AccountPage.initialData },
        loaded  : false,
        loading : false,
        success : "",
        errors  : {},
        error   : "",
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchAccount();
    }

    /**
     * Updates the State when
     * @returns {Void}
     */
    componentDidUpdate() {
        let data = null;
        if (!Utils.isEmpty(this.props.data) && this.state.loaded === false) {
            data = Utils.extend(AccountPage.initialData, this.props.data);
        }
        if (data) {
            this.setState({ data, loading : false, errors : {}, loaded : true });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, success : "", errors : {}, error : "" });
        try {
            const success = await this.props.editAccount(data);
            this.setState({ loading : false, success });
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isTablet, isPotential                 } = this.props;
        const { data, loading, success, errors, error } = this.state;

        return <Card className={"client-account" + (isPotential ? " client-card" : "")}>
            <SubTitle show={!isTablet} message="ACCOUNT_NAME" icon="account" />
            <section className="spacing">
                <Alert variant="success" message={success} onClose={this.closeAlert} />
                <Alert variant="error"   message={error}   onClose={this.closeAlert} />

                <form className="client-form client-fields" onSubmit={this.handleSubmit}>
                    <TextField
                        isHidden={isPotential}
                        name="socialReason"
                        label="ACCOUNT_SOCIAL_REASON"
                        value={data.socialReason}
                        error={errors.socialReason}
                        onChange={this.handleChange}
                        isDisabled
                    />
                    <TextField
                        isHidden={isPotential}
                        name="CUIT"
                        label="ACCOUNT_CUIT"
                        value={data.CUIT}
                        error={errors.CUIT}
                        onChange={this.handleChange}
                        isDisabled
                    />
                    <TextField
                        name="fantasyName"
                        label="ACCOUNT_FANTASY_NAME"
                        value={data.fantasyName}
                        error={errors.fantasyName}
                        onChange={this.handleChange}
                        isRequired
                    />

                    <TextField
                        type="email"
                        name="email"
                        label="ACCOUNT_EMAIL"
                        value={data.email}
                        error={errors.email}
                        onChange={this.handleChange}
                        isRequired
                    />
                    <TextField
                        type="tel"
                        name="phone"
                        label="ACCOUNT_PHONE"
                        value={data.phone}
                        error={errors.phone}
                        onChange={this.handleChange}
                        isRequired
                    />
                    <TextField
                        type="tel"
                        name="cellphone"
                        label="ACCOUNT_CELLPHONE"
                        value={data.cellphone}
                        error={errors.cellphone}
                        onChange={this.handleChange}
                        isRequired
                    />

                    <TextField
                        isHidden={isPotential}
                        name="address"
                        label="ACCOUNT_ADDRESS"
                        value={data.address}
                        error={errors.address}
                        onChange={this.handleChange}
                        isDisabled
                    />
                    <TextField
                        isHidden={isPotential}
                        name="province"
                        label="ACCOUNT_PROVINCE"
                        value={data.province}
                        error={errors.province}
                        onChange={this.handleChange}
                        isDisabled
                    />
                    <TextField
                        isHidden={isPotential}
                        name="locality"
                        label="ACCOUNT_LOCALITY"
                        value={data.locality}
                        error={errors.locality}
                        onChange={this.handleChange}
                        isDisabled
                    />

                    <div className="client-btn">
                        <Button
                            variant="primary"
                            message="ACCOUNT_SUBMIT"
                            isDisabled={loading}
                            fullWidth
                        />
                    </div>
                </form>
            </section>
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAccount : PropTypes.func.isRequired,
        editAccount  : PropTypes.func.isRequired,
        isPotential  : PropTypes.bool.isRequired,
        isTablet     : PropTypes.bool.isRequired,
        data         : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isPotential : state.auth.isPotential,
            isTablet    : state.core.isTablet,
            data        : state.client.account,
        };
    }
}

export default connect(AccountPage.mapStateToProps, {
    fetchAccount, editAccount,
})(AccountPage);
