import Utils                from "Utils/Common/Utils";

// Types
import {
    PRODUCT_SEARCH, PRODUCT_FAVORITES, PRODUCT_HISTORY, PRODUCT_NEWESTS,
    STORE_FAVORITE,
} from "Utils/Types";



// The product State
const productState = {
    name          : "GENERAL_LOADING",
    url           : "",
    categories    : [],
    subcategories : [],
    products      : [],
    results       : 0,
    total         : 0,
    pages         : 1,
};

// The initial State
const initialState = {
    error     : false,
    search    : { ...productState },
    newests   : { ...productState },
    favorites : { ...productState },
    history   : { ...productState },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case PRODUCT_SEARCH:
        return {
            ...state,
            error    : false,
            search   : action.data,
        };
    case PRODUCT_NEWESTS:
        return {
            ...state,
            error    : false,
            newests  : action.data,
        };

    case PRODUCT_FAVORITES: {
        if (action.data.products.length) {
            return {
                ...state,
                error     : false,
                favorites : action.data,
            };
        }
        return {
            ...state,
            error     : false,
            favorites : { ...initialState.favorites },
        };
    }

    case PRODUCT_HISTORY: {
        if (action.data.products.length) {
            return {
                ...state,
                error    : false,
                history  : action.data,
            };
        }
        return {
            ...state,
            error    : false,
            history  : { ...initialState.history },
        };
    }

    // Update the Favorite
    case STORE_FAVORITE:
        return {
            ...state,
            error     : false,
            search    : Utils.setFavorite(state.search,    action.data.productCode),
            favorites : Utils.setFavorite(state.favorites, action.data.productCode),
            history   : Utils.setFavorite(state.history,   action.data.productCode),
        };

    default:
        return state;
    }
};
