import React                from "react";
import NLS                  from "Utils/App/NLS";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Icon                 from "Components/Utils/Common/Icon";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Content/Error.css";



/**
 * The Error Page
 */
class ErrorPage extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        return <Wrapper className="error" withSpacing>
            <Icon variant="warning" />
            <h2>{NLS.get("ERROR_MESSAGE")}</h2>
            <p><Button variant="primary" message="ERROR_LINK" href="/" /></p>
        </Wrapper>;
    }
}

export default ErrorPage;
