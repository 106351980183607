import { combineReducers }      from "redux";

import CoreReducer              from "./Core/CoreReducer";
import AuthReducer              from "./Core/AuthReducer";

import StoreReducer             from "./Store/StoreReducer";
import CategoryReducer          from "./Store/CategoryReducer";
import OfferReducer             from "./Store/OfferReducer";
import BrandReducer             from "./Store/BrandReducer";
import BikeReducer              from "./Store/BikeReducer";
import ProductReducer           from "./Store/ProductReducer";
import CartReducer              from "./Store/CartReducer";

import NewsReducer              from "./Content/NewsReducer";
import NotificationReducer      from "./Content/NotificationReducer";

import ClientReducer            from "./Client/ClientReducer";
import AddressReducer           from "./Client/AddressReducer";
import VoucherReducer           from "./Client/VoucherReducer";
import QueryReducer             from "./Client/QueryReducer";
import OrderReducer             from "./Client/OrderReducer";



// The Combined Reducers
export default combineReducers({
    core         : CoreReducer,
    auth         : AuthReducer,

    store        : StoreReducer,
    category     : CategoryReducer,
    offer        : OfferReducer,
    brand        : BrandReducer,
    bike         : BikeReducer,
    product      : ProductReducer,
    cart         : CartReducer,

    news         : NewsReducer,
    notification : NotificationReducer,

    client       : ClientReducer,
    address      : AddressReducer,
    voucher      : VoucherReducer,
    query        : QueryReducer,
    order        : OrderReducer,
});
