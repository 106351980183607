import API                  from "Utils/App/API";

// Types
import {
    QUERY_LIST, QUERY_DATA, QUERY_CREATE, QUERY_CREATED,
} from "Utils/Types";



/**
 * Fetches the Query List
 * @param {Object} params
 * @returns {Function}
 */
export function fetchQueries(params) {
    return async (dispatch) => {
        const data = await API.Query.getQueries(params);
        dispatch({ type : QUERY_LIST, data });
    };
}

/**
 * Fetches the Query Data
 * @param {Object}   queryID
 * @param {Boolean=} loader
 * @returns {Function}
 */
export function fetchQuery(queryID, loader = true) {
    return async (dispatch) => {
        const data = await API.Query.getQuery({ queryID }, loader);
        dispatch({ type : QUERY_DATA, data });
    };
}

/**
 * Fetches the Query Create
 * @param {Object} queryID
 * @returns {Function}
 */
export function fetchQueryCreate() {
    return async (dispatch) => {
        const data = await API.Query.getQueryCreate();
        dispatch({ type : QUERY_CREATE, data });
    };
}



/**
 * Creates a Query
 * @param {Object} params
 * @returns {Function}
 */
export function createQuery(params) {
    return async (dispatch) => {
        const data = await API.Query.createQuery(params);
        dispatch({ type : QUERY_CREATED, data });
        return data.queryID;
    };
}

/**
 * Replies to a Query
 * @param {Object} params
 * @returns {Function}
 */
export function replyQuery(params) {
    return async (dispatch) => {
        const data = await API.Query.replyQuery(params);
        dispatch({ type : QUERY_DATA, data });
        return data.success;
    };
}
