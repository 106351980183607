import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchNew }         from "Actions/Content/NewsActions";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Content/News.css";



/**
 * The News Page
 */
class NewsPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchNew(this.props.match.params.news);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data } = this.props;

        return <Wrapper withSpacing>
            <Title message={data.title} href="/novedades" />
            <Card className="news-container">
                <section key={data.newsID}>
                    <SubTitle message={data.createdTimeFull} />
                    <div className="news-content spacing">
                        <div className="news-image">
                            {data.link ? <HyperLink variant="none" href={data.link}>
                                <img src={data.imageUrl} alt={data.title} />
                            </HyperLink> : <img src={data.imageUrl} alt={data.title} />}
                        </div>
                        {data.descriptionLines && data.descriptionLines.length > 0 ?
                            <div className="news-description">
                                {data.descriptionLines.map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))}
                            </div> : ""}
                        {!!data.link && <Button
                            variant="primary"
                            message="NEWS_VIEW"
                            href={data.link}
                        />}
                    </div>
                </section>
            </Card>
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchNew : PropTypes.func.isRequired,
        data     : PropTypes.object.isRequired,
        match    : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.news.new,
        };
    }
}

export default connect(NewsPage.mapStateToProps, {
    fetchNew,
})(NewsPage);
