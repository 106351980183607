import API                  from "Utils/App/API";

// Types
import {
    BIKE_CYLINDERS, BIKE_CYLINDER, BIKE_DATA,
} from "Utils/Types";



/**
 * Fetches the Cylinder List
 * @returns {Function}
 */
export function fetchCylinders() {
    return async (dispatch) => {
        const data = await API.Bike.getCylinders();
        dispatch({ type : BIKE_CYLINDERS, data });
    };
}

/**
 * Fetches the Cylinder Data
 * @param {Object} params
 * @returns {Function}
 */
export function fetchCylinder(params) {
    return async (dispatch) => {
        const data = await API.Bike.getCylinder(params);
        dispatch({ type : BIKE_CYLINDER, data });
    };
}

/**
 * Fetches the Bike Data
 * @param {Object} params
 * @returns {Function}
 */
export function fetchBike(params) {
    return async (dispatch) => {
        const data = await API.Bike.getBike(params);
        dispatch({ type : BIKE_DATA, data });
    };
}
