export default {

    DAYS            : [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ],
    DAYS_SHORT      : [ "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá" ],
    MONTHS          : [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ],
    LANGUAGES       : { "es" : "Español", "en" : "English" },


    // General Names
    GENERAL_NAME                    : "Tienda Tercom",
    GENERAL_LOADING                 : "Cargando ...",
    GENERAL_PREV                    : "Anterior",
    GENERAL_NEXT                    : "Siguiente",
    GENERAL_BACK                    : "Volver",
    GENERAL_NO                      : "No",
    GENERAL_YES                     : "Si",
    GENERAL_CREATE                  : "Crear",
    GENERAL_EDIT                    : "Editar",
    GENERAL_SAVE                    : "Guardar",
    GENERAL_DAYS                    : "Días",
    GENERAL_DELETE                  : "Eliminar",
    GENERAL_CANCEL                  : "Cancelar",
    GENERAL_HOME                    : "Inicio",
    GENERAL_HOURS                   : "Horas",
    GENERAL_MINUTES                 : "Minutos",
    GENERAL_MINUTES_SHORT           : "Min",
    GENERAL_SECONDS                 : "Segundos",
    GENERAL_SECONDS_SHORT           : "Seg",
    GENERAL_SELECT_FILE             : "Seleccionar archivo",
    GENERAL_OPEN_WHATSAPP           : "Abrir WhatsApp",
    GENERAL_OPEN_VOUCHER            : "Descargar Comprobante",
    GENERAL_ERROR                   : "Ocurrió un error inesperado. Estamos trabajando en resolverlo.",


    // Auth
    AUTH_NAME                       : "Ingresar",
    AUTH_IDENTIFIER                 : "Código de cliente / CUIT",
    AUTH_PASSWORD                   : "Contraseña",
    AUTH_RECOVER_USER               : "Obtener clave o usuario",
    AUTH_RECOVER_TEXT               : "Ingresa tu número de cliente, CUIT o email, y te enviaremos tu clave.",
    AUTH_RECOVER_BTN                : "Recuperar",
    AUTH_TERMS1                     : "Operar con Tienda Tercom implica aceptar los ",
    AUTH_TERMS2                     : "términos y condiciones",
    AUTH_TERMS3                     : " en los que se ofrece el servicio.",
    AUTH_INFO_MENU                  : "Un menu ordenado por productos para que encuentres lo que estas buscando.",
    AUTH_INFO_ACCOUNT               : "Conocé la sección \"Mi Cuenta\" que tiene tus datos personales y tus comprobantes.",
    AUTH_ERROR_IDENTIFIER           : "Por favor, complete su código de cliente, CUIT o email.",
    AUTH_ERROR_PASSWORD             : "Por favor, complete la contraseña.",
    AUTH_ERROR_SPAM                 : "Ha intentado demasiadas veces.",
    AUTH_ERROR_CREDENTIALS          : "El código o la contraseña es incorrecta.",
    AUTH_ERROR_RECOVER              : "No fue posible encontrar un cliente con los datos provistos.",
    AUTH_SUCCESS_LOGIN              : "Bienvenido a la Tienda Tercom",
    AUTH_SUCCESS_RECOVER            : "Se le enviará un mail con su contraseña, en las próximas horas.",


    // Header
    HEADER_PHONE                    : "Venta telefónica",
    HEADER_PHONE_NUMBER             : "Venta telefónica: {0}",
    HEADER_WHATSAPP_NUMBER          : "Venta por WhatsApp: {0}",
    HEADER_SEARCH                   : "Buscar producto",
    HEADER_DOLAR                    : "U$S {0}",
    HEADER_DOLAR_PRICE              : "Precio del dólar: U$S {0}",
    HEADER_MORE                     : "Ver más",
    HEADER_WELCOME                  : "¡Hola {0}!",
    HEADER_NOTIFICATIONS_SINGULAR   : "{0} notificación",
    HEADER_NOTIFICATIONS_PLURAL     : "{0} notificaciones",

    // Footer
    FOOTER_ABOUT_US                 : "Sobre Nosotros",
    FOOTER_TERMS                    : "Términos y condiciones",
    FOOTER_CONTACT                  : "Contacto",
    FOOTER_STAY_CONNECTED           : "Seguí conectado",
    FOOTER_DOWNLOAD_APP             : "Descarga la App Oficial",
    FOOTER_MAGAZINE                 : "Revista: Exclusivo Motos",
    FOOTER_SITE_MAP                 : "Mapa del Sitio",
    FOOTER_ACCOUNT                  : "Mi cuenta",
    FOOTER_COPYRIGHT                : "Copyright © {0}-{1} Tercom S.R.L. Todos los derechos reservados. Accesibilidad, Condiciones de uso, Aviso de privacidad, cookies y AdChoice.",
    FOOTER_FISCAL                   : "Data Fiscal",



    // Categories
    CATEGORIES_NAME                 : "Categorías",
    CATEGORIES_TITLE                : "Listado de Categorías",
    CATEGORIES_ALL                  : "Todas",
    CATEGORIES_OTHERS               : "Otras categorías",

    // Subcategories
    SUBCATEGORIES_NAME              : "Subcategorías",
    SUBCATEGORIES_TITLE             : "Listado de Subcategorías",

    // Offers
    OFFERS_NAME                     : "Ofertas",
    OFFERS_TITLE                    : "Nuestras Ofertas",
    OFFERS_FILTER                   : "Precios",
    OFFERS_NONE_AVAILABLE           : "No hay ofertas disponibles",
    OFFERS_BUY_MORE                 : "Mira nuestro listado de productos",

    // Bikes
    BIKES_NAME                      : "Motos",
    BIKES_TITLE                     : "Cilindradas",
    BIKES_CYLINDER                  : "Cilindrada {0}",
    BIKES_SELECT                    : "¡Selecciona la cilindrada que buscas!",
    BIKES_SPARES                    : "Repuestos Para",
    BIKES_ALL                       : "Todas",

    // Brands
    BRANDS_NAME                     : "Marcas",
    BRANDS_TITLE                    : "Nuestras Marcas",

    // Search
    SEARCH_RESULTS                  : "Resultados",
    SEARCH_TITLE                    : "Búsqueda \"{0}\"",

    // Newsts
    NEWESTS_NAME                    : "Nuevos",
    NEWESTS_TITLE                   : "Productos nuevos",
    NEWESTS_NONE_AVAILABLE          : "No hay productos nuevos disponibles",

    // Favorites
    FAVORITES_NAME                  : "Favoritos",
    FAVORITES_TITLE                 : "Mis Favoritos",
    FAVORITES_SHOW_ALL              : "Mostrar todos",
    FAVORITES_NONE_AVAILABLE        : "No hay favoritos disponibles",
    FAVORITE_ERROR_PRODUCT          : "El producto indicado no existe.",
    FAVORITE_SUCCESS_ADD            : "El producto se agregó correctamente.",
    FAVORITE_SUCCESS_REMOVE         : "El producto se quitó correctamente.",

    // History
    HISTORY_NAME                    : "Historial",
    HISTORY_TITLE                   : "Mi Historial",
    HISTORY_NONE_AVAILABLE          : "No hay productos disponibles",
    HISTORY_ERROR_PRODUCT           : "El producto indicado no existe.",
    HISTORY_SUCCESS_ADD             : "El producto se agregó correctamente.",



    // Products
    PRODUCTS_RESULTS_SINGULAR       : "1 resultado",
    PRODUCTS_RESULTS_PLURAL         : "{0} resultados",
    PRODUCTS_FILTER                 : "Filtrar",
    PRODUCTS_FILTER_TITLE           : "Filtrar por",
    PRODUCTS_SORT                   : "Ordenar resultados",
    PRODUCTS_SORT_NAME_AZ           : "Nombre A-Z",
    PRODUCTS_SORT_NAME_ZA           : "Nombre Z-A",
    PRODUCTS_SORT_CODE_AZ           : "Código A-Z",
    PRODUCTS_SORT_CODE_ZA           : "Código Z-A",
    PRODUCTS_SORT_LOWER_PRICE       : "Menor precio",
    PRODUCTS_SORT_GREATER_PRICE     : "Mayor precio",
    PRODUCTS_SORT_NEWER_PRODUCT     : "Nuevo a viejo",
    PRODUCTS_SORT_OLDER_PRODUCT     : "Viejo a nuevo",
    PRODUCTS_TYPE                   : "Tipo de producto",
    PRODUCTS_TYPE_ALL               : "Todos",
    PRODUCTS_TYPE_NATIONAL          : "Nacional",
    PRODUCTS_TYPE_IMPORTED          : "Importado",
    PRODUCTS_PRICE                  : "Precio",
    PRODUCTS_PRICE_ALL              : "Todos",
    PRODUCTS_PRICE_TO               : "Hasta {0}",
    PRODUCTS_PRICE_RANGE            : "De {0} a {1}",
    PRODUCTS_PRICE_FROM             : "Desde {0}",
    PRODUCTS_IN_OFFER               : "VER OFERTA",
    PRODUCTS_IS_NEW                 : "NUEVO",
    PRODUCTS_DISCOUNT               : "{0}% OFF",
    PRODUCTS_ADD_AMOUNT             : "Unidades",
    PRODUCTS_ADD_TO_CART            : "Agregar al carrito",
    PRODUCTS_SHOW_TO_CART           : "Ver carrito de compras",
    PRODUCTS_BUY_MORE               : "Seguir comprando",

    // Banner
    BANNER_ONLY                     : "Solo X",
    BANNER_DAYS_SINGULAR            : "{0} Día",
    BANNER_DAYS_PLURAL              : "{0} Días",
    BANNER_HOURS_SINGULAR           : "{0} Hora",
    BANNER_HOURS_PLURAL             : "{0} Horas",
    BANNER_BEFORE                   : "Antes",
    BANNER_SHOW                     : "Ver Oferta",



    // Cart
    CART_TITLE                      : "Carrito de compras",
    CART_TITLE_FULL                 : "Carrito de compras ({0})",
    CART_PRODUCTS_SINGULAR          : "{0} producto",
    CART_PRODUCTS_PLURAL            : "{0} productos",
    CART_PURCHASES                  : "Compras en: {0}",
    CART_AMOUNT                     : "Cantidad",
    CART_DELETE                     : "Eliminar",
    CART_PRICE                      : "Precio unitario",
    CART_SUBTOTAL                   : "Sub-total",
    CART_DISCOUNT_UNITS             : "Obtienes un descuento por comprar {0} o más unidades",
    CART_DISCOUNT_TOTAL             : "Obtienes un descuento por comprar por un total de {0} o más",
    CART_DISCOUNT_NEXT_UNITS        : "Puedes obtener un descuento por comprar {0} o más unidades",
    CART_DISCOUNT_NEXT_TOTAL        : "Puedes obtener un descuento por comprar por un total de {0} o más",
    CART_STOCK                      : "Solo quedan {0} unidades en promoción. El resto tienen el precio de lista",
    CART_SUMMARY                    : "Resumen de la orden",
    CART_TOTAL                      : "Total en {0}",
    CART_CONTINUE                   : "Continuar compra",
    CART_SHIPPING_TYPE              : "¿Qué tipo de envío preferís?",
    CART_SHIPPING_TYPE_1            : "Retiro en Tercom",
    CART_SHIPPING_TYPE_2            : "Reparto Gratuito",
    CART_SHIPPING_TYPE_3            : "Envío por Transporte",
    CART_SHIPPING_SELECTED          : "Tipo de envio",
    CART_SHIPPING_MAP               : "Mapa",
    CART_ADDRESS                    : "¿Dónde queres recibirlo?",
    CART_ADDRESS_OTHER              : "Enviar a otra dirección",
    CART_SELECT_ADDRESS             : "Seleccionar dirección de envío",
    CART_SELECT_ONE_ADDRESS         : "Seleccione una dirección de envío",
    CART_SELECT_NONE_ADDRESS        : "No hay direcciones disponibles. Si las editó o creó recientemente debe esperar a que sean confirmadas por nuestros vendedores.",
    CART_TRANSPORT                  : "¿Qué transporte preferís?",
    CART_MESSAGE                    : "Podes dejarnos un mensaje",
    CART_WRITE_MESSAGE              : "Dejanos un mensaje",
    CART_CONFIRM                    : "Confirmar compra",
    CART_EDIT                       : "Modificar compra",
    CART_NONE_AVAILABLE             : "No hay productos en el carrito",
    CART_ADD_MORE                   : "Agrega más productos",
    CART_BUY_MORE                   : "Agrega productos ahora",
    CART_LEGALS_1                   : "Los precios pueden ser modificados sin previo aviso.",
    CART_LEGALS_2                   : "Los productos se encuentran sujetos a disponibilidad de stock según demanda.",
    CART_LEGALS_3                   : "Las imágenes son meramente ilustrativas.",
    CART_LEGALS_4                   : "Aplicar un descuento del 19%, adicionar el IVA (21%) y realizar la conversion a pesos aplicando la cotizacion del dolar informada por nuestra empresa.",
    CART_COMPLETED_CONTACT          : "Será contactado en la brevedad por alguno de nuestros representantes.",
    CART_COMPLETED_NEXT             : "Ahora puedes:",
    CART_COMPLETED_ORDER            : "Ver mi pedido",
    CART_COMPLETED_MORE             : "Seguir comprando",
    CART_ERROR_POTENTIAL            : "Sólo puede crear un pedido hasta no ser un cliente activo.",
    CART_ERROR_EXISTS               : "No hay un carrito creado.",
    CART_ERROR_EMPTY                : "No hay productos en el carrito creado.",
    CART_ERROR_PRODUCT_EMPTY        : "Por favor, complete el producto.",
    CART_ERROR_PRODUCT_EXISTS       : "El producto indicado no existe.",
    CART_ERROR_AMOUNT               : "Por favor, indique la cantidad como un número mayor a cero.",
    CART_ERROR_SHIPPING             : "Por favor, indique el tipo de envío.",
    CART_ERROR_ADDRESS              : "Por favor, indique una dirección de envío.",
    CART_ERROR_TRANSPORT            : "Por favor, indique un transporte.",
    CART_SUCCESS_ADD                : "¡Se agrego a tu carrito de compras!",
    CART_SUCCESS_EDIT               : "Se modificó la cantidad del producto.",
    CART_SUCCESS_REMOVE             : "Se quitó el producto del carrito.",
    CART_SUCCESS_CONFIRM            : "Se confirmó el carrito correctamente.",
    CART_SUCCESS_UNCONFIRM          : "Se desconfirmó el carrito correctamente.",
    CART_SUCCESS_COMPLETE           : "¡Muchas gracias por su compra!",



    // Catalog
    CATALOG_NAME                    : "Catálogo",
    CATALOG_TITLE                   : "Catálogo 2020",

    // About Us
    ABOUT_NAME                      : "Quiénes somos",
    ABOUT_TITLE                     : "¿Quiénes Somos?",
    ABOUT_YESTERDAY                 : "Ayer",
    ABOUT_YESTERDAY_TITLE           : "“Para saber a donde vamos, tenemos que saber de donde venimos.”",
    ABOUT_YESTERDAY_LINE1           : "En 1994 buscamos liderar el mercado nacional de las motopartes, enfocándonos en las necesidades y deseos de nuestros clientes.",
    ABOUT_YESTERDAY_LINE2           : "Desde ese entonces, el contacto con los principales importadores asiáticos de repuestos y accesorios fue inminente. Posicionando a TERCOM como una de las principales marcas líderes.",
    ABOUT_YESTERDAY_LINE3           : "Con el correr de los años, nos dimos cuenta que la industria nacional tenía un gran potencial, por lo que decidimos incorporar productos con marca propia y apoyar la industria invirtiendo en productos 100% nacionales.",
    ABOUT_YESTERDAY_LINE4           : "De esta forma, Tercom SRL se convirtió en lo que es. Una empresa líder en el rubro de las motopartes, con la más amplia gama de repuestos y accesorios nacionales e importados.",
    ABOUT_TODAY                     : "Hoy",
    ABOUT_TODAY_LINE1               : "La constante innovación tecnológica y la dinámica actual de los negocios, nos obligan a ser más que una compañía de servicios con logística y distribución.",
    ABOUT_TODAY_LINE2               : "Estamos convencidos que fortaleciendo el vínculo con nuestros clientes, los ayudamos a cumplir sus objetivos y que juntos logremos un crecimiento sostenido.",
    ABOUT_TOMORROW                  : "Mañana",
    ABOUT_TOMORROW_LINE1            : "Queremos ser un ejemplo de compañía. Alcanzar un alto valor agregado gracias a nuestra  excelencia en la gestión empresarial, la cultura organizacional y la calidad de nuestro management.",

    // Bnefits
    BENEFITS_NAME                   : "Beneficios de comprar en la tienda",
    BENEFITS_SELECT                 : "Seleccioná un beneficio",
    BENEFITS_MAIL                   : "Realizá tus consultas, gestioná tus reclamos y devoluciones a través de la web y la app",
    BENEFITS_SECURE                 : "Sitio 100% seguro",
    BENEFITS_PHONE                  : "Usá la app desde tu celular",
    BENEFITS_ALLDAY                 : "Podes comprar las 24 horas del día los 365 días del año",
    BENEFITS_DESKTOP                : "Comprá desde tu escritorio de manera sencilla, segura y cómoda",
    BENEFITS_CART                   : "Aprovechá las ofertas, lanzamientos y liquidaciones",
    BENEFITS_TRANSPORT              : "Elegí la forma mas conveniente de entrega",
    BENEFITS_LOCATION               : "Retirá en nuestras oficinas de San Martín",

    // News
    NEWS_NAME                       : "Novedades",
    NEWS_VIEW                       : "Ver novedad",

    // Download
    DOWNLOAD_TITLE1                 : "Descarga la",
    DOWNLOAD_TITLE2                 : "App Oficial",
    DOWNLOAD_SUBTITLE               : "Obtené beneficios extras con nuestros descuentos exclusivos",
    DOWNLOAD_TEXT                   : "Descarga la App y mantenete al día",
    DOWNLOAD_APP_STORE              : "Descarga en App Store",
    DOWNLOAD_GOGOLE_PLAY            : "Descarga en Google Play",

    // Error
    ERROR_TITLE                     : "404",
    ERROR_MESSAGE                   : "Parece que la página no existe",
    ERROR_LINK                      : "Ir a la página principal",



    // Client
    CLIENT_NAME                     : "Cuenta",
    CLIENT_TITLE                    : "Mi Cuenta",
    CLIENT_LOGOUT                   : "Salir",

    // Client: Banks
    BANKS_NAME                      : "Cuentas Bancarias",
    BANKS_ACCOUNT                   : "Nº de cuenta",
    BANKS_CBU                       : "CBU",

    // Client: Account
    ACCOUNT_NAME                    : "Datos Personales",
    ACCOUNT_SOCIAL_REASON           : "Razón social",
    ACCOUNT_FANTASY_NAME            : "Nombre de fantasía",
    ACCOUNT_EMAIL                   : "Email",
    ACCOUNT_PHONE                   : "Teléfono",
    ACCOUNT_CELLPHONE               : "Celular",
    ACCOUNT_CUIT                    : "CUIT",
    ACCOUNT_ADDRESS                 : "Dirección",
    ACCOUNT_PROVINCE                : "Provincia",
    ACCOUNT_LOCALITY                : "Localidad",
    ACCOUNT_SUBMIT                  : "Editar cuenta",
    ACCOUNT_ERROR_FANTASY_NAME      : "Por favor, complete el nombre de fantasia.",
    ACCOUNT_ERROR_EMAIL_EMPTY       : "Por favor, complete el email.",
    ACCOUNT_ERROR_EMAIL_INVALID     : "El email indicado es inválido.",
    ACCOUNT_ERROR_PHONE             : "Por favor, complete el celular o teléfono.",
    ACCOUNT_SUCCESS_EDIT            : "Los datos de la cuenta se editaron correctamente.",

    // Client: Addresses
    ADDRESSES_NAME                  : "Lugares de entrega",
    ADDRESSES_CREATE                : "Agregar lugar de entrega",
    ADDRESSES_EDIT                  : "Editar lugar de entrega",
    ADDRESSES_DELETE                : "Eliminar lugar de entrega",
    ADDRESSES_DESCRIPTION           : "Descripción",
    ADDRESSES_ADDRESS               : "Dirección",
    ADDRESSES_PROVINCE              : "Provincia",
    ADDRESSES_LOCALITY              : "Localidad",
    ADDRESSES_POSTAL_CODE           : "Código Postal",
    ADDRESSES_SCHEDULE              : "Horario",
    ADDRESSES_IS_DEFAULT            : "Principal",
    ADDRESSES_NO_DEFAULT            : "Secundaria",
    ADDRESSES_PENDING               : "El lugar de entrega tiene una modificación pendiente.",
    ADDRESSES_DELETE_TEXT           : "¿Realmente desea eliminar el lugar de entrega \"{0}\"?",
    ADDRESSES_NONE_AVAILABLE        : "No hay direcciones de entrega disponibles",
    ADDRESSES_ERROR_EXISTS          : "No existe el lugar de entrega indicada.",
    ADDRESSES_ERROR_DESCRIPTION     : "Por favor, complete la descripción.",
    ADDRESSES_ERROR_ADDRESS         : "Por favor, complete la dirección.",
    ADDRESSES_ERROR_PROVINCE        : "Por favor, complete la provincia.",
    ADDRESSES_ERROR_LOCALITY        : "Por favor, complete la localidad.",
    ADDRESSES_ERROR_POSTAL_CODE     : "Por favor, complete el código postal.",
    ADDRESSES_ERROR_SCHEDULE        : "Por favor, complete el horario.",
    ADDRESSES_ERROR_IS_DEFAULT      : "Debe tener un lugar de entrega predeterminada.",
    ADDRESSES_ERROR_DELETE          : "No puede eliminar el lugar de entrega principal.",
    ADDRESSES_SUCCESS_CREATE        : "El lugar de entrega de entrega se creó correctamente.",
    ADDRESSES_SUCCESS_EDIT          : "El lugar de entrega de entrega se editó correctamente.",
    ADDRESSES_SUCCESS_DELETE        : "El lugar de entrega de entrega se eliminó correctamente.",

    // Client: Vouchers
    VOUCHERS_NAME                   : "Mis Comprobantes",
    VOUCHERS_INVOICES               : "Facturas",
    VOUCHERS_RECEIPTS               : "Recibos",
    VOUCHERS_CREDIT_NOTES           : "Notas de crédito",
    VOUCHERS_DEBIT_NOTES            : "Notas de débito",
    VOUCHERS_DESCRIPTION            : "Descripción",
    VOUCHERS_DATE                   : "Fecha",
    VOUCHERS_AMOUNT                 : "Importe",
    VOUCHERS_DOWNLOAD               : "Descargar",
    VOUCHERS_NONE_INVOICES          : "No hay facturas disponibles.",
    VOUCHERS_NONE_RECEIPTS          : "No hay recibos disponibles.",
    VOUCHERS_NONE_CREDIT_NOTES      : "No hay notas de crédito disponibles.",
    VOUCHERS_NONE_DEBIT_NOTES       : "No hay notas de débito disponibles.",

    // Client: Orders
    ORDERS_NAME                     : "Mis Pedidos",
    ORDERS_TITLE                    : "Pedido: #{0}",
    ORDERS_SINGULAR                 : "Pedido",
    ORDERS_GROUP                    : "Grupos de pedidos",
    ORDERS_GROUP_TITLE              : "Grupo de pedidos: #{0}",
    ORDERS_GROUP_SINGULAR           : "Grupo de pedidos",
    ORDERS_INDIVIDUAL               : "Pedidos",
    ORDERS_ID                       : "ID",
    ORDERS_DATE                     : "Fecha",
    ORDERS_STATUS                   : "Estado",
    ORDERS_AMOUNT                   : "Cantidad",
    ORDERS_TOTAL                    : "Total",
    ORDERS_TOTALS                   : "Totales",
    ORDERS_TOTAL_FOR                : "Total ({0})",
    ORDERS_PRODUCT                  : "Producto",
    ORDERS_PRICE                    : "Precio",
    ORDERS_SUBTOTAL                 : "Subtotal",
    ORDERS_SUMMARY                  : "Resumen del pedido",
    ORDERS_SHIPPING                 : "Envío del pedido",
    ORDERS_SHIPPING_TYPE            : "Tipo de envío",
    ORDERS_ADDRESS                  : "Dirección de envío",
    ORDERS_TRANSPORT                : "Transporte",
    ORDERS_PRODUCTS                 : "Productos en: {0}",
    ORDERS_MESSAGE                  : "Mensaje",
    ORDERS_NONE_AVAILABLE           : "No hay pedidos disponibles.",

    // Client: Queries
    QUERIES_NAME                    : "Mis Consultas",
    QUERIES_SINGULAR                : "Consulta",
    QUERIES_OPEN                    : "Consultas abiertas",
    QUERIES_CLOSED                  : "Consultas cerradas",
    QUERIES_CREATE                  : "Crear consulta",
    QUERIES_TITLE                   : "Consulta #{0}",
    QUERIES_TYPE                    : "Tipo",
    QUERIES_SUBJECT                 : "Asunto",
    QUERIES_MESSAGE                 : "Mensaje",
    QUERIES_IMAGE                   : "Imagen",
    QUERIES_ASSIGNED                : "Asignada a",
    QUERIES_LAST_REPLY              : "Última respuesta",
    QUERIES_STATUS                  : "Estado",
    QUERIES_STATUS_OPEN             : "Abierta",
    QUERIES_STATUS_CLOSE            : "Cerrada",
    QUERIES_READING                 : "Lectura",
    QUERIES_READ                    : "Leida",
    QUERIES_UNREAD                  : "No leida",
    QUERIES_PUBLISH                 : "Publicada el {0}",
    QUERIES_REPLY                   : "Responder",
    QUERIES_NONE_AVAILABLE          : "No hay consultas disponibles.",
    QUERIES_ERROR_EXISTS            : "No existe la consulta indicada.",
    QUERIES_ERROR_TYPE_EMPTY        : "Por favor, complete el tipo.",
    QUERIES_ERROR_TYPE_INVALID      : "El tipo indicado no existe",
    QUERIES_ERROR_SUBJECT           : "Por favor, complete el asunto.",
    QUERIES_ERROR_MESSAGE           : "Por favor, complete el mensaje.",
    QUERIES_ERROR_IMAGE_SIZE        : "El archivo indicado es muy grande.",
    QUERIES_ERROR_IMAGE_TYPE        : "El archivo debe ser una imágen.",
    QUERIES_SUCCESS_CREATE          : "La consulta se creó correctamnte.",
    QUERIES_SUCCESS_REPLY           : "La respuesta se creó correctamnte.",



    // Notifications
    NOTIFICATIONS_NAME              : "Notificaciones",
    NOTIFICATIONS_NONE_AVAILABLE    : "No tienes notificaciones",
    NOTIFICATIONS_ENABLE            : "Habilitar notificaciones",
    NOTIFICATIONS_ENABLED           : "¡Las notificaciones estan habilitadas!",
    NOTIFICATIONS_TITLE_OFFER       : "¡Tenemos una nueva oferta para vos!",
    NOTIFICATIONS_TITLE_ORDER       : "¡Tu pedido ha sido actualizado!",
    NOTIFICATIONS_TEXT_ORDER        : "El estado del pedido paso de \"{0}\" a \"{1}\". Haz click para verlo.",
    NOTIFICATIONS_TITLE_CART        : "¡Completa tu compra!",
    NOTIFICATIONS_TEXT_CART         : "Haz click para ver y completar tu compra.",
    NOTIFICATIONS_TITLE_QUERY       : "¡Han respondido tu consulta!",
    NOTIFICATIONS_TEXT_QUERY        : "Tienes una nueva resupuesta a tu consulta \"{0}\". Haz click para verla.",
    NOTIFICATIONS_TITLE_INVOICE     : "¡Tienes una nueva Factura!",
    NOTIFICATIONS_TEXT_INVOICE      : "Tu factura \"{0}\" está lista. Puedes descargarla desde aquí.",
    NOTIFICATIONS_TITLE_RECEIPT     : "¡Tienes un nuevo Recibo!",
    NOTIFICATIONS_TEXT_RECEIPT      : "Tu recibo \"{0}\" está listo. Puedes descargarlo desde aquí.",
    NOTIFICATIONS_TITLE_CREDITNOTE  : "¡Tienes una nueva Nota de Crédito!",
    NOTIFICATIONS_TEXT_CREDITNOTE   : "Tu nota de crédito \"{0}\" está lista. Puedes descargarla desde aquí.",
    NOTIFICATIONS_TITLE_DEBITNOTE   : "¡Tienes una nueva Nota de Débito!",
    NOTIFICATIONS_TEXT_DEBITNOTE    : "Tu nota de débito \"{0}\" está lista. Puedes descargarla desde aquí.",


    // Terms and Conditions
    TERMS_NAME                      : "Términos y condiciones",
    TERMS_LINE1                     : "TERCOM SRL, (en adelante TERCOM) a través de nuestra url https://www.tiendatercom.com (en adelante el “Sitio”), comunica a nuestros Clientes (en adelante “Usuario” y/o “Usuarios”) los términos y condiciones que van a regir a los efectos de la utilización por éstos del Sitio y que regirán los derechos y obligaciones entre el Usuario y TERCOM. Asimismo, se informa en el presente al Usuario que al operar el Sitio, aceptan la utilización de sus Datos Personales consignados en el Sitio (en adelante los “Datos Personales”) con adecuación a lo establecido en el punto 4 infra.",
    TERMS_LINE2                     : "El Propietario y Operador del Sitio es TERCOM SRL. La dirección postal del Sitio web es Calle 115 856, San Martín, Provincia de Buenos Aires. (Código Postal 1650) República Argentina. En función de los expresado ut supra, se fija en el presente los términos y condiciones a los efectos de reglar el uso por parte del Usuario del sitio www.tiendatercom.com y sus respectivos derechos y obligaciones.",
    TERMS_LIST1                     : "El sólo uso del Sitio implica la aceptación irrevocable e incondicional por parte del Usuario de todos los términos y condiciones fijados en el presente, asumiendo la obligación de respetar las mismas. En caso de no aceptar las presentes Bases y Condiciones el Usuario deberá abstenerse de utilizar el Sitio. Con la aceptación de las presente Bases y Condiciones, el Usuario tendrá una relación contractual con TERCOM.com en caso de utilización del Sitio o en caso de ser usuario registrado. Cualquiera de las Partes podrá rescindir la relación contractual, el Usuario Registrado, dando de baja su inscripción, el Usuario registrado no utilizando el Sitio y TERCOM en cualquier momento notificándolo a sus domicilios registrados o a sus domicilios electrónicos.",
    TERMS_LIST2                     : "TERCOM se reserva el derecho de realizar cambios en el Sitio, en las condiciones de uso y en las notificaciones que correspondieren, en cualquier momento, y con la simple modificación del presente, comunicándolo a través de publicación en el Sitio. Dicha modificación será aplicable en forma inmediata a su publicación. Asimismo, TERCOM se reserva el derecho en cualquier momento de interrumpir el servicio ofrecido, sin previo aviso a los Usuarios, en forma transitoria o definitiva, y sin necesitar en ningún momento consentimiento del Usuario.",
    TERMS_LIST3                     : "En función de lo expresado en el punto 2 del presente, cada vez que el Usuario desee utilizar el Sitio, deberá aceptar a priori todos y cada uno de los términos y condiciones especificados en el presente.",
    TERMS_LIST4                     : "Protección de los Datos Personales: TERCOM ha implementado todos los procesos requeridos a los efectos de Protección de los Datos Personales de los Usuarios, con el objeto de evitar el mal uso, alteración, acceso no autorizado y/o robo de los Datos Personales suministrados por el Usuario a TERCOM. Sin perjuicio de lo expresado en el presente, el Usuario acepta y reconoce que las medidas de seguridad en Internet no son inviolables. Así, todo dato que suministren los Usuarios, serán incluidos en una Base de Datos de TERCOM, con el objeto de identificar perfiles a los efectos de segmentar dicha Base con fines comerciales, promocionales y mejorar las ofertas a los Usuarios, quienes prestan conformidad mediante la utilización del Sitio de tales fines.",
    TERMS_LIST5                     : "Mediante el uso del sitio el Usuario declara bajo juramento ser mayor de 18 años de edad o más, y tener capacidad legal para contratar.",
    TERMS_LIST6                     : "Toda la información y datos suministrados por el Usuario deberán ser verdaderos, precisos, válidos, exactos y vigentes, siendo exclusiva responsabilidad del Usuario verificar que sus datos sean correctamente ingresados en el Sitio y de actualizarlos en caso de corresponder. TERCOM no se responsabiliza por veracidad de los datos suministrados, respondiendo los Usuarios por la autenticidad y exactitud de los mismos.",
    TERMS_LIST7                     : "Al utilizar el Sitio, el Usuario es responsable del cuidado de la confidencialidad de su cuenta. En función de lo especificado en el presente, el Usuario es responsable de todo lo que se registra en su cuenta.",
    TERMS_LIST8                     : "Indemnidad: Asimismo el Sitio sólo se puede utilizar con fines lícitos. El Usuario se obliga a mantener indemne a TERCOM  por cualquier tipo de reclamo, extrajudicial, administrativo o judicial, de terceros, por el uso del Sitio por parte del Usuario, o en caso de verificarse multas y/o deudas de cualquier tipo en función del desempeño o actividad realizada por el Usuario en el Sitio.",
    TERMS_LIST9                     : "Compra de Productos: la compra de cualquier producto o servicio publicado a través del Sitio se encuentra sujeta a disponibilidad de stock, de la aprobación de identidad del Usuario, de la aprobación del sector de Créditos y Cobranzas, como de la aprobación por parte del Usuario de estos términos y condiciones. Asimismo, podrán variar las características técnicas de los productos, los precios de los mismos, podrán los precios no coincidir con los ofrecidos en otros medios de venta utilizados por la empresa TERCOM . Las fotos o ilustraciones publicadas en el Sitio son al solo efecto ilustrativo y la información de los productos, podrá variar en caso de incorrecciones o equívocos involuntarios.",
    TERMS_LIST10                    : "Proceso de compra: Sin perjuicio de encontrarse detallado el proceso de compra en el sitio, el mismo se detalla a continuación:",
    TERMS_LIST101                   : "El usuario accede a la plataforma con el código de usuario /CUIT y clave brindado por la empresa,  sólo pueden acceder a la misma clientes aprobados y dados de alta por la empresa TERCOM.",
    TERMS_LIST102                   : "Una vez elegido el producto el Usuario deberá presionar el botón comprar y verá en su carrito de compras los artículos que tiene cargados, pudiendo modificar la cantidad o eliminar los que desee.",
    TERMS_LIST103                   : "Aquí el Usuario encontrará el resumen de su compra  y promociones que están disponibles en la venta online.",
    TERMS_LIST104                   : "Luego deberá continuar con el proceso de compra.",
    TERMS_LIST105                   : "Podrá seleccionar el tipo de envío, seleccionar su dirección de entrega.",
    TERMS_LIST106                   : "Luego deberá confirmar o modificar los datos ingresados.",
    TERMS_LIST107                   : "TERCOM podrá contactar al Usuario vía telefónica o vía electrónica para realizar una validación de datos, debiendo tener dicha línea base en Argentina.",
    TERMS_LIST108                   : "El resultado de la operación le será informado, y las operaciones exitosas le permitirán a través de la misma Web acceder a sus pedidos realizados en la solapa de su cuenta. ",
    TERMS_LIST11                    : "Precios: Se deja expresa constancia que los precios publicados en el Sitio podrán sufrir modificaciones y el precio final en la factura será el del momento de facturación por parte de la empresa.",
    TERMS_LIST12                    : "Facturación: Salvo requerimiento o solicitud en contrario las facturas de compra serán emitidas a Responsable Inscripto o Responsable Monotributo y las mismas son enviadas al mail informado.",
    TERMS_LIST13                    : "Entregas: Los métodos y costos de envío que le brinda TERCOM dependerán de la cantidad de productos adquiridos por el Usuario y del lugar de entrega de los mismos. Una vez registrado podrá conocer el costo de entrega de los productos seleccionados para una localidad y provincia de lugar de destino. Las entregas se realizarán de Lunes a Viernes de 8 a 17hs en el domicilio indicado por el Usuario. Los domingos y días festivos no se realizarán entregas, por lo tanto, se deberá tener en cuenta solo días hábiles. Se recomienda al Usuario revisar el producto que no tenga rayaduras o golpes producto del traslado, antes de firmar la conformidad de entrega. Asimismo, en el momento de la entrega del producto adquirido se solicitará a la persona receptora del producto un documento que identifique a la misma (Documento Nacional de Identidad o Cédula de Identidad) y autorización por escrito del comprador / usuario registrado. Asimismo, el receptor deberá suscribir el recibo con la aclaración del nombre y documento.",
    TERMS_LIST14                    : "Retiros en TERCOM: Para aquellas operaciones en las que se hubiere seleccionado Retiro en TERCOM, el Usuario deberá aguardar el e-mail de confirmación indicando que ya puede retirar su producto de la Sucursal seleccionada.",
    TERMS_LIST15                    : "Cambios y devoluciones: El reclamo se aceptará dentro de las 72 hs de recibida la mercadería (faltantes, roturas), el mismo debe ser efectuado a través de la página web o vía email al asistente de ventas con copia al vendedor asignado, detallando: Código de cliente. Artículo y cantidad. Nro. de Factura. Motivo y/o descripción de la devolución. TERCOM le responderá asignándole un Nro. de Reclamo",
    TERMS_LIST151                   : "Artículos que no se reconocen en devolución:",
    TERMS_LIST1511                  : "Baterías",
    TERMS_LIST1512                  : "Plásticos",
    TERMS_LIST1513                  : "Faroles",
    TERMS_LIST1514                  : "Lámparas",
    TERMS_LIST1515                  : "Electrónicos",
    TERMS_LIST1516                  : "Productos que no poseen problemas de fabricación",
    TERMS_LIST1517                  : "Productos que no se hayan informado dentro de las 72 hs",
    TERMS_LIST1518                  : "Packaging en mal estado",
    TERMS_LIST1519                  : "Sin Nro de Reclamo asignado",
    TERMS_LIST152                   : "Las devoluciones deberán ser enviadas a la empresa con los siguientes requisitos sin excepción alguna:",
    TERMS_LIST1521                  : "Previamente haber informado a la empresa y habiendo obtenido el Nro. de reclamo, sin excepción alguna.",
    TERMS_LIST1522                  : "Indicar código de cliente. Detallando artículos y cantidad  y motivo de la devolución.",
    TERMS_LIST1523                  : "Cada bulto deberá ser identificado con los datos del cliente.",
    TERMS_LIST1524                  : "Deberá ser enviada a:",
    TERMS_LIST1525                  : "TERCOM S.R.L.",
    TERMS_LIST1526                  : "At: Sr Walter Cáceres (depósito)",
    TERMS_LIST1527                  : "Calle 115 (Ex Güemes) Nº856 – San Martín – Buenos Aires",
    TERMS_LIST1528                  : "CP 1650",
    TERMS_LIST153                   : "En el momento del envió se deberá notificar vía email al asistente de ventas y vendedor asignado, informando por que transporte se ha realizado el envió junto con su Nro. de guía para su correcto seguimiento interno. Siempre y cuando posea el Nro. de Reclamo, caso contrario no se podrá retirar la mercadería del mismo.",
    TERMS_LIST154                   : "Las devoluciones no aceptadas y sin Nro. de reclamo serán remitidas al cliente acarreando los gastos por envió.",
    TERMS_LIST155                   : "Ni Los representantes comerciales ni los fleteros de la empresa están autorizados a  descontar las devoluciones. Por favor no los comprometa.",
    TERMS_LIST156                   : "Recuerde que la mercadería viaja por cuenta y riesgo del cliente. Si usted recibiera la mercadería en malas condiciones deberá dirigir su reclamo al expreso.",
    TERMS_LIST16                    : "Derechos de Propiedad Intelectual: TERCOM es una marca registrada y se encuentran todos los derechos reservados conforme a la ley 11.723.",
    TERMS_LIST17                    : "Legislación aplicable: el presente es regido por las leyes de la República Argentina.",

};
