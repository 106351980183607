import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Images/Store/Products.jpg";
import "Styles/Components/Product/List/ProductsHeader.css";



/**
 * The Products Header
 */
class ProductsHeader extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { className, name, banner, logo, url } = this.props;
        const style    = banner ? { "backgroundImage" : `url(${banner})` } : {};
        const showLogo = Boolean(url && name);

        return <header className={`products-header ${className}`} style={style}>
            {/* <img src={banner} /> */}
            {showLogo && <Wrapper>
                <HyperLink variant="none" href={url} className="products-logo">
                    {logo && <img src={logo} alt={name} />}
                    {!logo && <h2>{NLS.get(name)}</h2>}
                </HyperLink>
            </Wrapper>}
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className : PropTypes.string,
        banner    : PropTypes.string,
        logo      : PropTypes.string,
        name      : PropTypes.string,
        url       : PropTypes.string,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }
}

export default ProductsHeader;
