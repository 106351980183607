import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";
import Alert                from "Components/Utils/Form/Alert";

// Actions
import {
    fetchQueryCreate, createQuery,
} from "Actions/Client/QueryActions";



/**
 * The Query Create
 */
class QueryCreate extends React.Component {
    // The Initial Data
    initialData = {
        queryTypeID : "",
        subject     : "",
        message     : "",
        image       : "",
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
        error   : "",
    }



    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchQueryCreate();
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, errors : {}, error : "" });
        try {
            const queryID = await this.props.createQuery(data);
            this.setState({ loading : false });
            this.props.history.push(`/cuenta/consulta/${queryID}`);
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, errors, error } = this.state;
        const { types } = this.props.data;

        return <Card className="client-card">
            <SubTitle message="QUERIES_CREATE" icon="query" />
            <section className="spacing">
                <Alert variant="error" message={error} onClose={this.closeAlert} />

                <form className="client-fields" onSubmit={this.handleSubmit}>
                    <TextField
                        type="select"
                        name="queryTypeID"
                        label="QUERIES_TYPE"
                        options={types}
                        value={data.queryTypeID}
                        error={errors.queryTypeID}
                        onChange={this.handleChange}
                        isRequired
                        withNone
                    />
                    <TextField
                        className="client-double"
                        name="subject"
                        label="QUERIES_SUBJECT"
                        value={data.subject}
                        error={errors.subject}
                        onChange={this.handleChange}
                        isRequired
                    />

                    <TextField
                        type="textarea"
                        name="message"
                        className="client-triple"
                        label="QUERIES_MESSAGE"
                        value={data.message}
                        error={errors.message}
                        onChange={this.handleChange}
                        isRequired
                    />
                    <TextField
                        type="file"
                        name="image"
                        className="client-triple"
                        label="QUERIES_IMAGE"
                        error={errors.image}
                        onChange={this.handleChange}
                    />

                    <div className="client-btn">
                        <Button
                            variant="primary"
                            message="QUERIES_CREATE"
                            isDisabled={loading}
                            fullWidth
                        />
                    </div>
                </form>
            </section>
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history          : PropTypes.object.isRequired,
        fetchQueryCreate : PropTypes.func.isRequired,
        createQuery      : PropTypes.func.isRequired,
        data             : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.query.create,
        };
    }
}

export default withRouter(connect(QueryCreate.mapStateToProps, {
    fetchQueryCreate, createQuery,
})(QueryCreate));
