import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "Utils/Common/Utils";

// Components
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Utils/Common/Slider.css";




/**
 * The Slider
 */
class Slider extends React.Component {
    // The Current State
    state = {
        isMounted   : false,
        curIdx      : 0,
        moveIdx     : 0,
        direction   : 0,
        touchStart  : 0,
        touchDiff   : 0,
        animate     : false,
        loopTimeout : null,
        moveTimeout : null,
        endTimeout  : null,
    }

    // References
    sliderElem = null;

    /**
     * Starts the Timeout
     * @returns {Void}
     */
    componentDidMount() {
        this.setState({
            loopTimeout : this.autoSlide(),
            isMounted   : true,
        });
    }

    /**
     * End the Timeouts
     * @returns {Void}
     */
    componentWillUnmount() {
        if (this.state.loopTimeout) {
            window.clearTimeout(this.state.loopTimeout);
        }
        if (this.state.moveTimeout) {
            window.clearTimeout(this.state.moveTimeout);
        }
        this.setState({
            isMounted   : false,
            loopTimeout : null,
            moveTimeout : null,
            endTimeout  : null,
        });
    }

    /**
     * Returns the Auto Slide
     * @returns {Object}
     */
    autoSlide() {
        return this.props.autoSlide ? window.setTimeout(this.gotoDir(1), 5000) : null;
    }



    /**
     * Moves the Carousel to the given Index
     * @param {Number} moveIdx
     * @returns {Function}
     */
    gotoIndex = (moveIdx) => () => {
        this.startMove(moveIdx, moveIdx > this.state.curIdx ? 1 : -1);
    }

    /**
     * Moves the Carousel to the given Direction
     * @param {Number} direction
     * @returns {Function}
     */
    gotoDir = (direction) => (e) => {
        let moveIdx = this.state.curIdx + direction;
        if (moveIdx > this.props.data.length - 1) {
            moveIdx = 0;
        } else if (moveIdx < 0) {
            moveIdx = this.props.data.length - 1;
        }
        this.startMove(moveIdx, direction);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    /**
     * Starts the Movements
     * @param {Number} moveIdx
     * @param {Number} direction
     * @returns {Void}
     */
    startMove(moveIdx, direction) {
        if (!this.state.isMounted) {
            return;
        }

        this.clearTimeouts();
        this.setState({
            moveIdx, direction,
            animate     : true,
            loopTimeout : null,
            moveTimeout : window.setTimeout(this.endMove, 300),
        });
    }

    /**
     * Ends the Movement
     * @returns {Void}
     */
    endMove = () => {
        if (!this.state.isMounted) {
            return;
        }
        const endTimeout = window.setTimeout(() => {
            this.setState({ animate : false, direction : 0 });
        }, 50);

        this.setState({
            curIdx      : this.state.moveIdx,
            loopTimeout : this.autoSlide(),
            moveTimeout : null,
            endTimeout  : endTimeout,
        });
    }

    /**
     * Clears the Timeouts
     * @returns {Void}
     */
    clearTimeouts() {
        const { loopTimeout, moveTimeout, endTimeout } = this.state;
        if (loopTimeout) {
            window.clearTimeout(loopTimeout);
        }
        if (moveTimeout) {
            window.clearTimeout(moveTimeout);
        }
        if (endTimeout) {
            this.setState({ animate : false, direction : 0 });
            window.clearTimeout(endTimeout);
        }
    }



    /**
     * Handles the Touch Start
     * @param {Event} e
     * @returns {Void}
     */
    handleTouchStart = (e) => {
        const { isMounted, loopTimeout } = this.state;
        if (!isMounted || this.props.data.length === 1) {
            return;
        }
        if (loopTimeout) {
            window.clearTimeout(loopTimeout);
        }
        this.setState({
            loopTimeout : null,
            touchStart  : e.touches[0].clientX,
        });
    }

    /**
     * Handles the Touch Move
     * @param {Event} e
     * @returns {Void}
     */
    handleTouchMove = (e) => {
        const { isMounted, moveTimout, touchStart } = this.state;
        if (!isMounted || moveTimout || this.props.data.length === 1) {
            return;
        }
        const currentX  = e.touches[0].clientX;
        const touchDiff = touchStart - currentX;
        this.setState({ touchDiff });
    }

    /**
     * Handles the Touch End
     * @param {Event} e
     * @returns {Void}
     */
    handleTouchEnd = (e) => {
        const { isMounted, curIdx, touchDiff } = this.state;
        if (!isMounted || this.props.data.length === 1) {
            return;
        }
        this.setState({ touchStart : 0, touchDiff : 0 });
        if (Math.abs(touchDiff) < 30 && this.props.onClick) {
            this.props.onClick(this.props.data[curIdx])();
        } else if (touchDiff > 0) {
            this.gotoDir(1)();
        } else {
            this.gotoDir(-1)();
        }
    }



    /**
     * Parses the State and Returns the Slides
     * @returns {Object}
     */
    getSlides() {
        const { curIdx, moveIdx, direction } = this.state;
        const { data }                       = this.props;

        let prevIdx = curIdx - 1 < 0 ? data.length - 1 : curIdx - 1;
        let nextIdx = curIdx + 1 > data.length - 1 ? 0 : curIdx + 1;
        let moveTo  = 1;

        if (direction !== 0) {
            if (direction > 0) {
                nextIdx = moveIdx;
                moveTo  = 2;
            } else {
                prevIdx = moveIdx;
                moveTo  = 0;
            }
        }
        const slides = {
            "prev"    : data[prevIdx],
            "current" : data[curIdx],
            "next"    : data[nextIdx],
        };

        return { slides, moveTo, moveIdx };
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { slides, moveTo, moveIdx            } = this.getSlides();
        const { data, className, withDots, onClick } = this.props;
        const { touchDiff, animate                 } = this.state;

        const dots  = Utils.createArrayOf(data.length);
        const style = {};

        if (this.sliderElem && touchDiff !== 0) {
            const rect = this.sliderElem.getBoundingClientRect();
            const size = rect.width + touchDiff;
            style.transform = `translateX(-${size}px)`;
        }

        return <div
            ref={(elem) => { this.sliderElem = elem; }}
            className={`slider-container ${className}`}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
        >
            <div
                className={`slider-images ${animate ? "slider-animate" : ""}`}
                data-move={moveTo}
                style={style}
            >
                {Object.entries(slides).map(([ type, elem ]) => (
                    <div key={type} className={`slider-${type}`}>
                        <img
                            src={elem.image}
                            alt={elem.name}
                            onClick={onClick(elem)}
                        />
                    </div>
                ))}
            </div>
            <nav className="slider-nav">
                <button className="slider-prev" onClick={this.gotoDir(-1)}>
                    <Icon variant="left" />
                </button>
                <button className="slider-next" onClick={this.gotoDir(1)}>
                    <Icon variant="right" />
                </button>
            </nav>
            {withDots ? <nav className="slider-dots">
                <ul className="no-list">
                    {dots.map((elem, index) => <li key={index}>
                        <button
                            className={moveIdx === index ? "slider-active" : ""}
                            onClick={this.gotoIndex(index)}
                        >
                            {index}
                        </button>
                    </li>)}
                </ul>
            </nav> : ""}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data      : PropTypes.array.isRequired,
        onClick   : PropTypes.func,
        className : PropTypes.string,
        autoSlide : PropTypes.bool,
        withDots  : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        onClick   : (() => () => {}),
        className : "",
        autoSlide : false,
        withDots  : false,
    }
}

export default Slider;
