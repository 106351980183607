import API                  from "Utils/App/API";

// Types
import {
    NEWS_LIST, NEWS_DATA,
} from "Utils/Types";



/**
 * Fetches the News
 * @param {Object} params
 * @returns {Function}
 */
export function fetchNews(params) {
    return async (dispatch) => {
        const data = await API.News.getNews(params);
        dispatch({ type : NEWS_LIST, data });
    };
}

/**
 * Fetches the New
 * @param {Number} newsID
 * @returns {Function}
 */
export function fetchNew(newsID) {
    return async (dispatch) => {
        const data = await API.News.getNew({ newsID });
        dispatch({ type : NEWS_DATA, data });
    };
}
