import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import ProductDialog        from "Components/Product/Item/ProductDialog";
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import Timer                from "Components/Utils/Common/Timer";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Product/Item/ProductBanner.css";




/**
 * The Product Banner
 */
class ProductBanner extends React.Component {
    // The Current State
    state = {
        showDialog : false,
    }

    /**
     * Opens the Product Dialog
     * @returns {Void}
     */
    openProduct = () => {
        this.setState({ showDialog : true });
    }

    /**
     * Closes the Product Dialog
     * @returns {Void}
     */
    closeProduct = () => {
        this.setState({ showDialog : false });
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { showDialog      } = this.state;
        const { data, className } = this.props;
        const { id, isPrimary, totalDays, totalHours, startTime, endTime, description, discountName } = data;

        const type = isPrimary ? "banner-primary" : "banner-secondary";
        const time = totalDays > 3 ? NLS.pluralize("BANNER_DAYS", totalDays) : NLS.pluralize("BANNER_HOURS", totalHours);

        return <>
            <div className={`banner-card ${type} ${className}`}>
                <header className="banner-header">
                    <div className="banner-title">
                        <h2>{NLS.get("BANNER_ONLY")}</h2><br />
                        <h3>{time}</h3>
                    </div>
                    <Timer className="banner-timer" startTime={startTime} endTime={endTime} />
                </header>
                <section className="banner-content">
                    <div className="banner-slider">
                        <ProductImage
                            className="banner-image"
                            data={data}
                            onClick={this.openProduct}
                        />
                    </div>
                    <div className="banner-product">
                        <h2>{description}</h2>
                        <div className="banner-price">
                            <div className="banner-price-title">{discountName}</div>
                            <ProductPrice className="banner-price-value" data={this.props.data} />
                        </div>
                        <div className="banner-show">
                            <Button
                                variant={isPrimary ? "primary" : "secondary"}
                                className="banner-btn"
                                message="BANNER_SHOW"
                                href={`/ofertas/${id}`}
                            />
                        </div>
                    </div>
                </section>
            </div>

            <ProductDialog
                open={showDialog}
                data={data}
                onClose={this.closeProduct}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data      : PropTypes.object.isRequired,
        className : PropTypes.string,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }
}

export default ProductBanner;
