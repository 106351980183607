import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setPage }          from "Actions/Client/ClientActions";
import { fetchOrderGroups } from "Actions/Client/OrderActions";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Client/Orders.css";



/**
 * The Order Group List
 */
class OrderGroupList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchOrderGroups(params);
        this.props.setPage(params.page);
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchOrderGroups(newParams);
            this.props.setPage(newParams.page);
        }
    }

    /**
     * Returns the Params
     * @param {Object} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        return {
            page : Number(params.page) || 1,
        };
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isTablet, list, total, currencies } = this.props;
        const { page                              } = this.getParams();

        const baseUrl = "/cuenta/grupo-pedido";

        return <Card className="client-card">
            <SubTitle show={!isTablet} message="ORDERS_NAME" icon="cart" />
            <header className="client-header">
                <div className="client-tabs">
                    <Button variant="border" message="ORDERS_GROUP"      href="/cuenta/grupo-pedidos" />
                    <Button variant="cancel" message="ORDERS_INDIVIDUAL" href="/cuenta/pedidos"       />
                </div>
            </header>
            <section className="orders-content client-table spacing">
                <Table hasContent={!!list.length} none="ORDERS_NONE_AVAILABLE">
                    <TableHead>
                        <TableCell message="ORDERS_ID"            />
                        <TableCell message="ORDERS_DATE"          />
                        <TableCell message="ORDERS_STATUS"        />
                        <TableCell message="ORDERS_SHIPPING_TYPE" />
                        <TableCell message="ORDERS_AMOUNT" align="center" />
                        {Object.values(currencies).map((currency, index) => <TableCell
                            key={index}
                            message={NLS.format("ORDERS_TOTAL_FOR", currency)}
                            align="right"
                        />)}
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow
                            key={elem.groupID}
                            href={`${baseUrl}/${elem.groupID}/${page}`}
                        >
                            <TableCell
                                isLink
                                href={`${baseUrl}/${elem.groupID}/${page}`}
                                message={`#${elem.groupID}`}
                            />
                            <TableCell message={elem.groupTimeFull} breakAfter />
                            <TableCell message={elem.statusName} />
                            <TableCell message={elem.shippingName} breakAfter />
                            <TableCell message={elem.items.length} align="center" hide />
                            {Object.keys(currencies).map((currencyID) => {
                                const total = elem.total[currencyID];
                                return <TableCell
                                    key={currencyID}
                                    message={total ? `${total.symbol} ${total.price}` : ""}
                                    align="right"
                                    nowrap
                                />;
                            })}
                        </TableRow>)}
                    </TableBody>
                </Table>
                <Pagination
                    current={page}
                    total={total}
                    url="/cuenta/grupo-pedidos"
                />
            </section>
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setPage          : PropTypes.func.isRequired,
        fetchOrderGroups : PropTypes.func.isRequired,
        match            : PropTypes.object.isRequired,
        isTablet         : PropTypes.bool.isRequired,
        list             : PropTypes.array.isRequired,
        total            : PropTypes.number.isRequired,
        currencies       : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isTablet   : state.core.isTablet,
            list       : state.order.groups.list,
            total      : state.order.groups.total,
            currencies : state.order.groups.currencies,
        };
    }
}

export default connect(OrderGroupList.mapStateToProps, {
    setPage, fetchOrderGroups,
})(OrderGroupList);
