import React                from "react";
import PropTypes            from "prop-types";

// Components
import Slider               from "Components/Utils/Common/Slider";

// Styles
import "Styles/Components/Product/Item/ProductImage.css";



/**
 * The Product Image
 */
class ProductImage extends React.Component {
    /**
     * Renders the Image
     * @returns {Object}
     */
    renderImage() {
        const { description, hasImages, image, images } = this.props.data;
        const hasManyImages = Boolean(hasImages && images.length > 1);

        // Show a Slider for many images
        if (hasManyImages) {
            return <Slider data={images} />;
        }

        // Show the Single Image
        if (hasImages) {
            return <img src={image.image} alt={image.name} />;
        }

        // Just show the Description
        return <h3>{description}</h3>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, onClick } = this.props;

        return <div className={`product-image ${className}`} onClick={onClick}>
            {this.renderImage()}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data      : PropTypes.object.isRequired,
        className : PropTypes.string,
        onClick   : PropTypes.func,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }
}

export default ProductImage;
