import Utils                from "Utils/Common/Utils";

// Types
import {
    OFFER_LIST, OFFER_DATA,
    STORE_FAVORITE,
} from "Utils/Types";



// The initial State
const initialState = {
    error  : false,
    offers : {
        banners : [],
        list    : [],
    },
    offer  : {
        name          : "GENERAL_LOADING",
        url           : "",
        categories    : [],
        subcategories : [],
        products      : [],
        results       : 0,
        total         : 0,
        pages         : 1,
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case OFFER_LIST:
        return {
            ...state,
            error  : false,
            offers : action.data,
        };
    case OFFER_DATA:
        return {
            ...state,
            error  : false,
            offer  : action.data,
        };
    
    // Update the Favorite
    case STORE_FAVORITE:
        return {
            ...state,
            error  : false,
            offer  : Utils.setFavorite(state.offer, action.data.productCode),
        };
    
    default:
        return state;
    }
};
