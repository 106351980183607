import API                  from "Utils/App/API";

// Types
import {
    ADDRESS_LIST, ADDRESS_DATA, ADDRESS_UNSET,
} from "Utils/Types";



/**
 * Fetches the Address List
 * @param {Object} params
 * @returns {Function}
 */
export function fetchAddresses(params) {
    return async (dispatch) => {
        const data = await API.Address.getAddresses(params);
        dispatch({ type : ADDRESS_LIST, data });
    };
}

/**
 * Fetches the Address Data
 * @param {Object}   addressID
 * @param {Boolean=} loader
 * @returns {Function}
 */
export function fetchAddress(addressID, loader = true) {
    return async (dispatch) => {
        dispatch({ type : ADDRESS_UNSET });
        const data = await API.Address.getAddress({ addressID }, loader);
        dispatch({ type : ADDRESS_DATA, data });
    };
}



/**
 * Creates/Edits an Address
 * @param {Object} params
 * @returns {Function}
 */
export function editAddress(params) {
    return async (dispatch) => {
        const data = await API.Address.editAddress(params);
        dispatch({ type : ADDRESS_LIST, data });
        return data.success;
    };
}

/**
 * Deletes an Address
 * @param {Number} addressID
 * @returns {Function}
 */
export function deleteAddress(addressID) {
    return async (dispatch) => {
        const data = await API.Address.deleteAddress({ addressID });
        dispatch({ type : ADDRESS_LIST, data });
        return data.success;
    };
}
