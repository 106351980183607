import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCategories }  from "Actions/Store/CategoryActions";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Store/Category.css";



/**
 * The Category List
 */
class CategoryList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchCategories();
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data } = this.props;

        return <Wrapper withSpacing>
            <Title message="CATEGORIES_TITLE" />
            <Card className="categories-container">
                {data.map((elem) => <section key={elem.id}>
                    <SubTitle message={elem.name} href={`/categorias/${elem.url}`} />
                    <div
                        className="spacing categories-content"
                        style={{ backgroundImage : `url(${elem.image})` }}
                    >
                        <ul className="no-list categories-list">
                            {elem.childs.map((sub) => <li key={sub.id}>
                                <HyperLink
                                    variant="gray"
                                    href={`/categorias/${elem.url}/${sub.url}`}
                                    message={sub.name}
                                />
                            </li>)}
                        </ul>
                    </div>
                </section>)}
            </Card>
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCategories : PropTypes.func.isRequired,
        data            : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.category.categories,
        };
    }
}

export default connect(CategoryList.mapStateToProps, {
    fetchCategories,
})(CategoryList);
