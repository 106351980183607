import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import LoginForm            from "Components/Auth/LoginForm";
import LoginDialog          from "Components/Auth/LoginDialog";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Common/Icon";

// Images
import LogoImage            from "Styles/Images/LogoBlack.png";

// Styles
import "Styles/Images/AuthBG.jpg";
import "Styles/Components/Auth/Auth.css";



/**
 * The Login Page
 */
class LoginPage extends React.Component {
    // The Current State
    state = {
        showDialog : false,
    }

    /**
     * Opens a Dialog
     * @param {Event} e
     * @returns {Void}
     */
    openDialog = (e) => {
        this.setState({ showDialog : true });
        e.preventDefault();
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.setState({ showDialog : false });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { showDialog } = this.state;
        const redirect = this.props.location.state ? this.props.location.state.from.pathname : "/";

        return <div className="auth-container">
            <div className="auth-content">
                <div className="auth-main">
                    <h1><img src={LogoImage} alt={NLS.get("GENERAL_NAME")} /></h1>
                    <h2>{NLS.get("AUTH_NAME")}</h2>
                    <LoginForm redirect={redirect} />
                    <p className="auth-recover">
                        <HyperLink
                            variant="black"
                            message="AUTH_RECOVER_USER"
                            onClick={this.openDialog}
                        />
                    </p>
                </div>
                <p className="auth-terms">
                    {NLS.get("AUTH_TERMS1")}
                    <HyperLink
                        href="/terminos-y-condiciones"
                        target="_blank"
                        message="AUTH_TERMS2"
                    />
                    {NLS.get("AUTH_TERMS3")}
                </p>
            </div>
            <div className="auth-info">
                <ul className="no-list">
                    <li>
                        <Icon variant="product" className="auth-icon" />
                        <p>{NLS.get("AUTH_INFO_MENU")}</p>
                    </li>
                    <li>
                        <Icon variant="account" className="auth-icon" />
                        <p>{NLS.get("AUTH_INFO_ACCOUNT")}</p>
                    </li>
                </ul>
            </div>
            <LoginDialog
                open={showDialog}
                onClose={this.closeDialog}
            />
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        location : PropTypes.object.isRequired,
    }
}

export default LoginPage;
