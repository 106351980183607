import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { fetchCylinders }   from "Actions/Store/BikeActions";
import NLS                  from "Utils/App/NLS";

// Styles
import "Styles/Images/Store/Cylinders.jpg";
import "Styles/Components/Store/Cylinders.css";



/**
 * The Cylinders Page
 */
class CylindersPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchCylinders();
    }

    /**
     * Handles the Select Change
     * @param {Event} e
     * @returns {Void}
     */
    handleChange = (e) => {
        if (e.target.value) {
            this.props.history.push(`/cilindradas/${e.target.value}`);
        }
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data } = this.props;

        return <div className="cylinders-container">
            <div className="cylinders-content">
                <h2>{NLS.get("BIKES_SELECT")}</h2>
                <select className="cylinders-select" onChange={this.handleChange}>
                    <option>{NLS.get("BIKES_TITLE")}</option>
                    {data.map((elem) => <option key={elem.id} value={elem.url}>
                        {elem.name}
                    </option>)}
                </select>
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history        : PropTypes.object.isRequired,
        fetchCylinders : PropTypes.func.isRequired,
        data           : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.bike.cylinders,
        };
    }
}

export default withRouter(connect(CylindersPage.mapStateToProps, {
    fetchCylinders,
})(CylindersPage));
