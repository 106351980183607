// Types
import {
    ORDER_LIST, ORDER_DATA,
    ORDER_GROUP_LIST, ORDER_GROUP_DATA,
} from "Utils/Types";



// The initial State
const initialState = {
    error  : false,
    orders : {
        list       : [],
        total      : 0,
        currencies : {},
    },
    order  : {
        orderID  : 0,
        products : [],
        totals   : [],
    },
    groups : {
        list       : [],
        total      : 0,
        currencies : {},
    },
    group  : {
        groupID  : 0,
        products : [],
        totals   : [],
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case ORDER_LIST:
        return {
            ...state,
            orders : action.data,
        };
    case ORDER_DATA:
        return {
            ...state,
            order : action.data,
        };
    case ORDER_GROUP_LIST:
        return {
            ...state,
            groups : action.data,
        };
    case ORDER_GROUP_DATA:
        return {
            ...state,
            group : action.data,
        };
    default:
        return state;
    }
};
