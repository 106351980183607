import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchHistory }   from "Actions/Store/ProductActions";
import Production           from "Utils/Common/Production";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsAside        from "Components/Product/List/ProductsAside";
import ProductsList         from "Components/Product/List/ProductsList";
import Nothing              from "Components/Utils/Common/Nothing";



/**
 * The History Page
 */
class HistoryPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchHistory(params);
    }

    /**
     * Load the Data if the History changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchHistory(newParams);
        }
    }

    /**
     * Returns the Params for the Fetch
     * @param {Object=} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        const initials = Production.getInitialParams();
        return Production.getParams(initials, params);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, error } = this.props;
        const { category, subcategory, page, type, price, sort } = this.getParams();

        if (!data.products.length) {
            return <Nothing
                className="products-none"
                message="HISTORY_NONE_AVAILABLE"
                withWrapper
            />;
        }

        const production = new Production(data, "/historial", type, price, sort);
        const name       = NLS.format("HISTORY_TITLE");

        production.addCrumb(production.fullUrl, "HISTORY_NAME");
        production.addCategory(category);
        production.addSubcategory(subcategory);

        return <ProductsContainer error={error}>
            <ProductsContent noHeader>
                <ProductsAside
                    name={name}
                    results={data.results}
                    crumbs={production.crumbs}
                    sort={sort}
                    categories={production.categories}
                    category={production.category}
                    subcategories={production.subcategories}
                    subcategory={production.subcategory}
                    types={data.types}
                    type={type}
                    prices={data.prices}
                    price={price}
                    baseUrl={production.baseUrl}
                    catUrl={production.catUrl}
                    subUrl={production.subUrl}
                    withType
                />
                <ProductsList
                    products={data.products}
                    page={page}
                    pages={data.pages}
                    url={production.fullUrl}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchHistory : PropTypes.func.isRequired,
        data         : PropTypes.object.isRequired,
        error        : PropTypes.bool.isRequired,
        match        : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data  : state.product.history,
            error : state.product.error,
        };
    }
}

export default connect(HistoryPage.mapStateToProps, {
    fetchHistory,
})(HistoryPage);
