import API                  from "Utils/App/API";

// Types
import {
    PRODUCT_SEARCH, PRODUCT_NEWESTS, PRODUCT_FAVORITES, PRODUCT_HISTORY,
} from "Utils/Types";



/**
 * Fetches the Search Results
 * @param {Object} params
 * @returns {Function}
 */
export function fetchSearch(params) {
    return async (dispatch) => {
        const data = await API.Product.getSearch(params);
        dispatch({ type : PRODUCT_SEARCH, data });
    };
}

/**
 * Fetches the Newest Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchNewests(params) {
    return async (dispatch) => {
        const data = await API.Product.getNewests(params);
        dispatch({ type : PRODUCT_NEWESTS, data });
    };
}

/**
 * Fetches the Favorite Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchFavorites(params) {
    return async (dispatch) => {
        const data = await API.Product.getFavorites(params);
        dispatch({ type : PRODUCT_FAVORITES, data });
    };
}

/**
 * Fetches the History Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchHistory(params) {
    return async (dispatch) => {
        const data = await API.Product.getHistory(params);
        dispatch({ type : PRODUCT_HISTORY, data });
    };
}
