import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Bike Filter
 */
class BikeFilter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, selected, getUrl, onChange } = this.props;
        const total = data.reduce((res, elem) => res + elem.total, 0);

        return <div className="aside-options">
            <h4>{NLS.get("BIKES_NAME")}</h4>
            <ul className="products-option no-list">
                <li>
                    <HyperLink
                        variant={!selected ? "red" : "gray"}
                        href={getUrl()}
                        onClick={onChange}
                    >
                        {NLS.get("BIKES_ALL")}
                        <span className="aside-amount">({total})</span>
                    </HyperLink>
                </li>
                {data.map((elem) => <li key={elem.bikeID}>
                    <HyperLink
                        variant={selected === elem.hash ? "red" : "gray"}
                        href={getUrl(elem.hash)}
                        onClick={onChange}
                    >
                        {elem.name}
                        <span className="aside-amount">({elem.total})</span>
                    </HyperLink>
                </li>)}
            </ul>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data     : PropTypes.array.isRequired,
        selected : PropTypes.string,
        getUrl   : PropTypes.func.isRequired,
        onChange : PropTypes.func.isRequired,
    }
}

export default BikeFilter;
