import API                  from "Utils/App/API";

// Types
import {
    BRAND_LIST, BRAND_DATA,
} from "Utils/Types";



/**
 * Fetches the Brand List
 * @returns {Function}
 */
export function fetchBrands() {
    return async (dispatch) => {
        const data = await API.Brand.getBrands();
        dispatch({ type : BRAND_LIST, data });
    };
}

/**
 * Fetches the Brand Data
 * @param {Object} params
 * @returns {Function}
 */
export function fetchBrand(params) {
    return async (dispatch) => {
        const data = await API.Brand.getBrand(params);
        dispatch({ type : BRAND_DATA, data });
    };
}
