// Types
import {
    QUERY_LIST, QUERY_DATA, QUERY_CREATE, QUERY_CREATED,
} from "Utils/Types";



// The initial State
const initialState = {
    error   : false,
    queries : {
        list  : [],
        total : 0,
    },
    query   : {
        queryID : 0,
        replies : [],
    },
    create  : {
        types : [],
    },
    created : {
        queryID : 0,
        success : "",
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case QUERY_LIST:
        return {
            ...state,
            queries : action.data,
        };
    case QUERY_DATA:
        return {
            ...state,
            query : action.data,
        };
    case QUERY_CREATE:
        return {
            ...state,
            create : action.data,
        };
    case QUERY_CREATED:
        return {
            ...state,
            created : action.data,
        };
    default:
        return state;
    }
};
