import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import DialogContent        from "./DialogContent";
import DialogFooter         from "./DialogFooter";
import Dialog               from "Components/Utils/Dialog/Dialog";
import Button               from "Components/Utils/Form/Button";



/**
 * The Image Dialog
 */
class ImageDialog extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, message, image, onClose } = this.props;

        return <Dialog
            open={open}
            message={message}
            icon="image"
            className="dialog-image"
            onClose={onClose}
            hasFooter
        >
            <DialogContent>
                <img src={image} alt={NLS.get(message)} />
            </DialogContent>
            <DialogFooter>
                <Button
                    variant="cancel"
                    message="GENERAL_CANCEL"
                    onClick={onClose}
                />
            </DialogFooter>
        </Dialog>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open    : PropTypes.bool.isRequired,
        message : PropTypes.string.isRequired,
        image   : PropTypes.string.isRequired,
        onClose : PropTypes.func.isRequired,
    }
}

export default ImageDialog;
