import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Styles
import "Styles/Components/Utils/Common/Timer.css";




/**
 * The Timer
 */
class Timer extends React.Component {
    // The Current State
    state = {
        interval : null,
        now      : new Date(),
    }

    /**
     * Starts the Timeout
     * @returns {Void}
     */
    componentDidMount() {
        this.setState({
            interval : window.setInterval(() => this.setState({ now : new Date() }), 1000),
        });
    }

    /**
     * End the Timeouts
     * @returns {Void}
     */
    componentWillUnmount() {
        if (this.state.interval) {
            window.clearInterval(this.state.interval);
        }
        this.setState({
            interval : null,
        });
    }



    /**
     * Calculates the Times
     * @returns {Object}
     */
    calculateTimes() {
        const { startTime, endTime } = this.props;

        const now   = this.state.now;
        const start = new Date(startTime * 1000);
        const end   = new Date(endTime   * 1000);
        const diff  = start > now ? start - now : end - now;

        let msec      = diff;
        const days    = Math.floor(msec / 1000 / 60 / 60 / 24);
        if (days > 3) {
            msec     -= days * 1000 * 60 * 60 * 24;
        }

        const hours   = Math.floor(msec / 1000 / 60 / 60);
        msec         -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(msec / 1000 / 60);
        msec         -= minutes * 1000 * 60;
        const seconds = Math.floor(msec / 1000);

        return { days, hours, minutes, seconds };
    }

    /**
     * Adds Elements between the Time digits
     * @param {Number} time
     * @returns {Array.<Object>}
     */
    splitDigits(time) {
        const str = (time < 10 ? "0" : "") + String(time);
        return str.split("").map((number, index) => <div key={index}>{number}</div>);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { useShort } = this.props;
        const { days, hours, minutes, seconds } = this.calculateTimes();

        return <div className={`timer-container ${this.props.className}`}>
            {days > 3 && <>
                <div className="timer-time">
                    <div className="timer-numbers">
                        {this.splitDigits(days)}
                    </div>
                    <div className="timer-name">
                        {NLS.get("GENERAL_DAYS")}
                    </div>
                </div>
                <div className="timer-dots">:</div>
            </>}
            <div className="timer-time">
                <div className="timer-numbers">
                    {this.splitDigits(hours)}
                </div>
                <div className="timer-name">
                    {NLS.get("GENERAL_HOURS")}
                </div>
            </div>
            <div className="timer-dots">:</div>
            <div className="timer-time">
                <div className="timer-numbers">
                    {this.splitDigits(minutes)}
                </div>
                <div className="timer-name">
                    {NLS.get(`GENERAL_MINUTES${useShort ? "_SHORT" : ""}`)}
                </div>
            </div>
            <div className="timer-dots">:</div>
            <div className="timer-time">
                <div className="timer-numbers">
                    {this.splitDigits(seconds)}
                </div>
                <div className="timer-name">
                    {NLS.get(`GENERAL_SECONDS${useShort ? "_SHORT" : ""}`)}
                </div>
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className : PropTypes.string,
        startTime : PropTypes.number.isRequired,
        endTime   : PropTypes.number.isRequired,
        useShort  : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        useShort  : false,
    }
}

export default Timer;
