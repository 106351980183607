import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { recover }          from "Actions/Core/AuthActions";
import NLS                  from "Utils/App/NLS";

// Components
import EditDialog           from "Components/Utils/Dialog/EditDialog";
import TextField            from "Components/Utils/Form/TextField";
import Alert                from "Components/Utils/Form/Alert";



/**
 * The Login Dialog
 */
class LoginDialog extends React.Component {
    // The Current State
    state = {
        data    : { identifier : "" },
        loading : false,
        success : "",
        errors  : {},
        error   : "",
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, success : "", errors : {}, error : "" });
        try {
            const success = await this.props.recover(data);
            this.setState({ loading : false, success, data : { identifier : "" } });
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.closeAlert();
        this.props.onClose();
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { open                                  } = this.props;
        const { data, loading, success, errors, error } = this.state;

        return <EditDialog
            open={open}
            className="auth-dialog"
            message="AUTH_RECOVER_USER"
            icon="account"
            isDisabled={loading}
            onSubmit={this.handleSubmit}
            onClose={this.closeDialog}
            primary="AUTH_RECOVER_BTN"
            hasPrimary
            withSpacing
        >
            <Alert variant="success" message={success} onClose={this.closeAlert} />
            <Alert variant="error"   message={error}   onClose={this.closeAlert} />

            <div className="auth-dialog-content">
                <h3>{NLS.get("AUTH_RECOVER_TEXT")}</h3>
                <TextField
                    name="identifier"
                    label="AUTH_IDENTIFIER"
                    value={data.identifier}
                    error={errors.identifier}
                    onChange={this.handleChange}
                    isRequired
                    shrink
                />
            </div>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        recover : PropTypes.func.isRequired,
        open    : PropTypes.bool.isRequired,
        onClose : PropTypes.func.isRequired,
    }
}

export default connect(null, {
    recover,
})(LoginDialog);
