import React                from "react";
import ReactDOM             from "react-dom";
import PropTypes            from "prop-types";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Utils/Common/Dialog.css";



/**
 * The Dialog
 */
class Dialog extends React.Component {
    // The Current State
    state = {
        closing : false,
    }

    // References
    dialogRef = React.createRef();



    /**
     * Close the Dialog
     * @returns {Void}
     */
    componentWillUnmount() {
        document.body.classList.remove("dialog-noscroll");
    }

    /**
     * Close / Open the Dialog
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            if (this.props.open === true) {
                document.body.classList.add("dialog-noscroll");
            } else {
                document.body.classList.remove("dialog-noscroll");
            }
        }
    }

    /**
     * Handles the Dialog Backdrop Close
     * @param {Event} e
     * @returns {Void}
     */
    handleBackdrop = (e) => {
        if (this.dialogRef && this.dialogRef.current) {
            const node = ReactDOM.findDOMNode(this.dialogRef.current);
            if (node) {
                const bounds = node.getBoundingClientRect();
                if (e.clientX < bounds.left || e.clientX > bounds.right ||
                    e.clientY < bounds.top  || e.clientY > bounds.bottom) {
                    this.closeDialog();
                }
            }
        }
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        if (this.state.closing) {
            return;
        }
        this.setState({ closing : true });
        window.setTimeout(() => {
            this.setState({ closing : false });
            this.props.onClose();
        }, 200);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, hasFooter, className, message, icon, onClose, children } = this.props;
        const { closing } = this.state;

        return <div
            className={
                "dialog-backdrop" +
                (open      ? " dialog-open"        : "") +
                (closing   ? " dialog-closing"     : "") +
                (hasFooter ? " dialog-with-footer" : "")
            }
            onClick={this.handleBackdrop}
        >
            <Card className={`dialog-container ${className}`} ref={this.dialogRef}>
                <SubTitle className="dialog-header" message={message} icon={icon}>
                    <HyperLink
                        className="dialog-close"
                        variant="icon"
                        icon="close"
                        onClick={onClose}
                    />
                </SubTitle>
                {children}
            </Card>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open      : PropTypes.bool,
        hasFooter : PropTypes.bool,
        className : PropTypes.string,
        message   : PropTypes.string,
        icon      : PropTypes.string,
        onClose   : PropTypes.func.isRequired,
        children  : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        open      : false,
        hasFooter : false,
        className : "",
        message   : "",
        icon      : "",
    }
}

export default Dialog;
