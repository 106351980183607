import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Dialog Content
 */
class DialogContent extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { className, withSpacing, children } = this.props;

        return <main className={`dialog-content ${className}` + (withSpacing ? " spacing" : "")}>
            {children}
        </main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className   : PropTypes.string,
        withSpacing : PropTypes.bool,
        children    : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        withSpacing : false,
    }
}

export default DialogContent;
