import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Utils/Common/SubTitle.css";



/**
 * The SubTitle
 */
class SubTitle extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { show, className, message, href, icon, children, withTop } = this.props;

        if (!show) {
            return <React.Fragment />;
        }
        return <header className={`subtitle ${className} ${withTop ? "subtitle-top" : ""}`}>
            <div className="subtitle-content">
                {!!icon && <Icon variant={icon} />}
                <h3>
                    {href ? <HyperLink
                        href={href}
                        message={message}
                    /> : NLS.get(message)}
                </h3>
            </div>
            {children}
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        show      : PropTypes.bool,
        className : PropTypes.string,
        message   : PropTypes.string,
        href      : PropTypes.string,
        icon      : PropTypes.string,
        withTop   : PropTypes.bool,
        children  : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        show      : true,
        className : "",
        message   : "GENERAL_LOADING",
        withTop   : false,
    }
}

export default SubTitle;
