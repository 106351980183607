// Types
import {
    CLIENT_BANKS, CLIENT_ACCOUNT, CLIENT_PAGE,
} from "Utils/Types";



// The initial State
const initialState = {
    error   : false,
    banks   : [],
    account : {},
    page    : 0,
    type    : "",
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case CLIENT_BANKS:
        return {
            ...state,
            banks : action.data,
        };
    case CLIENT_ACCOUNT:
        return {
            ...state,
            account : action.data,
        };

    case CLIENT_PAGE:
        return {
            ...state,
            page : action.data.page,
            type : action.data.type || "",
        };
    default:
        return state;
    }
};
