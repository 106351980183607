import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Styles
import "Styles/Components/Product/Item/ProductPrice.css";



/**
 * The Product Price
 */
class ProductPrice extends React.Component {
    /**
     * Renders the Price
     * @returns {Object}
     */
    renderPrice() {
        const {
            currencySymbol, priceFormat,
            discountPrice, discountPercent,
            discountOffer, isNew,
        } = this.props.data;

        const hasDiscount = discountPrice && Boolean(Number(String(discountPrice).replace(",", ".")));
        const hasOffer    = !discountOffer && hasDiscount;
        const symbol      = <span className="product-price-symbol">{currencySymbol}</span>;

        // An Offer sign and the Price
        if (hasOffer) {
            return <div className="product-price-cnt">
                <div className="product-price-offer">
                    <span>★</span> {NLS.get("PRODUCTS_IN_OFFER")}
                </div>
                <div className="product-price-new">
                    <div className="product-new-value">
                        {symbol} {priceFormat}
                    </div>
                </div>
            </div>;
        }

        // Price and Discount
        if (hasDiscount) {
            return <div className="product-price-cnt">
                <div className="product-price-old">
                    {symbol} {priceFormat}
                </div>
                <div className="product-price-new">
                    <div className="product-new-value">
                        {symbol} {discountPrice}
                    </div>
                    <div className="product-discount">
                        {NLS.format("PRODUCTS_DISCOUNT", discountPercent)}
                    </div>
                </div>
            </div>;
        }

        // A New sign and the Price
        if (isNew) {
            return <div className="product-price-cnt">
                <div className="product-price-offer">
                    {NLS.get("PRODUCTS_IS_NEW")}
                </div>
                <div className="product-price-new">
                    <div className="product-new-value">
                        {symbol} {priceFormat}
                    </div>
                </div>
            </div>;
        }

        // Just the Price
        return <div className="product-price-cnt">
            {symbol} {priceFormat}
        </div>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, className } = this.props;
        const { semaphoreColor  } = data;
        const hasSemaphore = Boolean(semaphoreColor);

        return <div className={`product-price ${className}`}>
            {this.renderPrice()}
            {hasSemaphore && <div className="product-semaphore" style={{ backgroundColor : semaphoreColor }} />}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data      : PropTypes.object.isRequired,
        className : PropTypes.string,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }
}

export default ProductPrice;
