import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setPage }          from "Actions/Client/ClientActions";
import { fetchQueries }     from "Actions/Client/QueryActions";
import Utils                from "Utils/Common/Utils";

// Styles
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";
import Button               from "Components/Utils/Form/Button";



/**
 * The Query List
 */
class QueryList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchQueries(params);
        this.props.setPage(params.page, params.type);
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchQueries(newParams);
            this.props.setPage(newParams.page, newParams.type);
        }
    }

    /**
     * Returns the Params
     * @param {Object} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        return {
            type : params.type         || "abiertas",
            page : Number(params.page) || 1,
        };
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isTablet, list, total } = this.props;
        const { type, page            } = this.getParams();

        const baseUrl   = "/cuenta/consultas";
        const openType  = type === "abiertas" ? "border" : "cancel";
        const closeType = type !== "abiertas" ? "border" : "cancel";

        return <Card className="client-card">
            <SubTitle show={!isTablet} message="QUERIES_NAME" icon="query" />
            <header className="client-header queries-header">
                <div className="client-tabs">
                    <Button variant={openType}  message="QUERIES_OPEN"   href={`${baseUrl}/abiertas`} />
                    <Button variant={closeType} message="QUERIES_CLOSED" href={`${baseUrl}/cerradas`} />
                </div>
                <div className="client-create">
                    <Button variant="primary"   message="QUERIES_CREATE" href={`${baseUrl}/crear`} />
                </div>
            </header>
            <section className="queries-content client-table spacing">
                <Table hasContent={!!list.length} none="QUERIES_NONE_AVAILABLE">
                    <TableHead>
                        <TableCell message="QUERIES_SUBJECT"    />
                        <TableCell message="QUERIES_TYPE"       />
                        <TableCell message="QUERIES_ASSIGNED"   />
                        <TableCell message="QUERIES_LAST_REPLY" />
                        <TableCell message="QUERIES_READING"    />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.queryID} href={`/cuenta/consulta/${elem.queryID}`}>
                            <TableCell
                                isLink
                                href={`/cuenta/consulta/${elem.queryID}`}
                                message={elem.subject}
                                breakAfter
                            />
                            <TableCell message={elem.queryTypeName} />
                            <TableCell message={elem.credentialName} breakAfter />
                            <TableCell
                                className="table-terciary table-nowrap"
                                message={elem.lastReplyFull}
                            />
                            <TableCell
                                isStatus
                                condition={!elem.unread}
                                success="QUERIES_READ"
                                error="QUERIES_UNREAD"
                            />
                        </TableRow>)}
                    </TableBody>
                </Table>
                <Pagination
                    current={page}
                    total={total}
                    url={`${baseUrl}/${type}`}
                />
            </section>
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setPage      : PropTypes.func.isRequired,
        fetchQueries : PropTypes.func.isRequired,
        match        : PropTypes.object.isRequired,
        isTablet     : PropTypes.bool.isRequired,
        list         : PropTypes.array.isRequired,
        total        : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isTablet : state.core.isTablet,
            list     : state.query.queries.list,
            total    : state.query.queries.total,
        };
    }
}

export default connect(QueryList.mapStateToProps, {
    setPage, fetchQueries,
})(QueryList);
