import API                  from "Utils/App/API";

// Types
import {
    NOTIFICATION_LIST, NOTIFICATION_DISCARDED, NOTIFICATION_REDIRECT,
} from "Utils/Types";



/**
 * Sets the Notification Redirect
 * @param {String} data
 * @returns {{type: String, data: String}}
 */
export function getRedirect(data) {
    return { type : NOTIFICATION_REDIRECT, data };
}

/**
 * Sets the Notification Redirect
 * @param {String} data
 * @returns {Function}
 */
export function setRedirect(data) {
    return (dispatch) => dispatch(getRedirect(data));
}



/**
 * Fetches the Notifications
 * @param {Number} time
 * @returns {Function}
 */
export function fetchNotifications(time) {
    return async (dispatch) => {
        const result = await API.Notification.getNotifications({ time });
        const data   = result.notification;
        dispatch({ type : NOTIFICATION_LIST, data });
        return data;
    };
}

/**
 * Discards a Notification
 * @param {String} type
 * @param {Number} id
 * @returns {Function}
 */
export function discardNotification(type, id) {
    return async (dispatch) => {
        API.Notification.discardNotification({ type, id });
        dispatch({ type : NOTIFICATION_DISCARDED });
    };
}
