import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { addProduct }       from "Actions/Store/CartActions";
import NLS                  from "Utils/App/NLS";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Dialog               from "Components/Utils/Dialog/Dialog";
import DialogContent        from "Components/Utils/Dialog/DialogContent";
import Alert                from "Components/Utils/Form/Alert";
import NumberField          from "Components/Utils/Form/NumberField";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    toggleFavorite, addToHistory,
} from "Actions/Store/StoreActions";

// Styles
import "Styles/Components/Product/Item/ProductDialog.css";



/**
 * The Product Dialog
 */
class ProductDialog extends React.Component {
    // The Current State
    state = {
        data       : { amount : 1 },
        discount   : 1,
        isFavorite : false,
        loading    : false,
        success    : "",
        errors     : {},
        error      : "",
    }



    /**
     * Unsets the State if the Product is new
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { data, addToHistory } = this.props;
        if (data.productCode !== prevProps.data.productCode && data.productCode) {
            this.setState({
                data       : { amount : 1 },
                discount   : 1,
                isFavorite : data.isFavorite,
                loading    : false,
                success    : "",
                errors     : {},
                error      : "",
            });
            addToHistory(data.productCode);
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }
        
        this.setState({ loading : true, success : "", error : "" });
        try {
            const success = await this.props.addProduct({
                productCode : this.props.data.productCode,
                amount      : data.amount,
            });
            this.setState({ loading : false, success });
        } catch (errors) {
            this.setState({ loading : false, error : errors.form });
        }
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleFavorite = async (e) => {
        e.preventDefault();
        const { data, toggleFavorite } = this.props;
        if (this.state.loading) {
            return;
        }
        this.setState({ loading : true, error : "" });
        try {
            await toggleFavorite(data.productCode);
            this.setState({ loading : false, isFavorite : !this.state.isFavorite });
        } catch (error) {
            this.setState({ loading : false, error });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }



    /**
     * Sets the Discount value
     * @param {Number} discount
     * @returns {Void}
     */
    setDiscount = (discount) => {
        this.setState({ discount });
    }

    /**
     * Returns the Price data
     * @returns {Object}
     */
    getPrice() {
        const data     = this.props.data;
        const discount = this.state.discount - 1;
        const result   = {
            currencySymbol  : data.currencySymbol,
            priceFormat     : data.priceFormat,
            semaphoreColor  : data.semaphoreColor,
            discountPrice   : 0,
            discountPercent : 0,
            discountOffer   : false,
            discountName    : "",
        };
        if (data.discounts && data.discounts[discount]) {
            result.discountOffer   = true;
            result.discountPrice   = data.discounts[discount].price;
            result.discountPercent = data.discounts[discount].percent;
        }
        return result;
    }



    /**
     * Renders the Footer
     * @returns {Array.<Object>}
     */
    renderFooter() {
        const { data, loading, success, error } = this.state;
        const { onClose                       } = this.props;

        if (success) {
            return <>
                <h3 className="product-success">{NLS.get(success)}</h3>
                <Button
                    variant="primary"
                    className="product-dialog-button"
                    message="PRODUCTS_SHOW_TO_CART"
                    href="/compras"
                    onClick={onClose}
                    fullWidth
                />
            </>;
        }

        return <>
            <Alert variant="error" message={error} onClose={this.closeAlert} />
            <div className="product-dialog-amount">
                <h3>{NLS.get("PRODUCTS_ADD_AMOUNT")}</h3>
                <NumberField
                    name="amount"
                    value={data.amount}
                    onChange={this.handleChange}
                    onInput
                />
            </div>
            <Button
                variant="primary"
                className="product-dialog-button"
                message="PRODUCTS_ADD_TO_CART"
                onClick={this.handleSubmit}
                isDisabled={loading}
                fullWidth
            />
        </>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, data, onClose      } = this.props;
        const { isFavorite               } = this.state;
        const { productCode, description } = data;
        
        const hasDiscounts = data.discounts && data.discounts.length > 0;
        const priceData    = this.getPrice();
        const discount     = this.state.discount;

        return <Dialog
            open={open}
            className="product-dialog"
            message="PRODUCTS_ADD_TO_CART"
            icon="product"
            onClose={onClose}
        >
            <DialogContent className="product-dialog-container">
                <div className="product-dialog-content spacing">
                    <ProductImage className="product-dialog-image" data={data} />
                    <div className="product-dialog-info">
                        <h4>{productCode}</h4>
                        <h3>{description}</h3>
                        <ProductPrice className="product-dialog-price" data={priceData} />
                        {hasDiscounts && <ul className="product-dialog-discounts no-list">
                            {data.discounts.map((elem) => <li
                                key={elem.key}
                                className={elem.key === discount ? "product-dialog-selected" : ""}
                                onClick={() => this.setDiscount(elem.key)}
                            >
                                {elem.name}
                            </li>)}
                        </ul>}
                    </div>
                    <HyperLink
                        className="product-dialog-favorite"
                        variant="icon"
                        icon={isFavorite ? "favorite" : "unfavorite"}
                        onClick={this.handleFavorite}
                    />
                </div>
                <div className="product-dialog-footer spacing">
                    {this.renderFooter()}
                    <HyperLink
                        variant="black"
                        message="PRODUCTS_BUY_MORE"
                        onClick={onClose}
                    />
                </div>
            </DialogContent>
        </Dialog>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        addProduct     : PropTypes.func.isRequired,
        toggleFavorite : PropTypes.func.isRequired,
        addToHistory   : PropTypes.func.isRequired,
        open           : PropTypes.bool.isRequired,
        data           : PropTypes.object.isRequired,
        onClose        : PropTypes.func.isRequired,
    }
}

export default connect(null, {
    addProduct, toggleFavorite, addToHistory,
})(ProductDialog);
