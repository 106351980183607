import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchBike }        from "Actions/Store/BikeActions";
import Production           from "Utils/Common/Production";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsHeader       from "Components/Product/List/ProductsHeader";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsAside        from "Components/Product/List/ProductsAside";
import ProductsList         from "Components/Product/List/ProductsList";



/**
 * The Bike Page
 */
class BikePage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchBike(params);
    }

    /**
     * Load the Data if the Bike changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchBike(newParams);
        }
    }

    /**
     * Returns the Params for the Fetch
     * @param {Object=} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        const initials = Production.getInitialParams("bike");
        return Production.getParams(initials, params);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, error } = this.props;
        const { bike, category, subcategory, page, type, price, sort } = this.getParams();
        const production   = new Production(data, `/motos/${bike}`, type, price, sort);
        const cylinderUrl  = `/cilindradas/${data.cylinderUrl}`;
        const cylinderName = NLS.format("BIKES_CYLINDER", data.cylinderName);

        production.addCrumb("/cilindradas", "BIKES_TITLE");
        production.addCrumb(cylinderUrl, cylinderName);
        production.addCrumb(production.fullUrl, data.name);
        production.addCategory(category);
        production.addSubcategory(subcategory);

        return <ProductsContainer error={error}>
            <ProductsHeader
                className="cylinders-header"
                banner={data.banner}
                url={production.url}
            />
            <ProductsContent noHeader>
                <ProductsAside
                    name={data.name}
                    results={data.results}
                    crumbs={production.crumbs}
                    sort={sort}
                    bikes={data.bikes}
                    bike={bike}
                    categories={production.categories}
                    category={production.category}
                    subcategories={production.subcategories}
                    subcategory={production.subcategory}
                    types={data.types}
                    type={type}
                    prices={data.prices}
                    price={price}
                    baseUrl={production.baseUrl}
                    catUrl={production.catUrl}
                    subUrl={production.subUrl}
                    cylinderUrl={cylinderUrl}
                    bikeUrl="/motos"
                    withBike
                    withType
                />
                <ProductsList
                    products={data.products}
                    page={page}
                    pages={data.pages}
                    url={production.fullUrl}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchBike : PropTypes.func.isRequired,
        data      : PropTypes.object.isRequired,
        error     : PropTypes.bool.isRequired,
        match     : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data  : state.bike.bike,
            error : state.bike.error,
        };
    }
}

export default connect(BikePage.mapStateToProps, {
    fetchBike,
})(BikePage);
