import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchSearch }      from "Actions/Store/ProductActions";
import Production           from "Utils/Common/Production";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsAside        from "Components/Product/List/ProductsAside";
import ProductsList         from "Components/Product/List/ProductsList";



/**
 * The Search Page
 */
class SearchPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchSearch(params);
    }

    /**
     * Load the Data if the Search changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchSearch(newParams);
        }
    }

    /**
     * Returns the Params for the Fetch
     * @param {Object=} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        const initials = Production.getInitialParams("search");
        return Production.getParams(initials, params);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, error } = this.props;
        const { search, category, subcategory, page, type, price, sort } = this.getParams();
        const production = new Production(data, `/buscar/${search}`, type, price, sort);
        const name       = NLS.format("SEARCH_TITLE", data.name);

        production.addCrumb("/", "GENERAL_HOME");
        production.addCrumb(production.fullUrl, "SEARCH_RESULTS");
        production.addCategory(category);
        production.addSubcategory(subcategory);

        return <ProductsContainer error={error}>
            <ProductsContent noHeader>
                <ProductsAside
                    name={name}
                    results={data.results}
                    crumbs={production.crumbs}
                    sort={sort}
                    categories={production.categories}
                    category={production.category}
                    subcategories={production.subcategories}
                    subcategory={production.subcategory}
                    types={data.types}
                    type={type}
                    prices={data.prices}
                    price={price}
                    baseUrl={production.baseUrl}
                    catUrl={production.catUrl}
                    subUrl={production.subUrl}
                    withType
                />
                <ProductsList
                    products={data.products}
                    page={page}
                    pages={data.pages}
                    url={production.fullUrl}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchSearch : PropTypes.func.isRequired,
        data        : PropTypes.object.isRequired,
        error       : PropTypes.bool.isRequired,
        match       : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data  : state.product.search,
            error : state.product.error,
        };
    }
}

export default connect(SearchPage.mapStateToProps, {
    fetchSearch,
})(SearchPage);
