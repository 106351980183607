import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchFavorites }   from "Actions/Store/ProductActions";
import Production           from "Utils/Common/Production";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsAside        from "Components/Product/List/ProductsAside";
import ProductsList         from "Components/Product/List/ProductsList";
import Nothing              from "Components/Utils/Common/Nothing";



/**
 * The Favorite Page
 */
class FavoritePage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchFavorites(params);
    }

    /**
     * Load the Data if the Favorite changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);

        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchFavorites(newParams);
        }
    }

    /**
     * Returns the Params for the Fetch
     * @param {Object=} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        const initials = Production.getInitialParams();
        return Production.getParams(initials, params);
    }



    /**
     * Handles Product Favorite update
     * @returns {Void}
     */
    handleFavorite = () => {
        const params = this.getParams();
        this.props.fetchFavorites(params);
    }

    /**
     * Handles Product Dialog close
     * @param {Object} product
     * @returns {Void}
     */
    handleClose = (product) => {
        if (!product.isFavorite) {
            const params = this.getParams();
            this.props.fetchFavorites(params);
        }
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, error } = this.props;
        const { category, subcategory, page, type, price, sort } = this.getParams();

        if (!data.products.length) {
            return <Nothing
                className="products-none"
                message="FAVORITES_NONE_AVAILABLE"
                withWrapper
            />;
        }

        const production = new Production(data, "/favoritos", type, price, sort);
        const name       = NLS.format("FAVORITES_TITLE");

        production.addCrumb(production.fullUrl, "FAVORITES_NAME");
        production.addCategory(category);
        production.addSubcategory(subcategory);

        return <ProductsContainer error={error}>
            <ProductsContent noHeader>
                <ProductsAside
                    name={name}
                    results={data.results}
                    crumbs={production.crumbs}
                    sort={sort}
                    categories={production.categories}
                    category={production.category}
                    subcategories={production.subcategories}
                    subcategory={production.subcategory}
                    types={data.types}
                    type={type}
                    prices={data.prices}
                    price={price}
                    baseUrl={production.baseUrl}
                    catUrl={production.catUrl}
                    subUrl={production.subUrl}
                    withType
                />
                <ProductsList
                    products={data.products}
                    page={page}
                    pages={data.pages}
                    url={production.fullUrl}
                    onFavorite={this.handleFavorite}
                    onClose={this.handleClose}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchFavorites : PropTypes.func.isRequired,
        data           : PropTypes.object.isRequired,
        error          : PropTypes.bool.isRequired,
        match          : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data  : state.product.favorites,
            error : state.product.error,
        };
    }
}

export default connect(FavoritePage.mapStateToProps, {
    fetchFavorites,
})(FavoritePage);
