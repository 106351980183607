import React                from "react";
import ReactDOM             from "react-dom";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { login }            from "Actions/Core/AuthActions";
import { setRedirect }      from "Actions/Content/NotificationActions";

// Components
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";
import Alert                from "Components/Utils/Form/Alert";



/**
 * The Login Page
 */
class LoginForm extends React.Component {
    // The Current State
    state = {
        data : {
            identifier : "",
            password   : "",
            isApp      : this.props.isApp ? 1 : 0,
        },
        loading : false,
        errors  : {},
        error   : "",
    }

    // References
    inputRef = React.createRef();



    /**
     * Focus on first Input
     * @returns {Void}
     */
    componentDidMount() {
        if (this.inputRef && this.inputRef.current) {
            const node = ReactDOM.findDOMNode(this.inputRef.current);
            node.focus();
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { history, redirect, notiRedirect, login, setRedirect } = this.props;
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, errors : {}, error : "" });
        try {
            await login(data);
            if (notiRedirect) {
                history.push(notiRedirect);
                setRedirect("");
            } else {
                history.push(redirect);
            }
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, errors, error } = this.state;

        return <form className="auth-form" onSubmit={this.handleSubmit}>
            <Alert variant="error" message={error} onClose={this.closeAlert} />

            <TextField
                name="identifier"
                label="AUTH_IDENTIFIER"
                value={data.identifier}
                error={errors.identifier}
                onChange={this.handleChange}
                ref={this.inputRef}
                autoComplete
                isRequired
                withMargin
            />
            <TextField
                name="password"
                type="password"
                label="AUTH_PASSWORD"
                value={data.password}
                error={errors.password}
                onChange={this.handleChange}
                autoComplete
                isRequired
                withMargin
            />
            <Button
                variant="primary"
                message="AUTH_NAME"
                isDisabled={loading}
                fullWidth
            />
        </form>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history      : PropTypes.object.isRequired,
        login        : PropTypes.func.isRequired,
        setRedirect  : PropTypes.func.isRequired,
        redirect     : PropTypes.string.isRequired,
        notiRedirect : PropTypes.string.isRequired,
        isApp        : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            notiRedirect : state.notification.redirect,
            isApp        : state.core.isApp,
        };
    }
}

export default withRouter(connect(LoginForm.mapStateToProps, {
    login, setRedirect,
})(LoginForm));
