// Types
import {
    CORE_SETTINGS, CORE_PLATFORM, CORE_MOBILE,
    CORE_LOADING, CORE_REDIRECT, CORE_SECTION, CORE_RESULT,
} from "Utils/Types";



// The initial State
const initialState = {
    isApp     : false,
    isCordova : false,
    isMobile  : false,
    isTablet  : false,
    platform  : "browser",

    loading   : true,
    redirect  : "/",
    section   : "",
    result    : {
        open    : false,
        type    : "",
        message : "",
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    switch (action.type) {
    case CORE_SETTINGS:
        return {
            ...state,
            isApp     : action.data.isApp || action.data.isCordova,
            isCordova : action.data.isCordova,
        };
    case CORE_PLATFORM:
        return {
            ...state,
            platform  : action.data,
        };
    case CORE_MOBILE:
        return {
            ...state,
            isMobile  : action.data.isMobile,
            isTablet  : action.data.isTablet,
        };

    case CORE_LOADING:
        return {
            ...state,
            loading : action.data,
        };
    case CORE_REDIRECT:
        return {
            ...state,
            redirect : action.data,
        };
    case CORE_SECTION:
        return {
            ...state,
            section : action.data,
        };
    case CORE_RESULT:
        return {
            ...state,
            result : action.data,
        };
    default:
        return state;
    }
};
