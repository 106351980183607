import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import ProductCard          from "Components/Product/Item/ProductCard";
import Button               from "Components/Utils/Form/Button";



/**
 * The Home Products
 */
class HomeProducts extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { variant, message, href, list, onClick } = this.props;

        return <div className={`home-products home-${variant}`}>
            <header className="home-header">
                <h2>{NLS.get(message)}</h2>
                <Button variant="primary" href={href} message="HEADER_MORE" />
            </header>
            <div className="home-products-list">
                {list.map((elem) => <ProductCard
                    key={elem.productCode}
                    data={elem}
                    onClick={() => onClick(elem)}
                    hover
                />)}
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        variant  : PropTypes.string.isRequired,
        message  : PropTypes.string.isRequired,
        href     : PropTypes.string.isRequired,
        list     : PropTypes.array.isRequired,
        onClick  : PropTypes.func.isRequired,
    }
}

export default HomeProducts;
