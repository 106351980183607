export const CORE_SETTINGS          = "CORE_SETTINGS";
export const CORE_PLATFORM          = "CORE_PLATFORM";
export const CORE_MOBILE            = "CORE_MOBILE";
export const CORE_LOADING           = "CORE_LOADING";
export const CORE_REDIRECT          = "CORE_REDIRECT";
export const CORE_SECTION           = "CORE_SECTION";
export const CORE_RESULT            = "CORE_RESULT";

export const AUTH_CURRENT_USER      = "AUTH_CURRENT_USER";

export const STORE_HEADER           = "STORE_HEADER";
export const STORE_HOME             = "STORE_HOME";
export const STORE_FAVORITE         = "STORE_FAVORITE";
export const STORE_HISTORY          = "STORE_HISTORY";



// Store Types
export const CATEGORY_LIST          = "CATEGORY_LIST";
export const CATEGORY_DATA          = "CATEGORY_DATA";

export const OFFER_LIST             = "OFFER_LIST";
export const OFFER_DATA             = "OFFER_DATA";

export const BRAND_LIST             = "BRAND_LIST";
export const BRAND_DATA             = "BRAND_DATA";

export const BIKE_CYLINDERS         = "BIKE_CYLINDERS";
export const BIKE_CYLINDER          = "BIKE_CYLINDER";
export const BIKE_DATA              = "BIKE_DATA";

export const PRODUCT_SEARCH         = "PRODUCT_SEARCH";
export const PRODUCT_NEWESTS        = "PRODUCT_NEWESTS";
export const PRODUCT_FAVORITES      = "PRODUCT_FAVORITES";
export const PRODUCT_HISTORY        = "PRODUCT_HISTORY";

export const CART_DATA              = "CART_DATA";
export const CART_COMPLETE          = "CART_COMPLETE";



// Content Types
export const NEWS_LIST              = "NEWS_LIST";
export const NEWS_DATA              = "NEWS_DATA";

export const NOTIFICATION_LIST      = "NOTIFICATION_LIST";
export const NOTIFICATION_DISCARDED = "NOTIFICATION_DISCARDED";
export const NOTIFICATION_REDIRECT  = "NOTIFICATION_REDIRECT";



// Client Types
export const CLIENT_BANKS           = "CLIENT_BANKS";
export const CLIENT_ACCOUNT         = "CLIENT_ACCOUNT";
export const CLIENT_PAGE            = "CLIENT_PAGE";

export const ADDRESS_LIST           = "ADDRESS_LIST";
export const ADDRESS_DATA           = "ADDRESS_DATA";
export const ADDRESS_UNSET          = "ADDRESS_UNSET";

export const VOUCHER_INVOICES       = "VOUCHER_INVOICES";
export const VOUCHER_RECEIPTS       = "VOUCHER_RECEIPTS";
export const VOUCHER_CREDIT_NOTES   = "VOUCHER_CREDIT_NOTES";
export const VOUCHER_DEBIT_NOTES    = "VOUCHER_DEBIT_NOTES";

export const QUERY_LIST             = "QUERY_LIST";
export const QUERY_DATA             = "QUERY_DATA";
export const QUERY_CREATE           = "QUERY_CREATE";
export const QUERY_CREATED          = "QUERY_CREATED";

export const ORDER_LIST             = "ORDER_LIST";
export const ORDER_DATA             = "ORDER_DATA";
export const ORDER_GROUP_LIST       = "ORDER_GROUP_LIST";
export const ORDER_GROUP_DATA       = "ORDER_GROUP_DATA";
