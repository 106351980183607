import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchBrands }      from "Actions/Store/BrandActions";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Images/Store/Brands.jpg";
import "Styles/Components/Store/Brands.css";



/**
 * The Brand List
 */
class BrandList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchBrands();
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { types, lists } = this.props.data;
        const url   = this.props.match.params.type || (types[0] ? types[0].url : null);
        const type  = Utils.getData(types,  "url", url);
        const index = Utils.getIndex(types, "url", url);
        const list  = lists[index] || [];

        return <div className="brands-container">
            <header className="brands-header">
                <h2>{NLS.get("BRANDS_TITLE")}</h2>
            </header>
            <nav className="brands-nav">
                <ul className="no-list">
                    {types.map((elem, index) => <li key={index}>
                        <HyperLink
                            className={url === elem.url ? "brands-selected" : ""}
                            variant="none"
                            href={`/marcas/${elem.url}`}
                            message={elem.name}
                        />
                    </li>)}
                </ul>
            </nav>
            <div className="brands-content">
                <Card>
                    <SubTitle message={type ? type.title : "GENERAL_LOADING"} />
                    <div className="spacing">
                        {list.length > 0 && <ul className="brands-grid no-list">
                            {list.map((elem) => <li key={elem.id}>
                                <HyperLink variant="none" href={`/marcas/${elem.url}`}>
                                    {elem.image
                                        ? <img src={elem.image} alt={elem.name} />
                                        : <h4>{elem.name}</h4>
                                    }
                                </HyperLink>
                            </li>)}
                        </ul>}
                    </div>
                </Card>
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchBrands : PropTypes.func.isRequired,
        data        : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.brand.brands,
        };
    }
}

export default connect(BrandList.mapStateToProps, {
    fetchBrands,
})(BrandList);
