import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import EditDialog           from "Components/Utils/Dialog/EditDialog";
import TextField            from "Components/Utils/Form/TextField";
import Alert                from "Components/Utils/Form/Alert";

// Actions
import {
    fetchAddress, editAddress,
} from "Actions/Client/AddressActions";



/**
 * The Address Dialog
 */
class AddressDialog extends React.Component {
    // The Initial Data
    initialData = {
        addressID   : "",
        description : "",
        address     : "",
        province    : "",
        locality    : "",
        postalCode  : "",
        schedule    : "",
        isDefault   : 0,
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
        error   : "",
    }



    /**
     * Get the Address when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        let data = null;
        if (this.props.open !== prevProps.open) {
            if (this.props.elemID !== prevProps.elemID && !!this.props.elemID) {
                this.props.fetchAddress(this.props.elemID);
            } else {
                data = { ...this.initialData };
            }
        }

        // Elem Updated
        if (this.props.data.addressID !== prevProps.data.addressID) {
            data = Utils.extend(this.initialData, this.props.data);
        }
        if (data) {
            this.setState({ data, loading : false, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, errors : {}, error : "" });
        try {
            const success = await this.props.editAddress(data);
            this.setState({ loading : false });
            this.props.onSubmit(success);
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, data : address } = this.props;
        const { data, loading, errors, error          } = this.state;
        const isEdit = !!elemID;

        return <EditDialog
            open={open}
            className="address-dialog"
            message={isEdit ? "ADDRESSES_EDIT" : "ADDRESSES_CREATE"}
            icon="address"
            isDisabled={loading}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            hasPrimary
            withSpacing
        >
            <Alert variant="error" message={error} onClose={this.closeAlert} />
            {address.isPending && <p className="client-warning">{NLS.get("ADDRESSES_PENDING")}</p>}

            <div className="client-fields">
                <TextField
                    name="description"
                    label="ADDRESSES_DESCRIPTION"
                    value={data.description}
                    error={errors.description}
                    onChange={this.handleChange}
                    isRequired
                />
                <TextField
                    name="address"
                    label="ADDRESSES_ADDRESS"
                    value={data.address}
                    error={errors.address}
                    onChange={this.handleChange}
                    isRequired
                />
                <TextField
                    name="province"
                    label="ADDRESSES_PROVINCE"
                    value={data.province}
                    error={errors.province}
                    onChange={this.handleChange}
                    isRequired
                />

                <TextField
                    name="locality"
                    label="ADDRESSES_LOCALITY"
                    value={data.locality}
                    error={errors.locality}
                    onChange={this.handleChange}
                    isRequired
                />
                <TextField
                    name="postalCode"
                    label="ADDRESSES_POSTAL_CODE"
                    value={data.postalCode}
                    error={errors.postalCode}
                    onChange={this.handleChange}
                    isRequired
                />
                <TextField
                    name="schedule"
                    label="ADDRESSES_SCHEDULE"
                    value={data.schedule}
                    error={errors.schedule}
                    onChange={this.handleChange}
                    isRequired
                />

                <TextField
                    type="checkbox"
                    name="isDefault"
                    label="ADDRESSES_IS_DEFAULT"
                    value={data.isDefault}
                    error={errors.isDefault}
                    onChange={this.handleChange}
                    isRequired
                />
            </div>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAddress : PropTypes.func.isRequired,
        editAddress  : PropTypes.func.isRequired,
        data         : PropTypes.object.isRequired,
        open         : PropTypes.bool,
        elemID       : PropTypes.number,
        onSubmit     : PropTypes.func.isRequired,
        onClose      : PropTypes.func.isRequired,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        open   : false,
        elemID : 0,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.address.address,
        };
    }
}

export default connect(AddressDialog.mapStateToProps, {
    fetchAddress, editAddress,
})(AddressDialog);
