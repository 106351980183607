import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Componets
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Utils/Form/Alert.css";



/**
 * An Alert
 */
class Alert extends React.Component {
    // The Current State
    state = {
        open    : true,
        timeout : null,
    }

    /**
     * Opens when the Component Mounts
     * @returns {Void}
     */
    componentDidMount() {
        this.setState({ open : true, timeout : null });
    }

    /**
     * Reopens the Alert, if required
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.message !== prevProps.message) {
            this.setState({ open : true, timeout : null });
        }
    }

    /**
     * Handles the On Close event
     * @returns {Void}
     */
    onClose = () => {
        if (this.state.timeout) {
            window.clearTimeout(this.state.timeout);
        }
        const timeout = window.setTimeout(this.handleClose, 500);
        this.setState({ timeout });
    }

    /**
     * Handle the Close
     * @returns {Void}
     */
    handleClose = () => {
        if (this.state.timeout) {
            window.clearTimeout(this.state.timeout);
        }
        this.setState({ open : false, timeout : null });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { variant, message } = this.props;
        const { open, timeout    } = this.state;

        if (!message) {
            return <div />;
        }

        return <div
            className={`alert-container alert-${variant} ${timeout ? "alert-closing" : ""}`}
            style={{ "display" : open ? "block" : "none" }}
        >
            <div className="alert-content">
                {NLS.get(message)}
                <Icon
                    className="alert-close"
                    variant="close"
                    onClick={this.onClose}
                />
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        variant : PropTypes.string.isRequired,
        message : PropTypes.string,
        onClose : PropTypes.func,
    }
}

export default Alert;
