import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Route, Redirect }  from "react-router-dom";



/**
 * Only Authenticated Users can access the given Component
 */
class UserRoute extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isAuthenticated, component : Component, ...rest } = this.props;

        return <Route {...rest} render={(props) => (
            isAuthenticated
                ? <Component {...props} />
                : <Redirect to={{ pathname : "/ingresar", state : { from : props.location } }} />
        )} />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isAuthenticated : PropTypes.bool.isRequired,
        component       : PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]).isRequired,
    }
    
    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth && state.auth.isAuthenticated,
        };
    }
}

export default connect(UserRoute.mapStateToProps)(UserRoute);
