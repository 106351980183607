// Types
import {
    NEWS_LIST, NEWS_DATA,
} from "Utils/Types";



// The initial State
const initialState = {
    news : {
        list  : [],
        total : 0,
    },
    new  : {
        title : "GENERAL_LOADING",
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case NEWS_LIST:
        return {
            ...state,
            error  : false,
            news   : action.data,
        };
    case NEWS_DATA:
        return {
            ...state,
            error  : false,
            new    : action.data,
        };
    default:
        return state;
    }
};
