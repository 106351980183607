import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Type Filter
 */
class TypeFilter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, selected, getUrl, onChange } = this.props;
        const options = [
            { id : 0, name : "PRODUCTS_TYPE_ALL",      total : data[0] },
            { id : 1, name : "PRODUCTS_TYPE_NATIONAL", total : data[1] },
            { id : 2, name : "PRODUCTS_TYPE_IMPORTED", total : data[2] },
        ];

        return <div className="aside-options">
            <h4>{NLS.get("PRODUCTS_TYPE")}</h4>
            <ul className="products-option no-list">
                {options.map((elem) => <li key={elem.id}>
                    <HyperLink
                        variant={selected === elem.id ? "red" : "gray"}
                        href={getUrl(elem.id)}
                        onClick={onChange}
                    >
                        {NLS.get(elem.name)}
                        <span className="aside-amount">({elem.total})</span>
                    </HyperLink>
                </li>)}
            </ul>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data     : PropTypes.array.isRequired,
        selected : PropTypes.number.isRequired,
        getUrl   : PropTypes.func.isRequired,
        onChange : PropTypes.func.isRequired,
    }
}

export default TypeFilter;
