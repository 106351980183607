import Utils                from "Utils/Common/Utils";

// Types
import {
    BIKE_CYLINDERS, BIKE_CYLINDER, BIKE_DATA,
    STORE_FAVORITE,
} from "Utils/Types";



// The initial State
const initialState = {
    error     : false,
    cylinders : [],
    cylinder  : {
        name          : "GENERAL_LOADING",
        url           : "",
        categories    : [],
        subcategories : [],
        products      : [],
        results       : 0,
        total         : 0,
        pages         : 1,
    },
    bike      : {
        name          : "GENERAL_LOADING",
        url           : "",
        cylinderName  : "",
        cylinderUrl   : "",
        categories    : [],
        subcategories : [],
        products      : [],
        results       : 0,
        total         : 0,
        pages         : 1,
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case BIKE_CYLINDERS:
        return {
            ...state,
            error     : false,
            cylinders : action.data,
        };
    case BIKE_CYLINDER:
        return {
            ...state,
            error     : false,
            cylinder  : action.data,
        };
    case BIKE_DATA:
        return {
            ...state,
            error     : false,
            bike      : action.data,
        };

    // Update the Favorite
    case STORE_FAVORITE:
        return {
            ...state,
            error     : false,
            cylinder  : Utils.setFavorite(state.cylinder, action.data.productCode),
            bike      : Utils.setFavorite(state.bike,     action.data.productCode),
        };

    default:
        return state;
    }
};
