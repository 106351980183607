import React                from "react";
import PropTypes            from "prop-types";
import { withRouter }       from "react-router";



/**
 * Scrolls to the Top on location change
 */
class ScrollToTop extends React.Component {
    /**
     * Scroll to the Top
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const top = this.getTop();
            this.speed = Math.max(Math.floor(top / 20), 20);
            this.scroll();
        }
    }

    /**
     * Returns the Current Top
     * @returns {Number}
     */
    getTop() {
        const doc = document.documentElement;
        return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    }

    /**
     * Scrolls to the top smoothly
     * @returns {Void}
     */
    scroll = () => {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        const top = this.getTop();
        if (top > 0) {
            window.scrollTo(0, top - this.speed);
            this.timeout = window.setTimeout(this.scroll, 10);
        }
    }
    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return this.props.children;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        location : PropTypes.object,
        children : PropTypes.any,
    }
}
  
export default withRouter(ScrollToTop);
