import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchNews }        from "Actions/Content/NewsActions";
import Utils                from "Utils/Common/Utils";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Pagination           from "Components/Utils/Common/Pagination";

// Styles
import "Styles/Components/Content/News.css";



/**
 * The News List
 */
class NewsList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchNews(params);
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);
        
        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchNews(newParams);
        }
    }

    /**
     * Returns the Params
     * @param {Object} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        return {
            page : Number(params.page) || 1,
        };
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { list, total } = this.props.data;
        const { page        } = this.getParams();
        
        const backUrl = this.props.isApp ? "/cuenta" : "";
        const baseUrl = "/novedades";

        return <Wrapper withSpacing>
            <Title message="NEWS_NAME" href={backUrl} />
            <Card className="news-container">
                {list.map((elem) => <section key={elem.newsID}>
                    <SubTitle message={elem.title}>
                        {elem.createdTimeFull}
                    </SubTitle>
                    <div className="news-content spacing">
                        <div className="news-image">
                            <HyperLink variant="none" href={elem.link || `/novedad/${elem.newsID}`}>
                                <img src={elem.imageUrl} alt={elem.title} />
                            </HyperLink>
                        </div>
                        {elem.descriptionLines.length ? <div className="news-description">
                            {elem.descriptionLines.map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </div> : ""}
                    </div>
                </section>)}
            </Card>
            <Pagination
                current={page}
                total={total}
                url={baseUrl}
            />
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchNews : PropTypes.func.isRequired,
        isApp     : PropTypes.bool.isRequired,
        data      : PropTypes.object.isRequired,
        match     : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp : state.core.isApp,
            data  : state.news.news,
        };
    }
}

export default connect(NewsList.mapStateToProps, {
    fetchNews,
})(NewsList);
