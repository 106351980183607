import Auth                 from "Utils/App/Auth";
import Utils                from "Utils/Common/Utils";

// Actions
import {
    setLoading, showResult,
} from "Actions/Core/CoreActions";

// Module variables
let store      = null;
let longTerm   = false;
let controller = null;



/**
 * Initialize the API
 * @param {Store}   theStore
 * @param {Boolean} isApp
 * @returns {Void}
 */
function init(theStore, isApp) {
    store    = theStore;
    longTerm = isApp;
    store.dispatch(setLoading(false));
}



/**
 * Fetch wrapper
 * @param {(String|URL)} url
 * @param {Object=}      options
 * @param {Boolean=}     showLoader
 * @returns {Object}
 */
async function ajax(url, options = {}, showLoader = true) {
    const defError = { form : "GENERAL_ERROR" };
    let response   = null;
    let result     = null;

    // To be able to Aboert
    if (window.AbortController) {
        controller     = new window.AbortController();
        options.signal = controller.signal;
    }
    
    // Show the Loader
    if (showLoader) {
        store.dispatch(setLoading(true));
    }

    // Do the Request
    try {
        response = await fetch(url, options);
        if (showLoader) {
            store.dispatch(setLoading(false));
        }
    } catch (error) {
        if (showLoader) {
            store.dispatch(setLoading(false));
        }
        throw defError;
    }
    
    // Bad Response
    if (!response.ok) {
        throw defError;
    }
    
    // Get the JSON Result
    try {
        result = await response.text();
        // Hack to fix php warnnings
        if (result.indexOf("<br />") > -1) {
            result = result.split("<br />")[0];
        }
        result = JSON.parse(result);
    } catch (error) {
        throw defError;
    }

    // The session ended
    if (result.userLoggedOut) {
        Auth.unsetToken();
        return null;
    }
    
    // Update the Token
    if (result.jwt) {
        Auth.setToken(result.jwt);
    }

    // There was an error
    if (result.errors && !Utils.isEmpty(result.errors)) {
        throw result.errors;
    }
    
    // Show the Result
    if (!result.data) {
        result.data = {};
    }
    if (result.success) {
        store.dispatch(showResult("success", result.success));
        result.data.success = result.success;
    }
    if (result.warning) {
        store.dispatch(showResult("warning", result.warning));
        result.data.warning = result.warning;
    }
    if (result.error) {
        store.dispatch(showResult("error", result.error));
        result.data.error = result.error;
    }

    // Return just the data
    return result.data;
}

/**
 * Aborts a Fetch
 * @returns {Void}
 */
function abort() {
    if (controller) {
        controller.abort();
    }
}



/**
 * Creates a new Url
 * @param {String}  path
 * @param {Object=} params
 * @returns {URL}
 */
function createUrl(path, params = {}) {
    const url   = new URL(process.env.REACT_APP_API + path);
    const token = Auth.getToken();

    for (const [ key, value ] of Object.entries(params)) {
        url.searchParams.append(key, value);
    }
    if (longTerm) {
        url.searchParams.append("app", 1);
    }
    if (token) {
        url.searchParams.append("jwt", token);
    }
    return url;
}

/**
 * Does a Get
 * @param {String}   path
 * @param {Object=}  params
 * @param {Boolean=} showLoader
 * @returns {Object}
 */
async function get(path, params = {}, showLoader = true) {
    const url = createUrl(path, params);
    let result;

    try {
        result = await ajax(url, {}, showLoader);
    } catch(e) {
        result = { error : true };
    }
    return result;
}

/**
 * Does a Post
 * @param {String}   path
 * @param {Object=}  params
 * @param {Boolean=} showLoader
 * @returns {Object}
 */
function post(path, params = {}, showLoader = true) {
    const url  = createUrl(path);
    const body = new FormData();
    for (const [ key, value ] of Object.entries(params)) {
        body.append(key, value);
    }
    return ajax(url, { method : "post", body }, showLoader);
}




/**
 * The API functions
 */
export default {
    init,
    abort,


    // Auth actions
    Auth : {
        "login"               : (data) => post("/session/login",             data),
        "recover"             : (data) => post("/session/recover",           data),
        "logout"              : (data) => post("/session/logout",            data),
    },
    Device : {
        "add"                 : (data) => post("/device/add",                data, false),
        "edit"                : (data) => post("/device/edit",               data, false),
        "remove"              : (data) => post("/device/remove",             data, false),
    },
    
    // Store actions
    Store : {
        "getHeader"           : (data) => get("/store/getHeader",            data, false),
        "getHome"             : (data) => get("/store/getHome",              data),
        "toggleFavorite"      : (data) => post("/store/toggleFavorite",      data, false),
        "addToHistory"        : (data) => post("/store/addToHistory",        data, false),
    },
    Product : {
        "getSearch"           : (data) => get("/product/getSearch",          data),
        "getNewests"          : (data) => get("/product/getNewests",         data),
        "getFavorites"        : (data) => get("/product/getFavorites",       data),
        "getHistory"          : (data) => get("/product/getHistory",         data),
    },
    Category : {
        "getCategories"       : (data) => get("/section/getCategories",      data),
        "getCategory"         : (data) => get("/section/getCategory",        data),
    },
    Offer : {
        "getOffers"           : (data) => get("/offer/getOffers",            data),
        "getOffer"            : (data) => get("/offer/getOffer",             data),
    },
    Brand : {
        "getBrands"           : (data) => get("/brand/getBrands",            data),
        "getBrand"            : (data) => get("/brand/getBrand",             data),
    },
    Bike : {
        "getCylinders"        : (data) => get("/bike/getCylinders",          data),
        "getCylinder"         : (data) => get("/bike/getCylinder",           data),
        "getBike"             : (data) => get("/bike/getBike",               data),
    },
    Cart : {
        "getCart"             : (data) => get("/cart/getCart",               data),
        "addProduct"          : (data) => post("/cart/addProduct",           data, false),
        "editProduct"         : (data) => post("/cart/editProduct",          data, false),
        "removeProduct"       : (data) => post("/cart/removeProduct",        data, false),
        "confirmCart"         : (data) => post("/cart/confirmCart",          data),
        "unconfirmCart"       : (data) => post("/cart/unconfirmCart",        data),
        "completeCart"        : (data) => post("/cart/completeCart",         data),
    },

    // Content actions
    News : {
        "getNews"             : (data) => get("/store/getNews",              data),
        "getNew"              : (data) => get("/store/getNew",               data),
    },
    Notification : {
        "getNotifications"    : (data) => get("/store/getNotifications",     data, false),
        "discardNotification" : (data) => post("/store/discardNotification", data, false),
    },

    // Client actions
    Client : {
        "getBanks"            : (data) => get("/client/getBanks",            data),
        "getAccount"          : (data) => get("/client/getAccount",          data),
        "editAccount"         : (data) => post("/client/editAccount",        data),
    },
    Address : {
        "getAddresses"        : (data) => get("/address/getAddresses",       data),
        "getAddress"          : (data) => get("/address/getAddress",         data, false),
        "editAddress"         : (data) => post("/address/editAddress",       data, false),
        "deleteAddress"       : (data) => post("/address/deleteAddress",     data),
    },
    Voucher : {
        "getInvoices"         : (data) => get("/voucher/getInvoices",        data),
        "getReceipts"         : (data) => get("/voucher/getReceipts",        data),
        "getCreditNotes"      : (data) => get("/voucher/getCreditNotes",     data),
        "getDebitNotes"       : (data) => get("/voucher/getDebitNotes",      data),
    },
    Query : {
        "getQueries"          : (data) => get("/query/getQueries",           data),
        "getQuery"            : (data, loader) => get("/query/getQuery",     data, loader),
        "getQueryCreate"      : (data) => get("/query/getQueryCreate",       data),
        "createQuery"         : (data) => post("/query/createQuery",         data),
        "replyQuery"          : (data) => post("/query/replyQuery",          data),
    },
    Order : {
        "getOrders"           : (data) => get("/order/getOrders",            data),
        "getOrder"            : (data) => get("/order/getOrder",             data),
        "getGroups"           : (data) => get("/order/getGroups",            data),
        "getGroup"            : (data) => get("/order/getGroup",             data),
    },
};
