import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Components
import CartSummary          from "./CartSummary";
import AddressDialog        from "./AddressDialog";
import ImageDialog          from "Components/Utils/Dialog/ImageDialog";
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Common/Icon";
import Alert                from "Components/Utils/Form/Alert";
import TextField            from "Components/Utils/Form/TextField";

// Actions
import {
    unconfirmCart, completeCart,
} from "Actions/Store/CartActions";



/**
 * The Shipping Page
 */
class ShippingPage extends React.Component {
    // The Current State
    state = {
        confirmed     : false,
        showAddresses : false,
        showMapDialog : false,
        loading       : false,
        data          : {
            shippingType : 0,
            addressID    : 0,
            message      : "",
        },
        errors        : {},
    }

    /**
     * Unconfirm the Cart if I leave
     * @returns {Void}
     */
    componentWillUnmount() {
        if (!this.state.confirmed) {
            this.props.unconfirmCart();
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Selects the Shipping Type
     * @param {Number} shippingType
     * @returns {Function}
     */
    selectShipping = (shippingType) => () => {
        let addressID = this.state.data.addressID;

        if (shippingType > 1 && this.state.data.addressID === 0) {
            const mainAddress = Utils.getData(this.props.data.addresses, "isDefault", true);
            addressID = mainAddress.addressID || 0;
        }
        this.setState({ data : { ...this.state.data, shippingType, addressID } });
    }



    /**
     * Opens the Addresses Dialog
     * @returns {Void}
     */
    openAddresses = () => {
        this.setState({ showAddresses : true });
    }

    /**
     * Closes the Addresses Dialog
     * @returns {Void}
     */
    closeAddresses = () => {
        this.setState({ showAddresses : false });
    }

    /**
     * Selects the Address
     * @param {Number} addressID
     * @returns {Function}
     */
    selectAddress = (addressID) => {
        this.setState({ data : { ...this.state.data, addressID } });
        this.closeAddresses();
    }

    /**
     * Closes an Alert
     * @param {String} name
     * @returns {Function}
     */
    closeAlert = (name) => () => {
        this.setState({
            errors : { ...this.state.errors, [name] : "" },
        });
    }



    /**
     * Opens the Map Dialog
     * @returns {Void}
     */
    openMap = () => {
        this.setState({ showMapDialog : true });
    }

    /**
     * Closes the Map Dialog
     * @returns {Void}
     */
    closeMap = () => {
        this.setState({ showMapDialog : false });
    }



    /**
     * Handles a Cart Submit
     * @returns {Void}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, errors : {} });
        this.props.closeAlert();
        try {
            const result = await this.props.completeCart(data);
            this.setState({ confirmed : true });
            this.props.onComplete(result.orderID);
            this.props.onSubmit();
        } catch (errors) {
            this.props.openAlert("", errors.form);
            this.setState({ loading : false, errors });
        }
    }

    /**
     * Handles a Cart Cancel
     * @returns {Void}
     */
    handleCancel = async () => {
        const { loading } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true, errors : {} });
        this.props.closeAlert();
        try {
            await this.props.unconfirmCart();
            this.props.onSubmit();
        } catch (errors) {
            this.props.openAlert("", errors.form);
            this.setState({ loading : false, errors });
        }
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isPotential, addresses, totals, canUseFree, map     } = this.props.data;
        const { showAddresses, showMapDialog, loading, data, errors } = this.state;

        const hasShippings = !isPotential;
        const showShipping = hasShippings && data.shippingType > 0;
        const showMap      = hasShippings && data.shippingType === 2 && !!map.name;
        const showAddress  = hasShippings && data.shippingType > 1;
        const addressID    = data.addressID || (addresses.length > 0 ? addresses[0].addressID : 0);
        const address      = Utils.getData(addresses, "addressID", addressID);
        const hasAddress   = Boolean(address && address.addressID);
        const isSelected   = data.shippingType === 1 || (data.shippingType > 1 && addressID > 0);
        const isDisabled   = loading || (!isPotential && !isSelected);
        const shippings    = [
            { type : 1, message : "CART_SHIPPING_TYPE_1", image : "cart-shipping1", show : true         },
            { type : 2, message : "CART_SHIPPING_TYPE_2", image : "cart-shipping2", show : !!canUseFree },
            { type : 3, message : "CART_SHIPPING_TYPE_3", image : "cart-shipping3", show : true         },
        ];
        const shipping = showShipping ? shippings[data.shippingType - 1] : {};

        return <>
            <section className="cart-content">
                {hasShippings && <Card className="cart-card">
                    <SubTitle message="CART_SHIPPING_TYPE" icon="shipping" />
                    <Alert
                        variant="error"
                        message={errors.shipping}
                        onClose={this.closeAlert("shipping")}
                    />
                    <ul className="cart-shipping no-list spacing">
                        {shippings.filter((elem) => elem.show).map((elem) => <li
                            key={elem.type}
                            onClick={this.selectShipping(elem.type)}
                            className={data.shippingType === elem.type ? "cart-selected" : ""}
                        >
                            <div className={elem.image} />
                            <p>{NLS.get(elem.message)}</p>
                        </li>)}
                    </ul>
                </Card>}
                {showAddress && <Card className="cart-card cart-address-card">
                    <SubTitle message="CART_ADDRESS" icon="address" />
                    <Alert
                        variant="error"
                        message={errors.address}
                        onClose={this.closeAlert("address")}
                    />
                    <div className="cart-address-content spacing">
                        <Icon variant="location" />
                        <div className="cart-address-current">
                            {hasAddress ? <div>
                                <h3>{address.description}</h3>
                                <ul className="no-list">
                                    <li>{address.address}</li>
                                    <li>{address.province}</li>
                                    <li>{address.locality}</li>
                                    <li>{address.postalCode}</li>
                                    <li>{address.schedule}</li>
                                </ul>
                            </div> : NLS.get("CART_SELECT_ONE_ADDRESS")}
                        </div>
                        <HyperLink
                            message="CART_ADDRESS_OTHER"
                            onClick={this.openAddresses}
                        />
                    </div>
                </Card>}
                <Card className="cart-card">
                    <SubTitle message="CART_MESSAGE" icon="query" />
                    <div className="spacing">
                        <TextField
                            type="textarea"
                            name="message"
                            value={data.message}
                            placeholder="CART_WRITE_MESSAGE"
                            onChange={this.handleChange}
                        />
                    </div>
                </Card>
            </section>

            <CartSummary
                totals={totals}
                showShipping={showShipping}
                shipping={shipping}
                showMap={showMap}
                map={map}
                openMap={this.openMap}
                submit="CART_CONFIRM"
                cancel="CART_EDIT"
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                isDisabled={isDisabled}
            />

            <AddressDialog
                open={showAddresses}
                data={addresses}
                selectedID={addressID}
                onSelect={this.selectAddress}
                onClose={this.closeAddresses}
            />
            <ImageDialog
                open={showMapDialog}
                message={map.name || ""}
                image={map.mapUrl || ""}
                onClose={this.closeMap}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        unconfirmCart : PropTypes.func.isRequired,
        completeCart  : PropTypes.func.isRequired,
        onSubmit      : PropTypes.func.isRequired,
        onComplete    : PropTypes.func.isRequired,
        data          : PropTypes.object.isRequired,
        openAlert     : PropTypes.func.isRequired,
        closeAlert    : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.cart.data,
        };
    }
}

export default connect(ShippingPage.mapStateToProps, {
    unconfirmCart, completeCart,
})(ShippingPage);
