import API                  from "Utils/App/API";

// Types
import {
    CATEGORY_LIST, CATEGORY_DATA,
} from "Utils/Types";



/**
 * Fetches the Category List
 * @returns {Function}
 */
export function fetchCategories() {
    return async (dispatch) => {
        const data = await API.Category.getCategories();
        dispatch({ type : CATEGORY_LIST, data });
    };
}

/**
 * Fetches the Category Data
 * @param {Object} params
 * @returns {Function}
 */
export function fetchCategory(params) {
    return async (dispatch) => {
        const data = await API.Category.getCategory(params);
        dispatch({ type : CATEGORY_DATA, data });
    };
}
