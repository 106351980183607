import Utils                from "Utils/Common/Utils";

// Types
import {
    STORE_HEADER, STORE_HOME,
    STORE_FAVORITE, STORE_HISTORY,
    CART_DATA, CART_COMPLETE,
} from "Utils/Types";



// The initial State
const initialState = {
    error  : false,
    header : {
        category     : [],
        offer        : [],
        brand        : [],
        bike         : [],
        dollar       : "",
        cart         : 0,
        favorite     : [],
        notification : [],
    },
    home   : {
        slides       : [],
        banner       : null,
        newests      : [],
        favorites    : [],
        history      : [],
        category     : {
            main   : [],
            others : [],
        },
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case STORE_HEADER:
        return {
            ...state,
            error  : false,
            header : action.data,
        };
    case STORE_HOME:
        return {
            ...state,
            error  : false,
            home   : action.data,
        };

    // Update the Favorites
    case STORE_FAVORITE: {
        const productCode = action.data.productCode;
        const category    = { ...state.home.category };
        if (state.home.category.main.length) {
            for (const [ index, list ] of category.main.entries()) {
                category.main[index] = Utils.setFavorite(list, productCode);
            }
        }
        return {
            ...state,
            error  : false,
            header : {
                ...state.header,
                favorite  : action.data.list,
            },
            home   : {
                ...state.home, category,
                favorites : action.data.list,
                newests   : Utils.setFavoriteList(state.home.newests, productCode),
                history   : Utils.setFavoriteList(state.home.history, productCode),
            },
        };
    }

    // Update the History
    case STORE_HISTORY:
        return {
            ...state,
            error  : false,
            home   : {
                ...state.home,
                history : action.data.list,
            },
        };

    // Update the Cart
    case CART_DATA:
    case CART_COMPLETE:
        return {
            ...state,
            error  : false,
            header : {
                ...state.header,
                cart : action.data.amount,
            },
        };

    default:
        return state;
    }
};
