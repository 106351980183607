import React                        from "react";
import PropTypes                    from "prop-types";
import { connect }                  from "react-redux";
import { BrowserRouter, Switch }    from "react-router-dom";
import { setMobile }                from "Actions/Core/CoreActions";

// Sections
import LoginPage                    from "Components/Auth/LoginPage";
import TermsGuest                   from "Components/Terms/TermsGuest";
import Container                    from "Components/Core/Container";

// Components
import Loader                       from "Components/Utils/Common/Loader";
import UserRoute                    from "Components/Utils/Route/UserRoute";
import GuestRoute                   from "Components/Utils/Route/GuestRoute";
import ScrollToTop                  from "Components/Utils/Route/ScrollToTop";

// Styles
import "Styles/Core/Properties.css";
import "Styles/Core/Main.css";
import "Styles/Core/App.css";

// Constants
const MOBILE_WIDTH  = 600;
const MOBILE_HEIGHT = 450;
const TABLET_WIDTH  = 950;



/**
 * The App
 */
class App extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    /**
     * Unloads the Data
     * @returns {Void}
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    /**
     * Handles the Resize
     * @returns {Void}
     */
    handleResize = () => {
        const width    = window.innerWidth;
        const height   = window.innerHeight;
        const isMobile = width <= MOBILE_WIDTH || height <= MOBILE_HEIGHT;
        const isTablet = width <= TABLET_WIDTH;

        this.props.setMobile(isMobile, isTablet);
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isAuthenticated, loading } = this.props;

        return <div className="container">
            <Loader variant={isAuthenticated ? "app" : "auth"} show={loading} />

            <BrowserRouter>
                <ScrollToTop>
                    <Switch>
                        {!isAuthenticated && <GuestRoute
                            path="/terminos-y-condiciones"
                            component={TermsGuest}
                            exact
                        />}
                        <GuestRoute path="/ingresar" component={LoginPage} exact />
                        <UserRoute  path="/"         component={Container}       />
                    </Switch>
                </ScrollToTop>
            </BrowserRouter>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setMobile       : PropTypes.func.isRequired,
        loading         : PropTypes.bool.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading         : state.core.loading,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(App.mapStateToProps, {
    setMobile,
})(App);
