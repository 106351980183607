import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleFavorite }   from "Actions/Store/StoreActions";
import NLS                  from "Utils/App/NLS";

// Components
import ProductDialog        from "Components/Product/Item/ProductDialog";
import ProductImage         from "Components/Product/Item/ProductImage";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";



/**
 * The Favorite Menu
 */
class FavoriteMenu extends React.Component {
    // The Current State
    state = {
        showDialog : false,
        product    : {},
    }

    /**
     * Opens the Product Dialog
     * @param {Object} product
     * @returns {Function}
     */
    openProduct = (product) => () => {
        this.setState({ showDialog : true, product });
        this.props.onClose();
    }

    /**
     * Closes the Product Dialog
     * @returns {Void}
     */
    closeProduct = () => {
        this.setState({ showDialog : false, product : {} });
    }

    /**
     * Handles the Favorite Enabled
     * @param {Object} elem
     * @returns {Function}
     */
    handleDelete = (elem) => () => {
        const { data, toggleFavorite, onClose } = this.props;
        toggleFavorite(elem.productID);
        if (data.length <= 1) {
            onClose();
        }
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { open, data, onEnter, onLeave, onClose } = this.props;
        const { showDialog, product                   } = this.state;

        return <>
            <nav
                className={`submenu-favorite submenu-secondary ${open && "submenu-open"}`}
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
            >
                <h3>{NLS.get("FAVORITES_TITLE")}</h3>
                <ul className="no-list">
                    {data.map((elem) => <li key={elem.productCode} onClick={this.openProduct(elem)}>
                        <HyperLink
                            variant="secondary"
                            className="submenu-favorite-delete"
                            message="GENERAL_DELETE"
                            onClick={this.handleDelete(elem)}
                        />
                        <ProductImage className="submenu-favorite-image" data={elem} />
                        <div className="submenu-favorite-product">
                            {!!elem.productCode && <h4>{elem.productCode}</h4>}
                            <h3>{elem.description}</h3>
                        </div>
                    </li>)}
                    <li className="submenu-button">
                        <Button
                            variant="primary"
                            message="FAVORITES_SHOW_ALL"
                            href="/favoritos"
                            onClick={onClose}
                            fullWidth
                        />
                    </li>
                </ul>
            </nav>

            <ProductDialog
                open={showDialog}
                data={product}
                onClose={this.closeProduct}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleFavorite : PropTypes.func.isRequired,
        data           : PropTypes.array.isRequired,
        open           : PropTypes.bool.isRequired,
        onLeave        : PropTypes.func.isRequired,
        onEnter        : PropTypes.func.isRequired,
        onClose        : PropTypes.func.isRequired,
    }
}

export default connect(null, {
    toggleFavorite,
})(FavoriteMenu);
