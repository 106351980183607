import API                  from "Utils/App/API";

// Types
import {
    CART_DATA, CART_COMPLETE,
} from "Utils/Types";



/**
 * Fetches the Cart Items
 * @param {Object} params
 * @returns {Function}
 */
export function fetchCart(params) {
    return async (dispatch) => {
        const data = await API.Cart.getCart(params);
        dispatch({ type : CART_DATA, data });
    };
}



/**
 * Adds a Product to a Cart
 * @param {Object} params
 * @returns {Function}
 */
export function addProduct(params) {
    return async (dispatch) => {
        const data = await API.Cart.addProduct(params);
        dispatch({ type : CART_DATA, data });
        return data.success;
    };
}

/**
 * Edits a Product amount in a Cart
 * @param {Object} params
 * @returns {Function}
 */
export function editProduct(params) {
    return async (dispatch) => {
        const data = await API.Cart.editProduct(params);
        dispatch({ type : CART_DATA, data });
        return data.success;
    };
}

/**
 * Removes a Product from a Cart
 * @param {Object} params
 * @returns {Function}
 */
export function removeProduct(params) {
    return async (dispatch) => {
        const data = await API.Cart.removeProduct(params);
        dispatch({ type : CART_DATA, data });
        return data.success;
    };
}



/**
 * Confirms a Cart
 * @param {Object} params
 * @returns {Function}
 */
export function confirmCart(params) {
    return async (dispatch) => {
        const data = await API.Cart.confirmCart(params);
        dispatch({ type : CART_DATA, data });
    };
}

/**
 * Unconfirms a Cart
 * @param {Object} params
 * @returns {Function}
 */
export function unconfirmCart(params) {
    return async (dispatch) => {
        const data = await API.Cart.unconfirmCart(params);
        dispatch({ type : CART_DATA, data });
    };
}

/**
 * Completes a Cart
 * @param {Object} params
 * @returns {Function}
 */
export function completeCart(params) {
    return async (dispatch) => {
        const result = API.Cart.completeCart(params);
        dispatch({ type : CART_COMPLETE, data : { amount : 0 } });
        return result;
    };
}
