import jwtDecode                            from "jwt-decode";
import { showResult }                       from "Actions/Core/CoreActions";
import { setCurrentUser }                   from "Actions/Core/AuthActions";


// Module Variables
let token      = "";
let store      = null;
let timeout    = null;

// Module Callbacks
const setCBs   = [];
const unsetCBs = [];



/**
 * Initialize the API
 * @param {Store} theStore
 * @returns {Void}
 */
function init(theStore) {
    store = theStore;

    if (localStorage.token) {
        token = localStorage.token;
        setUser();
    }
}

/**
 * Sets the On Set Callback
 * @param {Function} callback
 * @returns {Void}
 */
function onSet(callback) {
    setCBs.push(callback);
}

/**
 * Sets the On Unset Callback
 * @param {Function} callback
 * @returns {Void}
 */
function onUnset(callback) {
    unsetCBs.push(callback);
}

/**
 * Calls the Callbacks
 * @param {Array.<Function>} callbacks
 * @param {Object=}          data
 * @returns {Void}
 */
function triggerCallbacks(callbacks, data) {
    for (const cb of callbacks) {
        cb(data);
    }
}



/**
 * Returns the Token
 * @returns {String}
 */
function getToken() {
    return token;
}

/**
 * Sets the JWT Token
 * @param {String} jwt
 * @returns {Void}
 */
function setToken(jwt) {
    if (token !== jwt) {
        token = jwt;
        localStorage.setItem("token", token);
        setUser();
    }
}

/**
 * Unsets the Token
 * @returns {Void}
 */
function unsetToken() {
    const user = getUser();
    token = null;

    localStorage.removeItem("token");
    store.dispatch(setCurrentUser({}));
    store.dispatch(showResult("error", "GENERAL_LOGGED_OUT"));
    
    triggerCallbacks(unsetCBs, user);
}



/**
 * Returns the User
 * @returns {?Object}
 */
function getUser() {
    if (token) {
        const jwt = jwtDecode(token);
        return jwt.data;
    }
    return null;
}

/**
 * Sets the User
 * @returns {Void}
 */
function setUser() {
    try {
        const jwt  = jwtDecode(token);
        const time = Math.floor(Date.now() / 1000);
        
        if (jwt.exp < time) {
            unsetToken();
        } else {
            store.dispatch(setCurrentUser(jwt.data));
            if (timeout) {
                window.clearTimeout(timeout);
            }
            timeout = window.setTimeout(unsetToken, (jwt.exp - time) * 1000);
            triggerCallbacks(setCBs, jwt.data);
        }
    } catch (e) {
        unsetToken();
    }
}




/**
 * The API functions
 */
export default {
    init, onSet, onUnset,
    getToken, setToken, unsetToken,
    getUser, setUser,
};
