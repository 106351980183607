import React                from "react";
import PropTypes            from "prop-types";
import { withRouter }       from "react-router";
import Utils                from "Utils/Common/Utils";
import NLS                  from "Utils/App/NLS";

// Styles
import "Styles/Components/Utils/Form/Button.css";



/**
 * The Button
 */
class Button extends React.Component {
    /**
     * Handles the Click
     * @param {Event} e
     * @returns {Void}
     */
    handleClick = (e) => {
        const { onClick, href } = this.props;
        let handled = false;

        if (onClick) {
            onClick(e);
            handled = true;
        }
        if (href) {
            if (Utils.hrefInApp(href)) {
                this.props.history.push(Utils.getUrl(href));
            } else if (!href.startsWith("http")) {
                this.props.history.push(href);
            } else {
                window.location.href = href;
            }
            handled = true;
        }
        if (handled) {
            e.preventDefault();
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { variant, className, message, isDisabled, fullWidth, children } = this.props;
        const content = children || NLS.get(message);

        return <button
            className={`btn btn-${variant} ${fullWidth ? "btn-full" : ""} ${className}`}
            disabled={isDisabled}
            onClick={this.handleClick}
        >
            {content}
        </button>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history    : PropTypes.object.isRequired,
        variant    : PropTypes.string.isRequired,
        className  : PropTypes.string,
        message    : PropTypes.string,
        isDisabled : PropTypes.bool,
        fullWidth  : PropTypes.bool,
        href       : PropTypes.string,
        onClick    : PropTypes.func,
        children   : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        fullWidth : false,
    }
}

export default withRouter(Button);
