import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { logout }           from "Actions/Core/AuthActions";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Client Menu
 */
class ClientMenu extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { isPotential, open, logout, onEnter, onLeave, onClose } = this.props;

        return <nav
            className={"submenu-client submenu-secondary" + (open ? " submenu-open" : "")}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className="no-list">
                <li>
                    <HyperLink
                        variant="none"
                        href="/cuenta/bancos"
                        message="BANKS_NAME"
                        icon="bank"
                        onClick={onClose}
                    />
                </li>
                <li>
                    <HyperLink
                        variant="none"
                        href="/cuenta/datos"
                        message="ACCOUNT_NAME"
                        icon="account"
                        onClick={onClose}
                    />
                </li>
                <li>
                    <HyperLink
                        isHidden={isPotential}
                        variant="none"
                        href="/cuenta/comprobantes"
                        message="VOUCHERS_NAME"
                        icon="voucher"
                        onClick={onClose}
                    />
                </li>
                <li>
                    <HyperLink
                        variant="none"
                        href="/cuenta/grupo-pedidos"
                        message="ORDERS_NAME"
                        icon="cart"
                        onClick={onClose}
                    />
                </li>
                <li>
                    <HyperLink
                        variant="none"
                        href="/cuenta/consultas"
                        message="QUERIES_NAME"
                        icon="query"
                        onClick={onClose}
                    />
                </li>
                <li>
                    <HyperLink
                        variant="none"
                        message="CLIENT_LOGOUT"
                        icon="logout"
                        onClick={logout}
                    />
                </li>
            </ul>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        logout      : PropTypes.func.isRequired,
        isPotential : PropTypes.bool.isRequired,
        open        : PropTypes.bool.isRequired,
        onEnter     : PropTypes.func.isRequired,
        onLeave     : PropTypes.func.isRequired,
        onClose     : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isPotential : state.auth.isPotential,
        };
    }
}

export default connect(ClientMenu.mapStateToProps, {
    logout,
})(ClientMenu);
