import React                from "react";
import ReactDOM             from "react-dom";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Styles
import "Styles/Images/Select.png";
import "Styles/Components/Utils/Form/TextField.css";



/**
 * The Text Field
 */
class TextField extends React.Component {
    // The Current State
    state = {
        isFocused : false,
        hasValue  : Boolean(this.props.value),
        fileName  : "",
    }

    // The Refs
    inputRef = React.createRef();



    /**
     * Updated when a Prop change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.type === "file" && prevProps.value === "" && this.props.value !== prevProps.value) {
            this.setState({ fileName : "" });
        }
    }

    /**
     * The Input got Focus
     * @returns {Void}
     */
    onFocus = () => {
        this.setState({ isFocused : true });
    }

    /**
     * The Input lost Focus
     * @returns {Void}
     */
    onBlur = () => {
        this.setState({ isFocused : false });
    }

    /**
     * Handles the Input Change
     * @param {Event} e
     * @param {*}     value
     * @returns {Void}
     */
    onChange = (e, value = e.target.value) => {
        this.setState({ hasValue : Boolean(value) });
        this.props.onChange(this.props.name, value);
    }




    /**
     * Handles the TextArea autogrow
     * @param {Event} e
     * @returns {Void}
     */
    onTextareaChange = (e) => {
        if (this.inputRef && this.inputRef.current) {
            const node = ReactDOM.findDOMNode(this.inputRef.current);
            if (node && node.offsetHeight < node.scrollHeight + 1) {
                node.style.height = `${node.scrollHeight + 1}px`;
            }
        }
        this.onChange(e);
    }

    /**
     * Handles the File Click
     * @param {Event} e
     * @returns {Void}
     */
    onFileClick = (e) => {
        if (this.inputRef && this.inputRef.current) {
            const node = ReactDOM.findDOMNode(this.inputRef.current);
            node.click();
        }
        e.preventDefault();
    }

    /**
     * Handles the File Change
     * @param {Event} e
     * @returns {Void}
     */
    onFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            this.setState({ fileName : file.name });
            this.onChange(e, file);
        }
    }



    /**
     * Renders the Input depending on the Type
     * @returns {Object}
     */
    renderInput() {
        const { type, name, value, placeholder, isDisabled, options, withNone, autoComplete } = this.props;
        const { fileName } = this.state;

        switch (type) {
        case "textarea":
            return <textarea
                ref={this.inputRef}
                name={name}
                value={value}
                placeholder={NLS.get(placeholder)}
                disabled={isDisabled}
                onInput={this.onTextareaChange}
                onChange={this.onTextareaChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
            />;

        case "select":
            return <select
                name={name}
                value={value}
                placeholder={NLS.get(placeholder)}
                disabled={isDisabled}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
            >
                {withNone && <option key="0" value="0" />}
                {options.map(({ key, value }) => (
                    <option key={key} value={key}>{value}</option>
                ))}
            </select>;

        case "file":
            return <div className="file-container">
                <div className="file-input" onClick={this.onFileClick}>
                    {fileName}
                </div>
                <div className="file-box">
                    <span className="btn btn-primary" onClick={this.onFileClick}>
                        {NLS.get("GENERAL_SELECT_FILE")}
                    </span>
                    <input
                        type="file"
                        name={name}
                        ref={this.inputRef}
                        onInput={this.onFileChange}
                    />
                </div>
            </div>;

        case "checkbox":
            return <div className="checkbox-container">
                <label>
                    <input
                        type="radio"
                        name={name}
                        value="1"
                        checked={value}
                        onChange={(e) => this.onChange(e, 1)}
                    /><span />
                    {NLS.get("GENERAL_YES")}
                </label>
                <label>
                    <input
                        type="radio"
                        name={name}
                        value="0"
                        checked={!value}
                        onChange={(e) => this.onChange(e, 0)}
                    /><span />
                    {NLS.get("GENERAL_NO")}
                </label>
            </div>;

        default:
            return <input
                type={type}
                name={name}
                value={value}
                placeholder={NLS.get(placeholder)}
                disabled={isDisabled}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                autoComplete={!autoComplete ? "new-password" : ""}
            />;
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            isHidden, className, type, label, isRequired,
            value, helperText, error, shrink, withMargin, autoComplete,
        } = this.props;
        const { isFocused, hasValue } = this.state;

        if (isHidden) {
            return <React.Fragment />;
        }

        const text          = error || helperText;
        const hasLabel      = Boolean(label);
        const hasError      = Boolean(error);
        const hasText       = Boolean(text);
        const withValue     = isFocused || hasValue || Boolean(value);
        const withTransform = !shrink && !autoComplete && type !== "file" && type !== "checkbox";

        return <label className={
            `input-field input-${type} ${className}` +
            (isRequired ? " input-required" : "") +
            (hasError   ? " input-error"    : "") +
            (withMargin ? " input-margin"   : "")
        }>
            {hasLabel && <p className={
                (withTransform ? " input-transform" : "") +
                (withValue     ? " input-value"     : "") +
                (isFocused     ? " input-focused"   : "")
            }>
                {NLS.get(label)}
            </p>}
            {this.renderInput()}
            {hasText && <p className="input-helper">
                {NLS.get(text)}
            </p>}
        </label>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isHidden     : PropTypes.bool,
        className    : PropTypes.string,
        type         : PropTypes.string,
        name         : PropTypes.string.isRequired,
        label        : PropTypes.string,
        value        : PropTypes.any,
        placeholder  : PropTypes.string,
        isRequired   : PropTypes.bool,
        isDisabled   : PropTypes.bool,
        onChange     : PropTypes.func.isRequired,
        error        : PropTypes.string,
        helperText   : PropTypes.string,
        options      : PropTypes.array,
        shrink       : PropTypes.bool,
        withMargin   : PropTypes.bool,
        withNone     : PropTypes.bool,
        autoComplete : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isHidden     : false,
        className    : "",
        type         : "text",
        isRequired   : false,
        isDisabled   : false,
        options      : [],
        shrink       : false,
        withMargin   : false,
        withNone     : false,
        autoComplete : false,
    }
}

export default TextField;
