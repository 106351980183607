import React                from "react";
import PropTypes            from "prop-types";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";

// Styles
import "Styles/Components/Product/List/ProductsContent.css";



/**
 * The Products Content
 */
class ProductsContent extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { noHeader, children } = this.props;

        return <Wrapper className={`products-wrapper ${noHeader ? "products-no-header" : ""}`}>
            {children}
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        noHeader : PropTypes.bool,
        children : PropTypes.any,
    }
}

export default ProductsContent;
