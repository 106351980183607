import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Status               from "Components/Utils/Common/Status";



/**
 * The Table Cell
 */
class TableCell extends React.Component {
    /**
     * Returns all the Classes
     * @returns {String}
     */
    getClasses() {
        const { className, align, nowrap, breakAfter, hide, inlineBlock } = this.props;

        const classes = [ className ];
        if (align) {
            classes.push(`align-${align}`);
        }
        if (nowrap) {
            classes.push("nowrap");
        }
        if (breakAfter) {
            classes.push("table-break");
        }
        if (hide) {
            classes.push("table-hide");
        }
        if (inlineBlock) {
            classes.push("table-iblock");
        }
        return classes.join(" ");
    }

    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const {
            isHead, isLink, isStatus, message,
            variant, href, target, onClick,
            condition, success, error,
            children,
        } = this.props;
        const classes = this.getClasses();

        // A Head Cell
        if (isHead) {
            return <th className={classes}>
                {message ? NLS.get(message) : children}
            </th>;
        }

        // A Link Cell
        if (isLink) {
            return <td className={classes}>
                <HyperLink
                    variant={variant}
                    href={href}
                    target={target}
                    message={String(message)}
                    onClick={onClick}
                />
            </td>;
        }

        // A Status Cell
        if (isStatus) {
            return <td className={classes}>
                <Status condition={condition} success={success} error={error} />
            </td>;
        }

        // A Normal Cell
        return <td className={classes}>
            {message ? NLS.get(message) : children}
        </td>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isHead      : PropTypes.bool,
        isLink      : PropTypes.bool,
        isStatus    : PropTypes.bool,
        className   : PropTypes.string,
        align       : PropTypes.string,
        nowrap      : PropTypes.bool,
        breakAfter  : PropTypes.bool,
        hide        : PropTypes.bool,
        inlineBlock : PropTypes.bool,
        message     : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        variant     : PropTypes.string,
        href        : PropTypes.string,
        target      : PropTypes.string,
        onClick     : PropTypes.func,
        condition   : PropTypes.bool,
        success     : PropTypes.string,
        error       : PropTypes.string,
        children    : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isHead      : false,
        isLink      : false,
        isStatus    : false,
        className   : "",
        nowrap      : false,
        breakAfter  : false,
        hide        : false,
        inlineBlock : false,
        variant     : "primary",
    }
}

export default TableCell;
