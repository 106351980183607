import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Amount Filter
 */
class AmountFilter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, selected, getUrl, onChange } = this.props;

        return <div className="aside-options">
            <h4>{NLS.get("OFFERS_FILTER")}</h4>
            <ul className="products-option no-list">
                {data.map((elem) => <li key={elem.id}>
                    <HyperLink
                        variant={selected === elem.id ? "red" : "gray"}
                        href={getUrl(elem.id)}
                        onClick={onChange}
                    >{elem.filter}</HyperLink>
                </li>)}
            </ul>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data     : PropTypes.array.isRequired,
        selected : PropTypes.number,
        getUrl   : PropTypes.func.isRequired,
        onChange : PropTypes.func.isRequired,
    }
}

export default AmountFilter;
